.AddBankAccountModal {
  &__ description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  &__help {
    font-size: .875rem;
  }
}

.InlineControl {
  display: grid;
  grid-template-columns: .95fr 1.6fr;
  margin-bottom: 1.25rem;
}
