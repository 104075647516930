.Pagination
  display: flex
  flex-wrap: wrap

.Pagination-pageButton
  margin-right: var(--unit)
  color: var(--accent-color)
  text-decoration: underline

  &:last-child
    margin-right: 0

  &--selected
    color: var(--black-color)
    text-decoration: none
    font-weight: bold
    pointer-events: none
