.StudentsDataUploadPage {
  &__institutionForm {
    max-width: 25rem;
  }

  &__error {
    input {
      color: var(--color-red-400);
    }
  }

  &__errorIcon {
    width: 1.3em;
    height: 1.3em;
    color: var(--color-red-400);
  }

  &__datePicker {
    width: 10rem;
  }

  &__citizenship {
    width: 11rem;
  }

  &__ethnicity {
    position: relative;
    width: 14rem;
  }
}

.StepsProgress {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
