@import "../../../../styles/common";

.Fields {
  @include text-style-body-sm;
}

.inputGroup {
  display: flex;
  gap: .25rem;
  font-size: 0.875rem;

  > * {
    flex: 1;
  }
}

.icon {
  width: .75rem;
  height: .75rem;
}
