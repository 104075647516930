.ReviewStudentsData {
  &__table {
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;

    &--errorCell {
      color: var(--color-red-800);
      width: 100%;
    }
  }
}
