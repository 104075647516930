@import ../../styles/common.sass

.InstantSearch-FiltersContainer
  +InstantSearch--smallScreen
    // Open filters in a full screen modal
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    width: auto
    z-index: var(--modal-z-index)
    background: var(--white-color)
    overflow: auto

.InstantSearch-FiltersContainer--hiddenModal
  +InstantSearch--smallScreen
    display: none

.InstantSearch-FiltersContainer--hidden
  display: none

.InstantSearch-Filters
  padding: var(--InstantSearch-Filters-padding)
  background-color: var(--InstantSearch-Filters-backgroundColor)
  border: var(--InstantSearch-Filters-borderWidth) solid var(--InstantSearch-Filters-borderColor)
  border-radius: var(--InstantSearch-Filters-borderRadius)

.InstantSearch-FiltersHeading
  margin-bottom: var(--InstantSearch-FiltersHeading-marginBottom)
  font-weight: normal
  font-size: var(--InstantSearch-FiltersHeading-fontSize)
  letter-spacing: var(--InstantSearch-FiltersHeading-letterSpacing)
  text-transform: var(--InstantSearch-FiltersHeading-textTransform)
  color: var(--graphite-color)

.InstantSearch-FiltersHeader--largeScreen
  .InstantSearch-FiltersHeading
    margin-top: 0

.InstantSearch-FiltersHeader--smallScreen
  .InstantSearch-FiltersHeading
    position: relative
    font-size: 22px
    margin-top: calc(var(--unit) * 2)
    margin-bottom: calc(var(--unit) * 1)

.InstantSearch-CloseFilters
  position: absolute
  right: 0
  top: calc(var(--unit) * 0.5)
  width: var(--unit)
  height: var(--unit)
  cursor: pointer

.InstantSearch-FiltersActions
  display: none
  +InstantSearch--smallScreen
    display: block
    margin-top: 16px
    margin-bottom: calc(var(--unit) * 3)
    text-align: center

.InstantSearch-ApplyFilters
  width: calc(var(--unit) * 18)
  height: 50px
  border: 2px solid var(--accent-color)
  color: var(--accent-color)
  border-radius: var(--border-radius)
  &:active
    color: var(--white-color)
    background-color: var(--accent-color)

.InstantSearch-FiltersResultsCount
  +InstantSearch--smallScreen
    margin-top: 0
    margin-bottom: 0

.InstantSearch-Filters
  .collapsible__title
    margin-top: 4px
    margin-bottom: 4px
    height: auto
    line-height: 2rem
    // background-color: var(--rrui-input-field-background-color)
    // background-color: var(--rrui-input-field-background-color-focus)
    background-color: white
    border: 1px solid #eaeaea

    &:focus
      box-shadow: 0 0 0 1px var(--accent-color-light)
      border-color: var(--accent-color-light)
