// // Stretches the page to full height.
// // (but doesn't seem to actually work with flexbox)
// html,
// body,
// #react,
// .__full-height
//   min-height: 100%

// I guess this global rule is appropriate
// considering Bootstrap has been
// doing so for ages now
*,
*:before,
*:after
  box-sizing: border-box

// Removes higlight on tap on mobile devices.
html, body
  -webkit-tap-highlight-color: transparent

body
  // Disables "double tap to zoom in" on mobile devices.
  // https://stackoverflow.com/questions/46167604/iphone-html-disable-double-tap-to-zoom
  touch-action: manipulation

body
  line-height: 1.52947

body, button, input, textarea, select
  font-family: var(--fontFamily)
  font-weight: normal
  font-size: 16px

h1
  margin: 0.67em 0
  font-size: var(--section-heading-font-size)
  margin: 0

mark
  color: var(--black-color)
  background-color: var(--accent-color)

small
  font-size: 80%

sub
  position: relative
  font-size: 75%
  line-height: 0
  vertical-align: baseline
  bottom: -0.25em

sup
  position: relative
  font-size: 75%
  line-height: 0
  vertical-align: baseline
  top: -0.5em

img
  border: 0

hr
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: content-box
  height: 0

pre
  overflow: auto
  font-family: monospace
  font-size: 1em

code
  font-family: monospace
  font-size: 1em

kbd
  font-family: monospace
  font-size: 1em

samp
  font-family: monospace
  font-size: 1em

button
  margin: 0
  font: inherit
  color: inherit
  overflow: visible
  text-transform: none
  cursor: pointer
  -webkit-appearance: button

  &:focus
    //outline: none

input[type="checkbox"]
  cursor: pointer

input[type="date"]::-webkit-inner-spin-button
  -webkit-appearance: none

input[type="date"]::-webkit-calendar-picker-indicator
  position: relative
  z-index: 1000
  color: transparent
  background: transparent
  -webkit-appearance: none

input
  // margin: 0
  // font: inherit
  // color: inherit
  // line-height: normal
  // appearance: none !important

  &:focus
    // outline: none

  // &:hover
  //  -webkit-appearance: none !important
  //  -moz-appearance: none !important
  //  appearance: none !important

  &.error
    border: 1px solid var(--error-color-darker)

optgroup
  margin: 0
  font: inherit
  color: inherit
  font-weight: bold

select
  margin: 0
  font: inherit
  color: inherit
  text-transform: none
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

  &:focus
    // outline: none

  &.error
    border: 1px solid var(--error-color-darker)

textarea
  margin: 0
  font: inherit
  color: inherit
  overflow: auto

  &.error
    border: 1px solid var(--error-color-darker)


input[type="reset"]
  cursor: pointer
  -webkit-appearance: button

input[type="submit"]
  cursor: pointer
  -webkit-appearance: button

button[disabled]
  cursor: default

button::-moz-focus-inner
  padding: 0
  border: 0

input::-moz-focus-inner
  padding: 0
  border: 0

input[type="radio"]
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: border-box
  padding: 0

input[type="number"]::-webkit-inner-spin-button
  height: auto

input[type="number"]::-webkit-outer-spin-button
  height: auto

input[type="search"]
  -webkit-appearance: textfield

input[type="search"]::-webkit-search-cancel-button
  -webkit-appearance: none

input[type="search"]::-webkit-search-decoration
  -webkit-appearance: none

fieldset
  margin: 0 2px
  padding: 0.35em 0.625em 0.75em
  border: 1px solid var(--gray-color)

legend
  padding: 0
  border: 0

table
  border-spacing: 0
  border-collapse: collapse

td
  padding: 0

th
  padding: 0

h2
  font-size: 20px
  font-weight: normal
  margin: 0

// label
//  font-size: 14px
//  font-weight: normal
//  color: var(--gray-color-400)

h3
  margin: 0

p
  margin: 0

ul
  list-style: none

input[type="button"]
  cursor: pointer
  -webkit-appearance: button

input[disabled]
  cursor: default

a
  color: var(--accent-color)

  &:hover
    color: $primary

// Styles have loaded so can now show the content.
// The flash of unstyled content wasn't shown.
#react
  display: block !important

address
  font-style: normal

h1, h2, h3, h4, h5, h6
  font-weight: 300

main
  /* This is required for IE11, otherwise there'd be no top margin. */
  display: block

a
  text-decoration: none

  &:hover
    text-decoration: underline

  // When default `outline` is overridden
  // then browser doesn't hide it when not tabbing via keyboard.
  //
  // Apple's website sets `data-focus-method` attribute interactively
  // to either "mouse" or "touch" or "key" (could also be "pointer").
  // Presumably something like:
  //
  // document.onKeyDown={() => focusMethod = 'key'}
  // document.onTouchStart={() => if focusMethod = 'touch'}
  // document.onMouseDown={() => if (focusMethod !== 'touch') focusMethod = 'mouse'}
  // document.onFocus={(event) => {
  //   if (!focusMethod) focusMethod = lastFocusMethod
  //   event.target.setAttribute('data-focus-method', focusMethod)
  //   lastFocusMethod = focusMethod
  //   focusMethod = undefined
  // }}
  // document.onBlur={(event) => event.target.removeAttribute('data-focus-method')}
  //
  // Perhaps not required:
  // window.onBlur={() => focusMethod = undefined}
  // Window "blur" event is triggered when the window is minimized.
  //
  // Or something like:
  // https://code.area17.com/a17/a17-helpers/blob/master/src/utility/focusDisplayHandler.js
  //
  // And the related CSS:
  //
  // :focus[data-focus-method=mouse]:not(input):not(textarea):not(select),
  // :focus[data-focus-method=touch]:not(input):not(textarea):not(select) {
  //   outline: 0;
  // }
  //
  // So commented out for now.
  //
  // &:focus
  //   // Copied from Apple.com.
  //   outline: var(--outline-width) solid var(--outline-color)
  //   outline-offset: 1px

.link--underline
  text-decoration: underline

input, textarea
  padding: 0

// Internet Explorer adds borders around all images
img
  border: none

// Instagram-like stubbing of a text block that's being loaded.
.text--loading
  user-select: none
  background-color: var(--gray-color-lightest)
  color: transparent

.danger
  color: var(--error-color-darker)

.Header
  z-index: var(--main-header-z-index) !important

#react
  // Used padding-top on the #__next element instead of body
  // to avoid the issue with external libraries that rely on body element
  padding-top: var(--Header-height)
