:root {
  --color-white: #fff;
  --color-black: #000;
  --color-transparent: transparent;

  --color-purple-100: #eadbff;
  --color-purple-500: #6000d6;
  --color-purple-600: #5000b2;

  --color-grayish-100: #dee3ec;
  --color-grayish-300: #777c8c;

  --color-gray-15: #faf8ff;
  --color-gray-20: #fafafa;
  --color-gray-25: #f4f5f7;
  --color-gray-50: #f0f0f0;
  --color-gray-100: #e8e8e8;
  --color-gray-200: #d5d5d5;
  --color-gray-300: #8e8b8b;
  --color-gray-400: #666;
  --color-gray-500: #595959;
  --color-gray-600: #4f4f4f;
  --color-gray-650: #404040;
  --color-gray-700: #2e2e2e;
  --color-gray-800: #292929;
  --color-gray-900: #0e0e0e;

  --color-green-50: #daffe2;
  --color-green-500: #29b34a;
  --color-green-700: #115f21;

  --color-red-50: #ffe1e1;
  --color-red-100: #ffc9c9;
  --color-red-300: #ef6464;
  --color-red-400: #cd3d64;
  --color-red-800: #650000;
  --color-red-900: #5f0000;

  --color-blue-25: #e5f6ff;
  --color-blue-50: #daedff;
  --color-blue-100: #d1dbff;
  --color-blue-300: #4788c7;
  --color-blue-500: #00a2ff;
  --color-blue-600: #065684;
  --color-blue-700: #003f63;
  --color-blue-800: #00145b;
  --color-blue-900: #0a0044;

  --color-brown-100: #ffd9ad;
  --color-brown-700: #5f3300;
  --color-brown-900: #2f2700;

  --color-yellow-25: #fef8e3;
  --color-yellow-100: #fcf1ba;
  --color-yellow-600: #ffb803;

  --color-danger-row-highlight: #FFEDED;

  --color-primaryA: var(--color-black);
  --color-primaryB: var(--color-white);
  --color-accent: var(--color-purple-600);
  --color-danger: var(--color-red-400);
  --color-warn: var(--color-yellow-600);
  --color-success: var(--color-green-500);

  --color-bg: var(--color-white);
  --color-bg-overlay: rgba(0, 0, 0, .6);
  --color-bg-danger: var(--color-red-50);
  --color-bg-warn: var(--color-yellow-25);
  --color-bg-info: var(--color-gray-15);
  --color-bg-toast: var(--color-gray-650);

  --color-border: var(--color-grayish-100);
  --color-border-info: var(--color-purple-500);
  --color-border-active: var(--color-purple-500);
  --color-border-danger: var(--color-red-400);
  --color-border-warn: var(--color-yellow-600);

  --color-text-primary: var(--color-black);
  --color-text-secondary: var(--color-gray-500);

  --shadow-element: 0 2px 6px rgba(0, 0, 0, .31);
  --shadow-table: 0 2px 4px rgba(0, 0, 0, .16);
  --shadow-table-scroll: 0 -5px 15px rgba(0, 0, 0, .15);
  --shadow-card: 0 1px 4px rgba(0, 0, 0, .24);
  --shadow-card-hover: 0 4px 8px rgba(0, 0, 0, .24);
  --shadow-footer: 0 -2px 18px rgba(129, 129, 129, .16);
  --shadow-calendar: 0 1px 7px rgba(0, 0, 0, .14);
  --shadow-notification: 0 1px .5rem rgba(0, 0, 0, .24);
  --shadow-wizard: 0 2px 8px rgba(0, 0, 0, .2);
  --shadow-wizard-footer: 0 -3px 6px rgba(0, 0, 0, .06);
  --shadow-popper: var(--shadow-table);

  --shadow-border-light: inset 0 0 0 1px var(--color-grayish-100);
  --shadow-border-base: inset 0 0 0 1px var(--color-gray-900);
  --shadow-border-purple: inset 0 0 0 1px var(--color-purple-600);
  --shadow-border-purple-active: inset 0 0 0 2px var(--color-purple-600);
  --shadow-border-error: inset 0 0 0 1px var(--color-red-400);

  --shadow-pagination-item: inset 0 0 0 1px var(--color-grayish-100);

  --shadow-header: 0 3px 6px rgba(0, 0, 0, 0.06);

  // Radius
  --radius-app: .25rem;
  --radius-select-border: .25rem;

  --radius-toggle-border: .5rem;
  --radius-toggle-border-focus: .625rem;

  // Animation
  --duration-control-animation: .15s;
}
