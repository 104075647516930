@import "../../styles/common";

.BaseButton {
  position: relative;
  /* Remove the `line-height`-induced vertical space. */
  display: flex;
  /* Center content. */
  align-items: center;
  justify-content: center;
  /* Reset the default `<button/>` styles */
  margin: 0;
  padding: 0;
  white-space: nowrap;
  appearance: none;
  border: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  user-select: none;

  outline: none;

  &:not([disabled]) {
    cursor: pointer;
  }

  @include onFocus--keyboard {
    @include focus-ring;
  }
}

.wait[disabled] {
  cursor: wait;
}
