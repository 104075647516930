@import "../../styles/common";

.HamburgerButton {
  position: relative;
  flex-direction: column;
  width: 2.25rem;
  height: 2.25rem;
  margin: -.25rem;

  @include onFocus--keyboard {
    @include focus-ring();
  }

  span {
    display: block;
    width: 1.125rem;
    height: 2px;
    background-color: var(--color-purple-600);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  &[aria-expanded="true"] {
    span {
      opacity: 1;

      &:nth-last-child(1) {
        transform: rotate(45deg) translate(-5px, -5px) scale(0.85, 0.85);
      }

      &:nth-last-child(2),
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:nth-last-child(4) {
        transform: rotate(-45deg) translate(-6px, 12px) scale(0.85, 0.85);
        transform-origin: 0 100%;
      }
    }
  }
}
