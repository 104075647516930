@import "../../styles/common";

.clearButton {
  font-size: .75rem;
  line-height: .875rem;
  letter-spacing: 1.5px;
}

.clearText {
  @include bp-down(md) {
    display: none;
  }
}
