@import ../../styles/common

.RequestCourseBanner
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  margin-top: 6rem
  margin-bottom: 6rem

  +xs
    flex-direction: column
    margin-top: 0
    margin-bottom: 5rem

.RequestCourseBanner__title
  margin-bottom: 1.75rem
  font-size: 1.25rem
  font-weight: normal
