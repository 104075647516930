@import "~@acadeum/ui/src/styles/common";

.UserManagementPage {
  position: relative;

  &__createUser {
    position: absolute;
    top: -3.5rem;
    right: 0;
  }

  &__filters {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid var(--color-grayish-100);
    border-radius: 4px;
  }

  &__filter {
    min-width: 10rem;
    padding-bottom: 0;
  }

  &__userName {
    display: flex;
    flex-wrap: nowrap;
    margin-right: .25rem;
    white-space: nowrap;
  }

  &__status {
    &:not(:last-child) {
      margin-right: .25rem;
    }
  }

  &__userRole,
  &__userType {
    display: flex;
    gap: .25rem;
    white-space: nowrap;

    svg {
      color: var(--color-gray-400);
      width: 1rem;
      height: 1rem;
    }
  }

  &__roles {
    display: flex;
    flex-direction: column;
    gap: .25rem;
  }
}
