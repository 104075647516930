@import ../../styles/common

.StudentOverviewCard
  color: var(--color-black)
  margin-top: 1.5rem

  &__title
    margin-bottom: 1rem
    display: flex
    justify-content: space-between
    color: var(--color-gray-700)
    font-size: .75rem
    font-style: normal
    font-weight: 700
    line-height: .875rem
    letter-spacing: 1.5px
    text-transform: uppercase

  &__closeButton
    width: 1rem
    color: var(--color-gray-400)

  &__content
    display: flex
    flex-direction: column
    gap: 1rem

  &__optional
    color: var(--color-gray-500)
