@import "../../../styles/common";

.Option {
  width: 100%;
  margin: 0;
  padding: 0.25rem .75rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: .7;
  }

  &__container-header {
    display: flex;
  }

  &__checkmark {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    align-self: center;
  }

  &__label {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
  }

  &__labelContentWrapper {
    display: flex;
    justify-content: space-between;
  }

  &__labelContent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: auto;
  }

  &__labelCount {
    margin-left: 1rem;
  }

  &__description {
    display: block;
    color: var(--color-gray-500);
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    width: 14px;
    height: 14px;
    margin-right: .5rem;
    border: 1px solid #595959;
    border-radius: 1px;
    background-color: #fff;
    transform: translateY(5px);
  }
}

ul:not([data-listbox-appearance="white"]) .Option {
  color: var(--color-black);

  &[aria-selected="true"] {
    color: var(--color-purple-600);
    background-color: var(--color-gray-15);
  }

  &[data-focused],
  &:hover {
    color: var(--color-white);
    background-color: var(--color-purple-600);

    .Option__description {
      color: var(--color-white);
    }
  }

  &[data-focused] {
    @include bp(md) {
      background-color: var(--color-gray-15);

      &,
      .Option__description {
        color: var(--color-white);
        background-color: var(--color-purple-600);
      }
    }

    &[aria-selected="true"] .Option__checkbox {
      @include bp(md) {
        border-color: var(--color-white);
        background-color: var(--color-white);
        color: var(--color-purple-600);
      }
    }

    &[aria-selected="false"] .Option__checkbox {
      background-color: var(--color-white);
      color: var(--color-purple-600);
    }
  }

  &[aria-selected="true"] .Option__checkbox {
    border-color: var(--color-purple-600);
    background-color: var(--color-purple-600);
    color: var(--color-white);
    transform: translateY(5px);
  }

  &:hover {
    .Option__checkbox {
      border-color: var(--color-white);
      background-color: var(--color-white);
      color: var(--color-purple-600);
    }
  }

  &[data-focused] {
    .Option__checkbox {
      @include bp(md) {
        background-color: var(--color-purple-600);
        color: var(--color-white);
      }
    }
  }
}

ul[data-listbox-appearance="white"] .Option {

  &,
  &:hover,
  &[data-focused],
  &[aria-selected="true"] {
    color: var(--color-black);
  }

  &:hover {
    background-color: var(--color-gray-15);

    .Option__checkbox {
      background-color: var(--color-white);
      color: var(--color-purple-600);
    }
  }

  &[data-focused] {
    background-color: var(--color-gray-15);
  }

  &[aria-selected="true"],
  &[aria-selected="true"]:hover,
  &[aria-selected="true"][data-focused] {
    .Option__checkbox {
      border-color: var(--color-purple-600);
      background-color: var(--color-purple-600);
      color: var(--color-white);
    }
  }
}

.icon {
  width: 1rem;
}
