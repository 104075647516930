.LoadingEllipsis {
  position: relative;
  display: flex;
}

.LoadingEllipsis__dot {
  width: 20%;
  /* Makes the dot square via `height: 0` and `padding-bottom: {width}`. */
  /* https://stackoverflow.com/questions/19068070/how-to-style-a-div-to-be-a-responsive-square */
  height: 0;
  padding-bottom: 20%;
  margin-right: 20%;
  border-radius: 50%;
  background-color: transparent;
  animation: LoadingEllipsis-animation 1.5s infinite;
}

.LoadingEllipsis__dot:nth-child(1) {
}

.LoadingEllipsis__dot:nth-child(2) {
  animation-delay: 0.25s;
}

.LoadingEllipsis__dot:nth-child(3) {
  margin-right: 0;
  animation-delay: 0.5s;
}

.LoadingEllipsis--square.LoadingEllipsis__dot {
  /* "The percentage is calculated with respect to the
      width of the generated box's containing block". */
  margin-top: 40%;
}

@keyframes LoadingEllipsis-animation {
  0% {
    background-color: transparent;
  }
  40% {
    background-color: currentColor;
  }
  80% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
