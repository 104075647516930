.root {
  width: 1em;
  height: 1em;
}

.mb {
  width: 1rem;
  height: 1rem;
}

.sm {
  width: .5rem;
  height: .5rem;
}

.primary {
  color: var(--color-text-primary);
}

.secondary {
  color: var(--color-text-secondary);
}

.tertiary {
  color: var(--color-gray-700);
}

.accent {
  color: var(--color-accent);
}

.success {
  color: var(--color-success);
}

.danger {
  color: var(--color-danger);
}

.warn {
  color: var(--color-warn);
}
