@import ../styles/common.sass

\:root
  --Document-marginTop: 1.5rem
  --Document-marginTop--large: 4rem
  --Document-marginTop--largeSmallScreen: 3rem
  --Document-marginBottom: 8rem
  --Document-marginBottom--smallScreen: 5rem

.Layout
  // For z-index ordering (relative to preloading screen)
  position: relative
  z-index: 0

  // Stretches the page to 100% height
  min-height: 100vh

  // Flex styles stretch the main content to the page's height.
  flex: 1
  display: flex
  flex-direction: column

// Webpage content expands to all free space left.
.WebpageContent
  // Stretches the content
  flex-shrink: 0
  flex-grow: 1
  flex-basis: auto

.WebpageContent--withVerticalMargins
  .main-content
    margin-top: var(--Document-marginTop)
    margin-bottom: var(--Document-marginBottom)

    +xs
      margin-bottom: var(--Document-marginBottom--smallScreen)

.WebpageContent--marginTopLarge
  .main-content
    margin-top: var(--Document-marginTop--large)

    +xs
      margin-top: var(--Document-marginTop--largeSmallScreen)

.WebpageContent--noTopMargin
  .main-content
    margin-top: 0

    // Overrides any margin set above using `+xs` mixin.
    +xs
      margin-top: 0

.WebpageContent--noBottomMargin
  .main-content
    margin-bottom: 0

    // Overrides any margin set above using `+xs` mixin.
    +xs
      margin-bottom: 0
