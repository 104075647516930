.table {
  width: 100%;
  color: var(--color-gray-500);

  &-background {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #f3f3f3;
  }

  th {
    text-align: left;
  }

  th,
  td {
    height: calc(var(--unit) * 3);
    padding-right: 0.5em;

    &:last-child {
      padding-right: 0;
      // `width: 1px` is a trick to remove unused
      // whitespace from the last column of the table.
      width: 1px;
      white-space: nowrap;
    }
  }

  td {
    border-bottom: 1px solid #d9d9d9
  }

  tr {
    &:last-child {
      td {
        border-bottom: none
      }
    }
  }
}