@import "../../styles/common";

.tabs {
  &__list {
    color: var(--color-gray-500);
    display: flex;
    border-bottom: 1px solid var(--color-grayish-100);
  }

  &__listBlack {
    color: var(--color-black);
  }

  &__link {
    cursor: pointer;
    list-style: none;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: .5rem;
    background-color: transparent;
    border: none;
    outline: none;
    position: relative;

    &:not(:last-child) {
      margin-right: 1.25rem;
    }

    &:hover {
      color: var(--color-purple-600);
      border-color: var(--color-purple-600);
    }

    @include onFocus--keyboard {
      @include focus-ring();
    }

    &[aria-selected=true] {
      color: var(--color-purple-600);
      border-bottom: 2px solid var(--color-purple-600);
    }
  }

  &__section {
    margin: 1.5rem 0;
  }
}
