.Info {

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: .5rem;
    color: #000;
  }
}
