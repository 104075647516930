@import ../../styles/common.sass

.InstantSearchResult--grid,
.InstantSearchResult--rowGrid
  margin-bottom: calc(var(--unit) * 2)

.InstantSearchResult--row
  .InstantSearchResult-component
    border-bottom: 1px solid var(--gray-color-border)

  &:last-child
    .InstantSearchResult-component
      border-bottom: none

.InstantSearchResult--card
  display: block
  padding: calc(var(--unit) * 2.5)
  background-color: var(--white-color)
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24)
  box-sizing: border-box
  overflow: hidden
  font-size: 18px

  +xs
    padding: calc(var(--unit) * 2)
