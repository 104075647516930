@import "~@acadeum/ui/src/styles/common";

.ContactCard {
  color: var(--color-black);

  &__notification {
    margin-top: 1.5rem;
  }

  &__content {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border: 1px solid var(--color-grayish-100);
    border-radius: .25rem;
  }

  &__title {
    color: var(--color-gray-700);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
      color: var(--color-gray-400);
      width: 1rem;
      height: 1rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: .5rem;
    }

    svg {
      color: var(--color-gray-400);
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
    }
  }

  &__controls {
    margin-top: 1.5rem;
    display: flex;
    gap: .5rem;
  }

  &__button {
    width: 6.5rem;
  }
}
