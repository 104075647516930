.FinancialStartScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 334px;

  &__btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    display: inline-block;
  }
}
