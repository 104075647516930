@import ../../styles/common

.error-page
  padding-top: calc(var(--unit) * 7)
  padding-bottom: calc(var(--unit) * 11)
  text-align: center

  h1
    margin-top: 0
    margin-bottom: 0

  .error-page__go-back
    display: inline-block
    margin-top: 1em
    font-size: 1.4em
