@import ../../styles/common

.AdvisorRecommendations-statusWrapper
  display: flex

.AdvisorRecommendations-statusPaddingRight
  flex: 1

.AdvisorRecommendations-status
  padding-right: 4px
  flex: 0
