.FloatingLinkEditor {
  position: absolute;
  z-index: var(--layer-top);
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: .5rem .75rem;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.preview {
  display: flex;
  justify-content: space-between;
  padding: .5rem .75rem;
}

.link {
  color: var(--color-purple-600);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;

  &:hover {
    color: var(--color-purple-500);
    text-decoration: underline;
  }
}

.button {
  padding: .25rem;
  background: var(--color-gray-25);
  border-radius: .25rem;

  &:hover:not([disabled]) {
    background-color: var(--color-gray-200);
  }
}
