@import "../../styles/common/variables";
@import "../../styles/utility/mixins";

$Modal-backgroundOverlay: #00000088;

.Modal {
  position: relative;
  padding: 2rem;
  border-radius: 0;

  .rrui__modal__content {
    margin: 1rem 0;
  }

  .CloseButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.ModalBackDrop {
  z-index: var(--above-main-header-z-index);

  .ReactModal__Content {
    width: 100%;
    max-width: 36rem;
  }

  &--medium {
    .ReactModal__Content {
      max-width: 48rem;
    }
  }

  &--wide {
    .ReactModal__Content {
      max-width: 60rem;
    }
  }

  .ReactModal__Content {
    @include small-screen--xs {
      max-width: none;
    }
  }
}
