@import "../../styles/common";

.FormFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;

  &--marginTop-large {
    margin-top: 2rem;
  }

  &--marginTop-medium {
    margin-top: 1.5rem;
  }

  &--marginTop-small {
    margin-top: 1rem;
  }

  &--marginTop-none {
    margin-top: 0;
  }

  &--alignLeft {
    justify-content: flex-start;
  }

  &--alignCenter {
    justify-content: center;
  }

  &--alignBetween {
    justify-content: space-between;
  }

  @include bp-down(md) {
    flex-direction: column;
    align-items: stretch;
    gap: .5rem;
  }
}
