@import "~@acadeum/ui/src/styles/common";

.MenuItem {
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: .5rem;
  min-height: 2.5rem;
  padding: .25rem .5rem;
  width: 100%;
  color: var(--color-white);
  border-radius: .25rem;

  @include onFocus--keyboard {
    color: var(--color-white);
  }

  &:hover {
    color: var(--color-white);
    background-color: var(--color-gray-650);
  }

  &--selected {
    color: var(--color-white);
    background-color: var(--color-gray-600);

    @include onKeyboardFocusOrHover {
      color: var(--color-white);
      background-color: var(--color-gray-600);
    }
  }

  &--minimized {
    justify-content: center;
  }

  &--menuItemsStyle-column {
    min-height: 4.5rem;
    flex-direction: column;
    justify-content: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-align: center;
  }
}


.iconContainer {
  position: relative;
}

.icon {
  width: 1.3rem;
  height: 1.3rem;
  flex-shrink: 0;
  padding: 2px;
}

.notificationCount {
  background-color: #e13232;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.25rem;
  position: absolute;
  right: -9px;
  text-align: center;
  top: -9px;
  width: 1.25rem;
}
