@import "../../styles/common";

.Overlay {
  position: fixed;
  // https://developer.mozilla.org/en-US/docs/Web/CSS/inset
  // https://caniuse.com/?search=inset
  // inset: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: calc(var( --layer-top) - 1);
  cursor: wait;
}

.Inline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - var(--Header-height));
  height: 5.5rem;

  &--medium {
    height: 15rem;
  }

  &--large {
    height: 32rem;
  }

  &--huge {
    height: 48rem;
  }
}

.Initial {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  z-index: var( --layer-top);
}
