@import ../../styles/common.sass

.InstantSearch-PaginationContainer
  display: flex
  justify-content: space-between
  margin-top: var(--InstantSearch-Results-marginBottom)
  +InstantSearch--smallScreen
    flex-direction: column
    align-items: center

.InstantSearch-PaginationContainer
  .ais-Pagination-list
    margin: 0
    padding: 0
    display: flex
    align-items: center

  .ais-Pagination-item
    display: inline-block
    margin-right: var(--InstantSearch-PaginationItem-marginRight)
    &:last-child
      margin-right: 0
    +InstantSearch--smallScreen
      margin-right: var(--InstantSearch-PaginationItem-marginRight--smallScreen)

  .ais-Pagination-link
    display: block
    color: var(--InstantSearch-Pagination-color)
    text-decoration: var(--InstantSearch-Pagination-textDecoration)

  .ais-Pagination-item--selected
    .ais-Pagination-link
      color: var(--InstantSearch-Pagination-color--selected)
      // background-color: var(--InstantSearch-Pagination-backgroundColor--selected)
      text-decoration: var(--InstantSearch-Pagination-textDecoration--selected)
      font-weight: var(--InstantSearch-Pagination-fontWeight--selected)
      cursor: default

  .ais-Pagination-item--disabled
    .ais-Pagination-link
      cursor: default
      opacity: 0.4

  .ais-Pagination--noRefinement
    display: none

  .ais-Pagination-item--nextPage,
  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--firstPage,
  .ais-Pagination-item--lastPage
    position: relative
    top: var(--InstantSearch-Pagination-marginTop--arrow)
    width: var(--InstantSearch-Pagination-width--arrow)
    height: var(--InstantSearch-Pagination-width--arrow)
    font-size: 0
    .ais-Pagination-link
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      &:before
        position: absolute
        top: 0
        left: 0
        display: block
        content: ''
        width: 100%
        height: 100%
        border-radius: 4px
        background-color: var(--InstantSearch-Pagination-backgroundColor--arrow)
      &:after
        position: absolute
        top: calc((var(--InstantSearch-Pagination-width--arrow) - 4px * 2) / 2)
        left: calc((var(--InstantSearch-Pagination-width--arrow) - 4px) / 2)
        display: block
        content: ''
        width: 0
        height: 0

  .ais-Pagination-item--nextPage,
  .ais-Pagination-item--lastPage
    .ais-Pagination-link
      &:after
        border-top: 4px solid transparent
        border-bottom: 4px solid transparent
        border-left: 4px solid var(--InstantSearch-Pagination-color--arrow)
        border-right: none

  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--firstPage
    .ais-Pagination-link
      &:after
        border-top: 4px solid transparent
        border-bottom: 4px solid transparent
        border-right: 4px solid var(--InstantSearch-Pagination-color--arrow)
        border-left: none

  .ais-Pagination-item--nextPage
    margin-right: var(--InstantSearch-PaginationItem-marginRight--nextPage)

  .ais-Pagination-item--firstPage
    display: var(--InstantSearch-PaginationItem-display--firstPage)

  .ais-Pagination-item--lastPage
    display: var(--InstantSearch-PaginationItem-display--lastPage)

  .ais-Pagination-item--hasMorePrevious,
  .ais-Pagination-item--hasMoreNext
    .ais-Pagination-link
      opacity: 1
      text-decoration: none

  .ais-Pagination--noRefinement + .BackToTop
    +InstantSearch--smallScreen
      margin-top: 0

// ASP reskin styles.

.ais-Pagination-item:not(.ais-Pagination-item--disabled)
  .ais-Pagination-link
    padding-left: 0.5rem
    padding-right: 0.5rem
    height: 1.75rem
    line-height: 1.75rem
    // font-size: 14px
    border-radius: 4px
    background-color: white
    border: 1px solid #d7dbe2

  .ais-Pagination-link--selected
    background-color: #F0F0F0
    border-color: #F0F0F0

a.ais-Pagination-link
  &:not(.ais-Pagination-link--selected):active
    background-color: var(--accent-color-lightest)
    border: 1px solid #beb0d4

.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage
  .ais-Pagination-link
    padding: 0

  a.ais-Pagination-link
    &:active
      &:before
        background-color: var(--accent-color-lightest)

.InstantSearch-PaginationContainer
  .ais-Pagination-item--previousPage
    .ais-Pagination-link:after
      left: 13px

.InstantSearch-PaginationContainer
  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--nextPage
    .ais-Pagination-link
      top: 2px
      width: 2rem

      &:after
        top: 10px

.InstantSearch-ScrollTopArrow
  margin: 0
