@import "~@acadeum/ui/src/styles/common";

.root {
}

.banner {
  background-color: #052b62;
  color: white;
}

.columns {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  gap: 1rem;

  @include bp-down(md) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.logo {
  max-width: 200px;
}
