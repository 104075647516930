@import "../../styles/common";

.Accordion {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 1px var(--color-grayish-100)
}

.header {
  display: flex;
  width: 100%;
  gap: .5rem;
  padding: 1.5rem;
  border-radius: 0.25rem;
  //box-shadow: inset 0 0 0 1px var(--color-grayish-100)
  border-bottom: 1px solid var(--color-grayish-100);
}

.leftControl {
  margin-right: auto;
}

.title {
  display: flex;
  gap: .5rem;
}

.iconButton {
  @include flex-center;

  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-gray-200);
  color: var(--color-gray-400);
  font-size: 1rem;

  &[disabled] {
    opacity: 0.5;
  }

  &:hover:not([disabled]) {
    box-shadow: 0px 2px 5px 0px rgba(61, 63, 65, 0.10);
  }
}

.button {
  svg {
    margin-left: auto;
    width: 1.5rem;
    height: 1.5rem;
    transition: transform .2s ease-in-out;
  }

  &[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
}

.panel {
  padding: 1.5rem;
}
