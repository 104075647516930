@import "../../styles/common";

.ContentSection {
  border-radius: .25rem;

  &:not(.fullWidth) {
    max-width: var(--ContentSection-max-width);
  }

  & + & {
    margin-top: 1.5rem;
  }
}

.padding-lg {
  padding: 2rem;
}

.padding-md {
  padding: 1.5rem;
}

.border {
  border: 1px solid #dee3ec;
}

.header {
  @include bp-down(xl) {
    margin-bottom: .75rem;
  }
}
