@keyframes fade-in {
  0% {
    transform: translateY(3rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-2rem);
    opacity: 0;
  }
}

.Toast {
  display: inline-flex;
  gap: .5rem;
  align-items: center;
  margin-top: .5rem;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
  pointer-events: auto;
  border-radius: 0.25rem;
  //background-color: var(--color-gray-900); // If modal opened
  background-color: var(--color-gray-650);
  box-shadow: var(--shadow-card);
  color: var(--color-white);
  padding-left: .75rem;
}

.enterAnimation {
  animation: fade-in 0.3s ease-out both;
}

.exitAnimation {
  animation: fade-out 0.1s ease-in both;
}

.content {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .75rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.close {
  align-self: stretch;
  padding: 0 1rem;
  border-left: 1px solid var(--color-gray-500);
}

.errorIcon {
  color: var(--color-red-300);
}

.warnIcon {
  color: var(--color-yellow-600);
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.pendingIcon {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-play-state: running;
  animation-timing-function: linear;
}
