@import "~@acadeum/ui/src/styles/common";

.narrow {
  @include bp(md) {
    --Header-horizontal-spacing: 10%;
  }
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--layer-5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem var(--Header-horizontal-spacing);
  width: 100%;
  height: var(--Header-height);
  background-color: var(--color-white);
  box-shadow: var(--shadow-header);

  @include bp-down(md) {
    gap: .5rem;
  }

  &__nav,
  &__inner {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2rem;
  }

  &__nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__inner {
    color: var(--color-gray-500);
  }

  .Loader {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.Logo {
  display: block;
  width: 17rem;

  @include bp-down(md) {
    width: 13.5rem;
  }
}

.HeaderItem {
  margin-right: 1rem;
  flex: auto;

  &--noMargin {
    margin: 0;
  }

  &--full {
    flex: auto;
  }
}
