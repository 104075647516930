@import "@acadeum/ui/src/styles/common";

.InvoiceDetails {
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: .75rem;
  }

  &__content {
    padding: 1.5rem;
    border-radius: 4px;
    border: 1px solid var(--color-grayish-100);
  }

  &__invoice {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-grayish-100);
  }
}

.fileIcon {
  color: var(--color-text-secondary);
  width: 2.5rem;
  height: 2.5rem;
}
