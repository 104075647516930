@import ../../styles/common

.SearchIndexUpdate-status
  &--pending
    .output__value
      // color: var(--gray-color)

  &--ok
    .output__value
      color: var(--green-color)

  &--error
    .output__value
      color: var(--error-color)
      font-weight: bold

.SearchIndexUpdate-indexType
  .output__value
    color: #000000
    font-weight: bold
    background-color: #f0f0f0
    border-radius: 0.2em
    border: 1px solid var(--gray-color)
