@import ../../styles/common

.breadcrumbs
  margin: 0
  padding: 0

  li
    display: inline-block
    font-size: 14px

    &:after
      display: inline-block
      content: ''
      margin-left: 0.75em
      margin-right: 0.75em
      width: 0
      height: 0
      border-left: 4px solid currentColor
      border-top: 4px solid transparent
      border-bottom: 4px solid transparent
      color: var(--graphite-color)

    &:last-child:after
      display: none

    a
      &.current
        color: var(--black-color)

    .arrow
      font-size: 80%
