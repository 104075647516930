.Hue {
  position: absolute;
  inset: 0;
  border: 1px solid var(--color-grayish-100);
  background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  padding: 0 2px;
  border-radius: .5rem;
}

.container {
  position: relative;
  height: 100%;
}

.Pointer {
  transform: translate(-50%, -50%);
}
