@import "~@acadeum/ui/src/styles/common";

.FinancialDashboard {
  margin-top: 1.5rem;
  padding: 1.5rem;
  border: 1px solid var(--color-grayish-100);
  border-radius: .25rem;

  &__bankAccountSection {
    margin-bottom: 3rem;
  }

  &__bankAccountWarning {
    margin-bottom: 1rem;
  }
}
