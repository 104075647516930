@import "../../styles/common";

.ContentFields {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: black;
  background: #f0f0f0;

  &__header {
    display: flex;
    gap: .25rem 1rem;
    padding: .75rem 1.5rem;
  }

  &__row {
    display: flex;
    gap: .25rem 1rem;
    padding: .75rem 1.5rem;

    @include bp-down(md) {
      flex-direction: column;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  &__title {
    @include bp(md) {
      flex: 1 0 calc(40% - .5rem)
    }
  }

  &__content {
    @include bp(md) {
      flex: 1 0 calc(60% - .5rem);
    }
  }
}
