@import ../../styles/common

.react-pages__loading
  // `top: 0` was added for using `<LoadingIndicator/>` in things like
  // pagination (not page loading).
  top: 0

.react-pages__loading--shown
  // Show above the header.
  z-index: var(--above-main-header-z-index)
  height: 100%
  cursor: wait
