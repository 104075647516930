.ScheduleSection {
  border: 1px solid #dee3ec;
  border-radius: .25rem;
  padding: 1.75rem 1.5rem;
  max-height: 14.25rem;
  overflow-y: scroll;

  &__item {

  }

  &__itemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.courseSubstitute {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
