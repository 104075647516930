@import "~@acadeum/ui/src/styles/common";

.StepWrapper {
  border: 1px solid var(--color-grayish-100);
  padding: 2rem;
  display: inline-grid;
  grid-template-columns: minmax(25%, 14rem) minmax(75%, 37.5rem);
  border-radius: .25rem;

  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    max-width: 41rem;
  }

  &__copy {
    margin-bottom: 1.5rem;
  }
}
