.Output {

  dt, dd {
    font-weight: inherit;
  }
}

.label {
  color: var(--color-text-primary);
}

.value {
  color: var(--color-text-secondary);
  margin: 0;
}

.Output.inverted {
  .label {
    color: var(--color-text-secondary);
  }

  .value {
    color: var(--color-text-primary);
  }
}
