.root {
}

.content {
  padding: 1rem;
  border-radius: .25rem;
  background: var(--color-white);
  border: 1px solid var(--color-grayish-100);
  box-shadow: var(--shadow-card);
  z-index: var(--layer-top);
}
