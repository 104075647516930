@import "../../styles/common";

.SectionCard {
  display: flex;
  border: 1px solid #dee3ec;
  border-radius: 4px;
  padding: 2rem 1.5rem;

  & + & {
    margin-top: 1.5rem;
  }
}

.hasCheckbox {
  gap: .5rem 1.5rem;

  @include bp-down(md) {
    flex-direction: column;
  }
}

.content {
  flex: 1;
}

.row {
  // Reset margin-bottom for `dl` elements in courseshare
  margin-bottom: 0;
}
