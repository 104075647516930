.root {
  display: flex;
  align-items: center;
  gap: .25rem;
  min-height: 2rem;
  padding: .25rem .5rem;
  border-radius: .25rem;
  font-size: 1rem;
  color: var(--color-blue-800);
  background-color: var(--color-gray-50);
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}
