@import "../../styles/common";

.AcadeumDatePicker {
  position: relative;
  width: 100%;
  min-width: 10rem;
}

.AcadeumDatePickerError {
   .input {
    padding-right: 3rem;
  }
  .iconWrapper {
    right: .5rem;
  }
}

.AcadeumDatePickerErrorTooltip {
  position: absolute;
  top: .45rem;
  right: 1.75rem;
}

.input {
  display: block;
  min-width: 100%;
  height: 2rem;
  padding: 0.25rem 1rem + .75rem * 2 0.25rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-black);
  background-color: var(--color-gray-25);
  appearance: none;
  border: none;
  outline: 0;
  transition: 0.15s ease-in-out;
  box-shadow: var(--shadow-border-light);

  @include bp-down(md) {
    padding-bottom: 0;
    padding-top: 0;
    line-height: 2.25rem;
  }

  &:disabled {
    opacity: .5;
  }

  &:read-only,
  &[readonly] {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px #dee3ec;
  }

  @include hover {
    background-color: var(--color-white);
    box-shadow: var(--shadow-border-purple);
  }

  &:focus {
    background-color: var(--color-white);
    box-shadow: var(--shadow-border-purple);
  }

}

.iconWrapper {
  position: absolute;
  top: 1rem;
  right: .75rem;
  transform: translateY(-50%);
  display: flex;
  color: var(--color-gray-500);
}

.isInvalid {
  box-shadow: var(--shadow-border-error);
}
