.button {
  gap: .5rem;
  padding: .5rem .75rem;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border: 1px solid #6000d6;
  border-radius: .25rem;

  &[data-state="open"] > svg {
    transform: rotate(180deg);
  }
}
