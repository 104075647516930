@import "~@acadeum/ui/src/styles/common";

.CreateUserForm {
  margin-top: 1.5rem;
  max-width: 56rem;

  &__inner {
    border-radius: 4px;
    border: 1px solid var(--color-grayish-100);
    padding: 2rem 2rem .5rem;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  &__section {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 1rem;
    padding-top: 1.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-grayish-100);
    }
  }

  &__sectionTitle {
    color: var(--color-black);
  }

  &__sectionContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    @include bp-down(md) {
      grid-template-columns: 1fr;
    }
  }
}
