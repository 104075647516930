@import ../../styles/common

.search-input
  position: relative
  float: right
  width: calc(var(--unit) * 15)

  input
    padding-right: calc(var(--unit) + 1rem)
    color: var(--gray-color-800)
    &::placeholder
      color: var(--gray-color-800)

.search-input__button
  position: absolute
  top: 0
  right: 0
  width: 30px
  height: 100%
  text-align: center
  color: var(--gray-color-600)
  border: none
  background: none
  +focus-outline

  &:focus
    border: 1px solid var(--accent-color-light)
