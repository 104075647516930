@import "../mixins/typography";

.editor-heading-h1 {
  @include text-style-heading-h1;
}

.editor-heading-h2 {
  @include text-style-heading-h2;
}

.editor-heading-h3 {
  @include text-style-heading-h3;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-link {
  color: var(--color-purple-600);
  text-decoration: underline;

  &:hover {
    color: var(--color-purple-500);
    text-decoration: none;
  }
}
