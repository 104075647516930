@import "~@acadeum/ui/src/styles/common";

.block {

  &__title {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--color-black);
  }

  &__text {
    margin-top: .5rem;
    display: flex;
    align-content: center;
  }

  &__button {
    margin-top: .5rem;
    border-radius: .25rem;
  }

  &__icon {
    width: 1rem;
    margin-right: .5rem;
  }
}
