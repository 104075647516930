.ToggleCard {
  border-radius: .25rem;
  border: 1px solid #dee3ec;
  padding: 2rem;
}

.header {
  position: relative;
  display: flex;
}

.ChevronDownIcon {
  width: 1.5rem;
  margin-left: auto;
  color: #666;
  transition: .3s;

  &.isOpen {
    transform: rotate(180deg);
  }
}

.body {
  margin-top: 1.5rem;
}
