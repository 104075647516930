@import "../../styles/common";

.Separator {
  display: flex;
  align-items: center;
  color: var(--color-black);



  &:before,
  &:after {
    content: "";
    background-color: var(--color-grayish-100);
  }

  &__content {
    margin: 0;
    flex-shrink: 0;
    font-size: 1rem;
    text-transform: uppercase;
  }

  &--horizontal {
    flex-direction: row;

    &:before,
    &:after {
      height: 1px;
      width: 100%;
    }

    .Separator__content {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &.Separator--alignLeft {

      &:before {
        display: none;
      }

      .Separator__content {
        padding-left: 0;
      }
    }
  }

  &--vertical {
    flex-direction: column;
    height: 100%;

    &:before,
    &:after {
      height: 100%;
      width: 1px;
    }

    .Separator__content {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
