@import ../../styles/common.sass

.user-form__role-description
  margin-top: 0.5em
  color: var(--graphite-color)

.user-form__roles-section-heading
  margin-top: calc(var(--unit) * 3)

.user-form__role
  margin-bottom: calc(var(--unit) * 2)
  border: 1px solid var(--Tabs-borderBottomColor)
  box-sizing: border-box
  border-radius: 4px
  padding: 20px 16px

  &.rrui__checkbox--checked
    background: var(--accent-color-lightest)
    border-color: var(--purple-color)

  .rrui__checkbox__checkbox
    margin-top: 0.4em

.user-form__role-name
  font-weight: normal

.user-form-radio
  .rrui__checkbox__checkbox
    width: 1rem
    height: 1rem
    position: relative
    .rrui__checkbox__box
      display: none

  .rrui__checkbox__box-background
    border-radius: 50%
    background-color: #fff
    border: 1px solid var(--graphite-color)

  .rrui__checkbox__input:checked + .rrui__checkbox__box + .rrui__checkbox__box-background:before
    content: ''
    background-color: var(--purple-color)
    width: .625rem
    height: .625rem
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    border-radius: 50%
    z-index: 2
