@import "../../styles/common";

.DropdownMenuContent,
.DropdownMenuSubContent {
  z-index: var(--layer-top);
  min-width: 18.75rem;
  background-color: white;
  border-radius: .25rem;
  padding-block: .25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }

  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }

  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }

  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }
}

.DropdownMenuCheckboxItem,
.DropdownMenuItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  user-select: none;
  outline: none;
  padding: .5rem 1rem;
  cursor: pointer;
  font: var(--font-bodySm);
  border-radius: 0;

  &[data-disabled] {
    opacity: .5;
    pointer-events: none;
  }
}

.DropdownMenuItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  color: var(--color-black);

  &:where(&:not(&--danger)) {
    &:hover,
    &[data-highlighted] {
      text-decoration: none;
      color: var(--color-white);
      background-color: var(--color-purple-500);
    }

    @include onFocus--keyboard {
      @include focus-ring();
    }
  }

  &--danger {
    color: var(--color-red-400);

    &:hover,
    &[data-highlighted] {
      color: var(--color-white);
      background-color: var(--color-red-400);
    }

    @include onFocus--keyboard {
      @include focus-ring(var(--color-red-400));
    }
  }
}

.DropdownMenuItem--size-md,
.DropdownMenuRadioItem--size-md,
.DropdownMenuSubTrigger--size-md,
.DropdownMenuCheckboxItem--size-md {
  padding: .25rem .5rem;
}

.DropdownMenuCheckboxItem {
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grayish-100);
  }

  &[data-highlighted] {
    background-color: var(--color-gray-15);
  }
}

.DropdownMenuSubTrigger[data-state="open"] {
  background-color: var(--violet-4);
  color: var(--violet-11);
}

.DropdownMenuLabel {
  padding: .25rem 1rem;
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}

.DropdownMenuRadioItem {
  justify-content: space-between;

  &[aria-checked="true"] {
    &:not(:focus) {
      color: var(--color-purple-500);
      background-color: var(--color-gray-15);
    }
  }

  &__icon {
    color: var(--color-purple-600);
  }

  &:hover &__icon,
  &:focus &__icon {
    color: inherit;
  }
}

[data-highlighted] > .RightSlot {
  color: white;
}

[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

.DropdownMenuSeparator {
  width: 100%;
  background-color: var(--color-grayish-100);
  height: 1px;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
