@import ../../styles/common

.upload-data-page__file-upload
  margin-top: calc(var(--unit) * 3)

  .rrui__file-upload__area
    border-radius: 5px
    background-color: var(--rrui-input-field-background-color)
    border: 1px solid var(--gray-line-color)

    &:active,
    &.rrui__file-upload__area--dragged-over
      background-color: var(--accent-color-very-light)

  &--selected
    .rrui__file-upload__area
      border-top-right-radius: 0
      border-bottom-right-radius: 0

  .inline-file-upload__upload
    height: 3rem
    // Overrides the `border-radius` defined in `style.sass`.
    &.form__button:not(.form__button--text)
      border-top-left-radius: 0
      border-bottom-left-radius: 0

.upload-data-page__ignored-columns
  margin-top: calc(var(--unit) * 3)

.upload-data-page__ignored-columns-list
  margin-top: var(--unit)
  padding-left: calc(var(--unit) * 2)

.upload-data-page__errors,
.upload-data-page__data
  margin-top: calc(var(--unit) * 4)
  margin-bottom: calc(var(--unit) * 4)

.upload-data-page__errors
  h3
    margin-bottom: calc(var(--unit) * 2)
    color: var(--error-color-darker)

  li
    margin-top: calc(var(--unit) * 2)
    margin-bottom: calc(var(--unit) * 2)
    line-height: 1.35em

.upload-data-page__table
  margin-top: calc(var(--unit) * 4)

.upload-data-page__table-cell
  margin-left: calc(var(--unit) * -1)
  margin-right: calc(var(--unit) * -1)
  padding-left: var(--unit)
  padding-right: var(--unit)

.upload-data-page__table-cell--invalid
  color: var(--white-color)
  background-color: var(--error-color-darker)
  &:before
    content: "INVALID: "

.upload-data-page__download-template
  display: inline-block
  margin-top: calc(var(--unit) * 2)

.upload-data-page__download-template-icon
  display: inline-block
  width: 1em
  height: 1em
  margin-bottom: -0.1em
  margin-right: 0.25em

.inline-file-upload
  display: flex

  .rrui__file-upload
    // https://hackernoon.com/11-things-i-learned-reading-the-flexbox-spec-5f0c799c776b
    min-width: 0

  .rrui__file-upload__area
    // display: block
    height: calc(var(--unit) * 4)
    max-width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

    &:focus
      border-color: var(--accent-color-lighter)
      // Show the border shadow above the "Submit" button on the right side.
      position: relative
      z-index: 1
