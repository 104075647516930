@import "~@acadeum/ui/src/styles/common";

.Subsection {
  &--marginBottomLarge {
    margin-bottom: 3rem;
  }

  &--marginTopLarge {
    margin-top: 3rem;
  }

  &--marginTopMedium {
    margin-top: 2rem;
  }

  &__header {
    display: flex;
    margin-bottom: .5rem;
    align-items: center;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    height: 2rem;
    overflow: hidden;

    &--marginBottomLarge {
      margin-bottom: 2rem
    }
  }

  &__heading {
    margin: 0;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: .875rem;

    &--new {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__copy {
    max-width: 38.5rem;
    color: #2e2e2e;
    margin-bottom: 1.5rem;
  }

  &__headingLine {
    height: 1px;
    flex: 1;
    margin-left: 1rem;
    background-color: var(--color-grayish-100);
  }
}
