@import ../../styles/common.sass

.UserAuthHistoryEvent-adminUser,
.UserAuthHistoryEvent-automatic,
.UserAuthHistoryEvent-error,
.UserAuthHistoryEvent-errorDetails
  margin-left: 2rem

.UserAuthHistoryEvent-adminUser,
.UserAuthHistoryEvent-automatic
  margin-top: 0.5rem
  color: var(--graphite-color)

.UserAuthHistoryEvent--error
  .UserAuthHistoryEvent-icon,
  .UserAuthHistoryEvent-label
    color: var(--error-color)

.UserAuthHistoryEvent-error
  display: flex
  align-items: center
  color: var(--error-color)
  margin-top: 0.5rem
  margin-bottom: 0.5rem

.UserAuthHistoryEvent-errorIcon
  width: 0.75rem
  height: 0.75rem
  margin-right: 0.5rem

.UserAuthHistoryEvent-errorDetails
  margin-top: 0.5rem
  margin-bottom: 0
  color: var(--graphite-color)

.UserAuthHistoryEvent-icon
  width: 1.5rem
  height: 1.5rem
  margin-right: 0.5rem

.UserAuthHistoryEvent-iconAndLabel
  display: flex
  align-items: center
