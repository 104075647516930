@import ../../styles/common

.InstantSearch-ToggleSwitch
  display: inline-flex
  height: auto
  // Not overriding `--rrui-switch-accent-color` CSS variable here
  // because IE11 doesn't support CSS variables at non-`:root`.
  // The only way would be overriding `--rrui-switch-accent-color`
  // at `:root` level that wouldn't suit all apps.
  .rrui__switch__groove
    color: var(--InstantSearch-baseColor)
    background-color: var(--InstantSearch-Toggle-backgroundColor)

  .rrui__switch__groove--on
    background-color: var(--InstantSearch-Toggle-backgroundColor--on)

  .rrui__switch__knob
    background-color: var(--InstantSearch-ToggleKnob-backgroundColor)

  .rrui__switch__knob--on
    background-color: var(--InstantSearch-ToggleKnob-backgroundColor--on)

  .rrui__switch__label--right
    margin-left: 0.5em

  .rrui__switch__label
    margin-top: var(--InstantSearch-Toggle-marginTop--negative)
    margin-left: var(--InstantSearch-Toggle-marginLeft)
    font-size: var(--InstantSearch-Toggle-fontSize)

.InstantSearch-ToggleIcon
  width: 0.8em
  height: 0.8em
  margin-right: 0.35em

.InstantSearch-ToggleIconAndLabel
  display: flex
  align-items: center
