@import "../../styles/common/variables";

.FormTitle {
  // Overrides Bootstrap's styles.
  &.subtitle {
    margin-top: -0.3em;
    margin-bottom: 1rem;
    // font-family: $font-family-add;
    font-weight: normal;
    font-size: 1.5rem;
    // line-height: 1.5rem;
    color: $dark;
  }
}
