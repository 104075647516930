@import ../../../styles/common.sass

.InstantSearch-Range
  .rheostat
    width: calc(100% - 2 * 6px)
    margin-left: auto
    margin-right: auto
    height: 12px
    position: relative
    overflow: visible

  .rheostat-background
    position: relative
    top: 7px
    width: 100%
    height: 2px
    background: #dce0e0

  .rheostat-progress
    position: absolute
    top: 6px
    height: 4px
    background-color: var(--accent-color)

  .rheostat-handle
    position: absolute
    z-index: 1
    width: 12px
    height: 12px
    margin-left: -6px
    background-color: var(--accent-color)
    border-radius: 100%
    border: none
    cursor: pointer
    font-size: 0
    +focus-outline

  .rheostat--disabled
    .rheostat-progress
      background-color: #edefed

    .rheostat-handle
      cursor: default

.InstantSearch-Range-values
  display: flex
  justify-content: space-between
  margin-top: 5px
  font-size: 12px

.InstantSearch-Range-from-to-values
  display: flex
  flex-direction: column
  gap: .5rem
  margin-bottom: var(--unit)

.InstantSearch-Range-from,
.InstantSearch-Range-to
  position: relative
  z-index: 0

.InstantSearch-Range-value-currency
  position: absolute
  left: 7px
  z-index: 1
  height: 100%
  display: flex
  align-items: center
  color: var(--gray-color-800)

+InstantSearch--smallScreen
  .InstantSearch-Range-from,
  .InstantSearch-Range-to
    flex: 1

  .instant-search__range
    .rheostat-handle
      width: 30px
      height: 30px
      margin-left: -15px

    .rheostat
      width: calc(100% - 2 * 15px)

    .rheostat-progress,
    .rheostat-background
      top: 15px

  .InstantSearch-Range-values
    margin-top: 24px
    margin-left: 8px
    margin-right: 8px
