// Without this import `postcss-calc` wouldn't substitute CSS variables
// for some reason (IE11 doesn't support non-substituted CSS variables).
@import ../../styles/common

.section-header-action
  // This container is just so that the negative margin
  // is set on `.section-header-action__inner` and not
  // on the root element.

.section-header-action__inner
  height: calc(var(--unit) * 3)
  padding-left: 0.5em
  padding-right: 0.5em
  margin-left: -0.5em
  margin-right: -0.5em
  display: flex
  align-items: center
  font-size: var(--font-size)
  white-space: nowrap
  &:focus
    .section-header-action__title
      border-top: 1px solid transparent
      border-bottom: 1px solid currentColor
  &:hover
    text-decoration: none

.section-header-action__title
  line-height: 1.2em
