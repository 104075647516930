.StickyFormFooter {
  &__cancelLeft {
    margin-right: auto;
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
    line-height: .875rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
}
