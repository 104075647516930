.SuccessModal {

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // This is to center align and compensate for the size of close button
    padding-left: 2rem;
    margin-top: 3rem;
  }

  &__checkIcon {
    width: 2.5rem;
    margin-bottom: 1rem;
    color: #29b34a;
  }

  &__title {
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
