.ButtonGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem .75rem;
}

[data-buttongroup-full-width="true"] {
  .Item {
    flex: 1 1 auto;
  }
}
