.StickyFooter
  &__cancel
    font-size: .75rem
    line-height: .875rem
    font-style: normal
    font-weight: 700
    letter-spacing: 1.5px
    text-transform: uppercase
    margin-right: auto

  &__nonSticky
    z-index: 0