.ToastContainer {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  bottom: 1rem;
  pointer-events: none;
  z-index: var(--Toasts-layer);
}
