@import ../../styles/common

.SearchIndexUpdates-status
  &--pending
    // color: var(--gray-color)

  &--ok
    color: var(--green-color)

  &--error
    color: var(--error-color)
    font-weight: bold
