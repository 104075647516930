.Summary
  &__title
    margin-bottom: 1em
    text-align: center

  &__description
    margin-bottom: 2em

  &__subsectionDescription
    margin-bottom: 1.5rem

  &__studentInfo
    font-weight: 700

  &__modalAlertMessage
    color: var(--color-red-400)
    font-weight: 700
