@import "../../styles/common";

.button {
  background-color: transparent;
  padding: .25rem .5rem;
  border-radius: .25rem;
  border: 1px solid var(--color-grayish-100);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  outline: none;

  &:hover,
  &:focus {
    border-color: var(--color-purple-600);
    box-shadow: none;
  }

  &[data-state="open"] {
    border-color: var(--color-purple-600);

    .icon {
      transform: rotate(-180deg);
    }
  }
}

.icon {
  width: 1rem;
  transition: all .4s;
}

.body {
  padding: 1rem;
  background: var(--color-white);
  box-shadow: 0 2px 4px 0 #00000029;
  border-radius: .25rem;
  border: 1px solid var(--color-grayish-100);
  z-index: 50;
  margin-top: .25rem;
}

.radioWrap {
  margin: 0 0 .25rem;
  display: flex;
  gap: .5rem;
}

.label-field-input {
  margin-top: .5rem;
  display: block;
}
