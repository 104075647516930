@import ../../styles/common

.FileUpload
  .rrui__file-upload__area
    min-height: calc(var(--unit) * 8)
    padding-top: var(--unit)
    padding-bottom: var(--unit)

.FileUploadFileName
  text-decoration: underline

.FileUploadButton
  margin: calc(-1 * var(--unit) / 2)
  margin-top: calc(var(--unit) + -1 * var(--unit) / 2)
  padding: calc(var(--unit) / 2)
  color: var(--accent-color)
  border-radius: var(--Button-borderRadius)
  text-decoration: underline
  // +focus-outline
  &:focus
    background-color: var(--rrui-input-field-background-color)
  &:active
    color: var(--accent-color-lighter)
