@import "../../styles/common";

.Text {
  text-align: inherit;
  font: inherit;

  ul {
    list-style: disc;
  }
}

.accent {
  color: var(--color-accent);
}

.primary,
.black {
  color: var(--color-black);
}

.secondary,
.grey {
  color: var(--color-gray-500);
}

.tertiary,
.graphiteDark {
  color: var(--color-gray-700);
}

.success {
  color: var(--color-green-500);
}

.danger {
  color: var(--color-red-400);
}

.warn {
  color: var(--color-yellow-600);
}

.headingLg {
  @include text-style-heading-h1;
}

.headingMd {
  @include text-style-heading-h2;
}

.headingSm {
  @include text-style-heading-h3;
}

.subtitle1 {
  @include text-style-subtitle-1;
}

.subtitle2 {
  @include text-style-subtitle-2;
}

.bodyLg {
  @include text-style-body-lg;
}

.bodyLgBold {
  @include text-style-body-lg-bold;
}

.bodyMd {
  @include text-style-body-md;
}

.bodyMdBold {
  @include text-style-body-md-bold;
}

.bodySm {
  @include text-style-body-sm;
}

.bodySmBold {
  @include text-style-body-sm-bold;
}

.courseCardTitle {
  @extend .black;
  @include text-style-course-card-title;
}

.sidebar {
  @include text-style-sidebar;
}

.block {
  display: block;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.start {
  text-align: start;
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}

.isLoading {
  padding-right: 1.25em;
}

.isLoading:after {
  position: absolute;
  width: 1.25em;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* width: 0; */
  clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
}

@keyframes ellipsis {
  to {
    /* width: 1.25em; */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.uppercase {
  text-transform: uppercase;
}
