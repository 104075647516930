.Alpha {
  position: absolute;
  inset: 0;
}

.Checkboard, .gradient {
  border-radius: .5rem;
}

.gradient {
  position: absolute;
  inset: 0;
}

.Pointer {
  transform: translate(-50%, -50%);
}
