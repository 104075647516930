@import "~@acadeum/ui/src/styles/common";

.root {
  @include grid-columns();
  --grid-layout-gap: 1rem;
  --grid-column-count: 3;
  --grid-item--min-width: 22rem;

  @include bp-down(sm) {
    --grid-item--min-width: 100%;
  }
}
