@import "../../styles/common";


.IconPicker {
}

.default,
.preview {
  @include flex-center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.default {
  font-size: 1.5rem;
  color: var(--color-white);
  background-color: var(--color-gray-200);
}

.preview {
  font-size: 1.5rem;
  color: #00274b;
  background-color: #f7f8f9;
  border: 1px solid var(--color-grayish-100);
}

.icons {
  font-size: 1.5rem;
}

.PopoverContent {
  min-width: 21.5rem;
  padding: 1rem 0 0;
}

.body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1rem;
}

.footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--color-grayish-100);
}

.iconGrid {
  margin-top: 1rem;
  max-height: 13.75rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2rem, 1fr));
  gap: .75rem .25rem;
}

.button {
  width: 2rem;
  height: 2rem;
  border-radius: .25rem;

  &[data-selected="true"],
  &:hover {
    box-shadow: inset 0 0 0 2px var(--color-purple-600);
    color: var(--color-purple-600);
  }

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
