@import ../../styles/common

.action
  display: flex
  align-items: center
  padding: 0
  // height: calc(var(--unit) * 2.5)
  // +focusable-text-button-on-white(0.5em)
  &:hover
    text-decoration: none

.action__icon
  height: 1em
  // IE11 requires both `height` and `width`.
  width: 1em
  margin-right: 6px
  margin-bottom: 0.1em

.action__icon--l
  height: 1.3em
  // IE11 requires both `height` and `width`.
  width: 1.3em

.action__label
  line-height: 1.2em
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent

.action:focus
  .action__label
    border-top: 1px solid transparent
    border-bottom: 1px solid currentColor

.action--auto-height
  height: auto
  &:focus
    text-decoration: underline
    .action__label
      border: none
  .action__label
    line-height: inherit
    border: none

.action--link,
.action--inline
  display: inline-flex
