.swatch {
  position: relative;
  overflow: hidden;
}

.active {
  position: absolute;
  inset: 0;
  z-index: 2;
}
