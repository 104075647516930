@import '~@acadeum/ui/src/styles/common';

.card {
  max-width: 100%;
  width: 32.25rem;
  background-color: var(--color-white);
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: var(--shadow-card);
  align-items: center;
}
