.StickyFooter {
  &__cancel {
    margin-left: auto;
  }

  &__cancelLeft {
    font-size: .75rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.5px;
    line-height: .875rem;
    text-transform: uppercase;
  }

  &__singleSubmit {
    margin-left: auto;
  }
}
