@import ../common/variables.scss

// Duplicating the native CSS variable just for SASS.
// Must be equal to `var(--unit)` CSS variable.
$unit: 12px

// https://www.sitepoint.com/managing-responsive-breakpoints-sass/
// `$screen-sm-min` must be equal to `SMALL_SCREEN_MIN_WIDTH` in `responsive.js`.
// `$screen-md-min` must be equal to `MEDIUM_SCREEN_MIN_WIDTH` in `responsive.js`.
// `$screen-lg-min` must be equal to `LARGE_SCREEN_MIN_WIDTH` in `responsive.js`.
$screen-sm-min: $unit * 64 // 768px
$screen-xs-max: $screen-sm-min - 1
$screen-md-min: $unit * 82 // 984px
$screen-sm-max: $screen-md-min - 1
$screen-lg-min: $unit * 100 // 1200px
$screen-md-max: $screen-lg-min - 1
$screen-xl-min: $unit * 124 // 1488px
$screen-xl-container-max-width: $unit * 106
// $screen-xl-container-max-width: $unit * 124 // 1488px // Used to be equal to `$screen-lg-min`.
$screen-lg-max: $screen-xl-min - 1

$InstantSearch-maxWidth--smallScreen: $screen-sm-max

=xs
  @media all and (max-width: $screen-xs-max)
    @content

=s
  @media all and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)
    @content

=m
  @media all and (min-width: $screen-md-min) and (max-width: $screen-md-max)
    @content

=l
  @media all and (min-width: $screen-lg-min)
    @content

=xl
  @media all and (min-width: $screen-xl-min)
    @content

=xs-s
  @media all and (max-width: $screen-sm-max)
    @content

=xs-m
  @media all and (max-width: $screen-md-max)
    @content

=s-m
  @media all and (min-width: $screen-sm-min) and (max-width: $screen-md-max)
    @content

=m-l
  @media all and (min-width: $screen-md-min)
    @content

=s-l
  @media all and (min-width: $screen-sm-min)
    @content

=InstantSearch--smallScreen
  @media all and (max-width: $InstantSearch-maxWidth--smallScreen)
    @content

=small-screen--xs
  +xs
    @content

=larger-than-xs
  +s-l
    @content

=small-screen
  +xs-m
    @content

=medium-screen
  +m
    @content

=medium-or-large-screen
  +m-l
    @content

=large-screen
  +l
    @content

=small-or-medium-screen
  +xs-m
    @content

=focusable-text-button-on-white($side-padding)
  margin-left: -$side-padding
  margin-right: -$side-padding
  padding-left: $side-padding
  padding-right: $side-padding
  outline: none

  &:focus:not([disabled])
    background-color: var(--gray-color-lightest)

  &:active:not([disabled])
    background-color: var(--gray-color-light)

=outline($width: var(--outline-width), $color: var(--outline-color))
  outline: none
  box-shadow: 0 0 0 $width $color

=focus-outline($width: var(--outline-width), $color: var(--outline-color))
  &:focus
    +outline($width, $color)

=focus-background($horizontal-padding, $vertical-padding, $color, $border-radius)
  &:focus:before
    display: block
    content: ''
    position: absolute
    left: -$horizontal-padding
    top: -$vertical-padding
    width: 100% + 2 * $horizontal-padding
    height: 100% + 2 * $vertical-padding
    background-color: $color
    z-index: -1
    border-radius: $border-radius

=onFocus--keyboard
  [data-whatinput='keyboard'] &:focus
    @content

=focus-outline-thin($color: $primary, $position-y: -.25rem, $position-x: -.25rem)
  position: relative
  // This solution is not perfect.
  // In this case, with absolute positioning and using negative values,
  // the `:before` element creates additional space around the parent element
  // and will overlap other elements or create a scroll.
  //
  // For example: When used in `<ModalBody/>` as the last element and `padding-bottom: 0;` it creates a vertical scroll.
  //
  // For fixes you need to add `padding-`
  &:before
    content: ''
    position: absolute
    top: #{$position-y}
    left: #{$position-x}
    right: #{$position-x}
    bottom: #{$position-y}
    border: 2px solid #{$color}
    border-radius: inherit
    pointer-events: none
