@import "../../styles/common";

.wrapper {
  position: sticky;

  &--bottom {
    bottom: -1px;
    z-index: var(--layer-08);

    .inner.isSticky {
      padding-top: 1rem;
      box-shadow: var(--shadow-footer);
    }
  }

  &--top {
    top: var(--Header-height);
    z-index: var(--layer-09);

    .inner.isSticky {
      padding-top: 14px;
      box-shadow: var(--shadow-header);
    }
  }
}

.inner {
  background-color: var(--color-white);

  &.isSticky {
    padding-bottom: 1rem;
  }
}

.fixedWrapper {
  position: fixed;
}

.fixedInner {}

.inStream {
  z-index: 0;
}
