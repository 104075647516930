@import ~react-responsive-ui/style
@import ./react-responsive-ui.constants.sass
@import ./react-responsive-ui.small-screen.sass

\:root
  --rrui-button-side-padding: calc(var(--unit) * 2)
  --rrui-input-field-side-padding: calc(var(--unit) * 1)
  --rrui-input-field-border-color: var(--input-border-color)
  --rrui-input-field-background-color: var(--input-background-color)
  --rrui-input-field-border-color-disabled: var(--rrui-input-field-background-color)
  --rrui-input-field-label-color: var(--gray-color-700)
  --rrui-checkbox-stroke-color: var(--gray-color-400)
  --rrui-checkbox-color-focus: var(--green-color)
  --rrui-checkbox-color-checked: var(--green-color)
  --rrui-checkbox-color-active: var(--green-color-light)
  --rrui-modal-border-radius: 0
  --rrui-error-color: var(--error-color-darker)

.rrui__input,
.rrui__input-label
  +xs
    // `.form__button--inline-except-xs` must have the same height.
    height: calc(var(--unit) * 3)

.rrui__input--line-height
  +xs
    line-height: calc(var(--unit) * 3)

.rrui__input--max-height
  max-height: calc(var(--unit) * 4)
  +xs
    max-height: calc(var(--unit) * 3)

.rrui__input--multiline
  height: auto

// <TextInput/> and <DatePicker/> :focus outline.
.rrui__input-field
  +focus-outline
  &::placeholder
    color: var(--gray-color-800)

/* Place the error message below the relevant input field. */
.rrui__input-error
  position: absolute
  margin-top: 0

/* (when the `value` is empty) */
/* Required input field label asterisk. */
.rrui__input-label--required-field:after
  content: '*'
  margin-left: 0.2em

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.rrui-input-type-number--no-arrows .rrui__input-field::-webkit-inner-spin-button,
.rrui-input-type-number--no-arrows .rrui__input-field::-webkit-outer-spin-button
  margin: 0 !important
  -webkit-appearance: none !important
  -moz-appearance: textfield !important

.rrui__file-upload__area
  display: flex
  justify-content: center
  align-items: center
  padding-left: var(--unit)
  padding-right: var(--unit)
  // min-height: calc(var(--unit) * 2)
  background-color: var(--rrui-input-field-background-color)
  border: 1px solid var(--gray-line-color)
  border-radius: 5px
  cursor: pointer
  user-select: none

  &:focus
    +outline
    border-color: var(--accent-color-lighter)

.rrui__file-upload__area
  min-height: calc(var(--unit) * 4)
  +xs
    min-height: calc(var(--unit) * 3)

.rrui__file-upload__area--invalid
  border-color: var(--error-color)
  background-color: var(--error-color-lightest)

.rrui__file-upload__area:active,
.rrui__file-upload__area--dragged-over
  background-color: var(--accent-color-very-light)
  border-color: var(--accent-color-lighter)
  color: var(--white-color)

// <Select/> :focus outline.
.rrui__select--focus .rrui__select__button
  +outline

.rrui__select__option
  +xs
    height: calc(var(--unit) * 3)
    line-height: calc(var(--unit) * 3)

.rrui__select__arrow
  color: #595959
  opacity: 1

// <Checkbox/> :focus outline.
.rrui__checkbox__input:focus + .rrui__checkbox__box + .rrui__checkbox__box-background
  +outline(5px, rgba(0, 0, 58, .55))

// The global setting for `line-height` is set
// which messes with `<DatePicker/>` day buttons height
// resulting in oval-shaped background instead of a round one.
.rrui__date-picker__calendar
  // Fixes `<DatePicker/>` calendar day background circle not being round.
  line-height: 1.15em

.rrui__slideout-menu
  z-index: var(--below-main-header-z-index)

.rrui__button--text
  padding-left: 0
  padding-right: 0

// Seems to only be used for the "Download" button in a `<CourseSearch/>`.
.rrui__button--text
  .rrui__button__busy
    // No padding for the "Loading" strip.
    left: 0
    width: 100%

.rrui__tooltip__target
  +focus-outline

.rrui__tooltip
  +focus-outline

.rrui__form__error
  margin-top: calc(var(--unit) * 2)
  margin-bottom: calc(var(--unit) * 2)

// <Modal/> close button.
.rrui__modal__close:active
  color: var(--green-color)

.rrui__modal__content .rrui__form__error
  margin-bottom: calc(var(--unit) * 2)

.rrui__modal__overlay
  &.ReactModal__Overlay--after-open
    z-index: var(--above-main-header-z-index)

    +xs
      background-color: var(--white-color)

.rrui__modal__contents
  border-radius: 5px

.rrui__expandable__content,
.rrui__expandable-list.rrui__shadow
  border-radius: 5px

.rrui__list__item
  @include onFocus--keyboard
    background-color: var(--purple-color)
    color: var(--white-color)

.rrui__list__item:active
  background-color: var(--accent-color-light)
  color: var(--white-color)

.rrui__input-field:focus,
.rrui__input-field:focus:-webkit-autofill,
.rrui__select--focus .rrui__select__button:not(.rrui__select__button--invalid),
.rrui__select .rrui__select__button:not(.rrui__select__button--invalid):focus
  box-shadow: 0 0 0 1px var(--accent-color)
  background-color: white

.rrui__input-field--invalid:focus,
.rrui__select--focus .rrui__select__button--invalid,
.rrui__select .rrui__select__button--invalid:focus
  box-shadow: 0 0 0 1px var(--error-color)

.rrui__input-field:hover:not(:focus):not([disabled]):not(:read-only):not(.rrui__input-field--invalid),
.rrui__select:not(.rrui__select--focus):hover .rrui__select__button:not(.rrui__select__button--invalid):not(.rrui__select__button--disabled)
  border: 1px solid var(--accent-color)
  background-color: white

.rrui__date-picker
  .DayPicker-NavButton--prev
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' color='%23672bb2'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E")

  .DayPicker-NavButton--next
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' color='%23672bb2'%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E")

// React Bootstrap's `reboot.css` contains the following style:
//
// select:disabled {
//   opacity: 1;
// }
//
// This CSS style overwrites that Bootstrap's CSS style.
//
.rrui__select__native--disabled:disabled
  opacity: 0
