@import ../../styles/common.sass

.Tab
  // `position: relative` is because of the red notifications count buble.
  position: relative

  margin-left: 1rem
  margin-right: 1rem

  // `margin-bottom: -1px` is for the tab bar line.
  margin-bottom: -1px

  font-weight: normal
  display: flex
  align-items: center

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

  .PageLink
    // `display: inline-block` fixes `line-height`.
    display: inline-block
    border-bottom: 2px solid transparent
    line-height: 2em
    color: var(--dark-black-color)

    &:hover
      text-decoration: none

  .PageLink--selected
    color: var(--accent-color)
    border-bottom: 2px solid currentColor
