@import "../../styles/common";

.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: auto;
  min-height: 2rem;
  // There max-width is set to 18rem to prevent chips from overflowing
  // when the text is long in date range picker
  max-width: 18rem;
  padding: .25rem .5rem;
  border: none;
  border-radius: .25rem;
  background-color: var(--color-gray-50);
  color: var(--color-black);
  outline: none;

  &:hover {
    box-shadow: var(--shadow-element);
  }

  &[aria-pressed="false"] {
    border: 1px solid var(--color-gray-50);
  }

  &[aria-pressed="true"] {
    border: 1px solid var(--color-purple-600);
  }

  @include onFocus--keyboard {
    @include focus-ring(var(--color-purple-600), -.25rem, -.25rem);
  }

  &:not(:last-child) {
    margin-right: .5rem;
  }
}
