@import "~@acadeum/ui/src/styles/common";

.Institution {

  &__header {
    display: grid;
    grid-template-columns: [start] auto [endText] 1fr [startButton] auto [end];
    align-items: flex-start;
    gap: .5rem;

    &-title,
    &-link {
      grid-column: start / end;
    }

    &-buttons-wrapper {
      grid-column: startButton / end;
    }

    &-button:not(:last-child) {
      margin-right: .5rem;
    }
  }

  &__tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin: 1.5rem 0 2rem;
  }

  &__tag {
    &--teaching {
      background-color: var(--color-yellow-25);
      color: var(--color-blue-700);

      &-icon {
        color: #ffb803;
      }
    }
  }
}
