.table
  width: 100%

  td, th
    padding-left: 20px
    padding-right: 20px
    padding-top: 5px
    padding-bottom: 5px
    font-weight: normal
    text-align: left

  th
    padding-bottom: 20px
    white-space: nowrap
    color: var(--table-header-color)
    font-weight: var(--table-header-fontWeight)
    letter-spacing: var(--table-header-letterSpacing)
    font-size: var(--table-header-fontSize)
    text-align: var(--table-header-textAlign)
    text-transform: var(--table-header-textTransform)

  &--striped
    tr:nth-child(even)
      background-color: rgba(0, 0, 0, 0.03)

  &--single-line-rows
    td
      white-space: nowrap

  // E.g. institution profile schedule
  &--with-sections
    tbody
      tr
        border-bottom: 1px solid #e6e6e6
      tr:nth-child(even)
        background-color: transparent
      tr.even
        background-color: rgba(0, 0, 0, 0.03)
        border-bottom: 1px solid #ffffff
      tr.--first-in-section,
      tr.--last-in-section
        border-bottom: none

.table__button
  display: inline-flex
  align-items: center
  justify-content: center
  height: 2rem
  font-size: 12px
  line-height: 14px
  letter-spacing: 1.5px
  font-weight: bold
  text-transform: uppercase
  border-radius: 5px
  min-width: 0
  margin-right: 0
  padding-top: 0
  padding-bottom: 0
  padding-left: 1.5rem
  padding-right: 1.5rem
  text-align: center
  white-space: nowrap
  // Could be replaced with re-defining `--rrui-button-side-padding`
  // but IE11 doesn't support CSS variables.
  .rrui__button__busy
    left: 12px
    width: calc(100% - 2 * 12px)

.table__button--primary
  color: var(--white-color)
  background-color: var(--accent-color-lighter)
  border: none
  +focus-outline

  &:active
    background-color: var(--accent-color-lighter-2)

.table__button--secondary,
.table__button--secondary-borderless
  background: transparent
  color: var(--accent-color)

.table__button--secondary
  border: 1px solid var(--accent-color)
  +focus-outline

  &:active
    background-color: var(--accent-color-lightest)

.table__button--link
  &:hover
    text-decoration: none

.table__button + .table__button
  margin-left: 0.5rem

.table__buttons
  display: flex
  justify-content: flex-end

.table__buttons--vertical
  flex-direction: column
  justify-content: center

  .table__button + .table__button
    margin-left: 0
    margin-top: 0.5rem
