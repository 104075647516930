@import ../../styles/common.sass

.course-search__consortial-filter,
.course-search__approved-filter
  font-weight: bold
  color: #000000

.CourseSearch
  .FilterList
    padding-left: 0

  .RequestCourseBanner
    margin-top: 5rem
    margin-bottom: 5rem

    +xs
      // On small screens it doesn't make sense to show two identical banners:
      // one inside `<InstantSearchResults/>` and the other at the bottom of the page.
      // So just show the one at the bottom of the page.
      display: none

.FilterListWrapper
  margin: 0
  padding: 0
  display: flex
  flex-wrap: wrap
  gap: .5rem
  width: 100%
  +xs-s
    flex-direction: column

  > *
    min-width: 6.5rem

  &--multiSelect > *
    flex: 1 0 auto
    max-width: 12rem

  &--toggle
    margin-top: 1rem

.BlockFilters
  border-radius: 4px
  border: 1px solid var(--gray-line-color)
  padding: 1rem
  margin-bottom: 1rem

  &__saveSearch
    +xs-s
      margin-top: 1rem


  &__mobile-header
    display: grid
    grid-template-columns: 57% 40%
    gap: .75rem
    margin-top: 1rem
    margin-bottom: 1rem

  +s-l
    display: grid
    grid-template-columns: 1fr auto
    gap: .5rem

  &__header-modal
    display: flex
    justify-content: space-between
    margin-bottom: 1rem

  &__btn-apply-filters
    margin-top: 1rem

  &__filter
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

    +s-l
      margin-left: auto
      grid-row: 1/3
      grid-column: 2/3

  &__FilterList
    +xs
      position: fixed
      padding: 1rem
      max-width: 100%
      left: 0
      top: 64px
      bottom: 0
      right: 0
      background-color: var(--white-color)
      overflow-y: auto
      z-index: 400
      &:not(.active)
        display: none

