.root {
}

.content {
  padding: .25rem .5rem;
  background: var(--color-gray-700);
  color: var(--color-white);

  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: var(--layer-top);
}

.content[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}

.content[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}

.content[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.content[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.arrow {

}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
