@import ../../styles/common

.admin-tools-page__menu
  margin: 0
  padding-left: 0

  li
    font-size: 1.5rem
    line-height: 1.35em
    margin-top: 0.5em
    margin-bottom: 0.5em

.admin-tools__section
  margin-top: calc(var(--unit) * 2)

.admin-tools-page
  margin-top: calc(var(--unit) * 2 * -1)
  margin-bottom: calc(var(--unit) * 2 * -1)

.admin-tools__section > h2
  font-weight: 300
  font-size: var(--section-heading-font-size)
  margin-top: 0
  margin-bottom: 0
