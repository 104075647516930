@import ../../styles/common.sass

.SecondaryNav
  font-weight: 300
  font-size: 18px

  +xs
    font-size: 14px
    margin-top: 0

  &__icon
    width: 1rem
    height: 1rem
    display: inline-flex
    margin-right: .25rem

  &__icon--warning
    color: var(--yellow-color-500)

.SecondaryNav--tree
  margin-bottom: calc(var(--unit) * 2)

.SecondaryNav__section
  margin-top: calc(var(--unit) * 1)
  margin-bottom: calc(var(--unit) * 1)

.SecondaryNav__section
  position: relative
  margin-top: 0

.SecondaryNav__section-links
  margin-top: 0
  margin-bottom: 0
  padding-left: calc(var(--unit) * 2)

  .SecondaryNav__section-link
    margin-top: var(--unit)
    +xs
      margin-top: 4px

.SecondaryNav__notification
  position: absolute
  top: -10px
  left: -19px
  width: 20px
  line-height: 20px
  font-size: 11px
  text-align: center
  color: var(--white-color)
  border-radius: 50%
  background-color: var(--notification-badge-color)

  +xs
    left: -12px
    top: -8px
    width: 14px
    line-height: 14px

.SecondaryNav__section-counter
  display: inline-block
  margin-left: 0.5em
  font-weight: 300

  // &:before
  //   display: inline-block
  //   content: '('
  //   margin-right: 0.12em

  // &:after
  //   display: inline-block
  //   content: ')'
  //   margin-left: 0.12em

  // &:before,
  // &:after
  //   position: relative
  //   top: -0.075em
  //   color: var(--gray-color-400)

.SecondaryNav__section-notifications-counter
  position: absolute
  top: -10px
  right: -15px
  width: 1.25rem
  line-height: 1.25rem
  font-size: 12px
  font-weight: bold
  text-align: center
  color: var(--white-color)
  border-radius: 50%
  background-color: var(--notification-badge-color)

  +xs
    top: -2px
    right: -6px
