@import "./../../styles/common";

.Group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.OptionCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 18.5rem;
  max-width: 100%;
  padding: 2.5rem 1.5rem;
  border-radius: .5rem;
  border: 1px solid var(--color-grayish-100);
  background: var(--color-white);

  @include unify-parent("a") {
    outline: none;
  }

  @include unify-parent("button") {
    text-align: left;
    background: none;
    outline: none;
  }

  &.selected {
    color: #4788c7;
    border-width: 2px;
  }

  @include onKeyboardFocusOrHover {
    border-color: currentColor;
    color: var(--color-purple-600);

    &.selected {
      color: #4788c7;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: currentColor;
      display: block;
      width: .5rem;
      height: 100%;
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
  }

  @include onFocus--keyboard {
    &.selected {
      @include focus-ring(#4788c7, -.3rem, -.3rem);
    }
    &:not(.selected) {
      @include focus-ring(var(--color-purple-600), -.3rem, -.3rem);
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.25rem;
  height: 3.25rem;
  margin-bottom: 1rem;
  border-radius: 50%;
  background-color: #dff0fe;
  color: #4788c7;

  svg {
    width: 2.25rem;
  }
}

.title {
}

.description {
}
