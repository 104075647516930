@import ../../styles/common

.ZendeskLogin
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: 5rem
  margin-bottom: 5rem
  padding-left: 2rem
  padding-right: 2rem
  text-align: center
