@import "~@acadeum/ui/src/styles/common";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem 1rem;

  @include bp-down(xl) {
    flex-direction: column-reverse;
  }

  @include bp(xl) {
    min-height: 660px;
    padding: 6rem 10%;
  }
}

.content {
  max-width: 30rem;

  @include bp-down(xl) {
    text-align: center;
  }

  @include bp(xl) {
    max-width: 60.6%;
  }
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 1rem;

  @include bp(xl) {
    font-size: 2.75rem;
  }
}

.text {
  margin-bottom: 1rem;

  @include bp(xl) {
    font-size: 1.125rem;
    max-width: 70%;
    margin-bottom: 2rem;
  }
}

.image {
  max-width: 70%;
  margin-bottom: 1rem;

  @include bp(md) {
    max-width: 40%;
    margin-bottom: 2rem;
  }

  @include bp(xl) {
    max-width: min(36.5%, 535px);
    margin-bottom: 0;
  }
}
