@import ../../styles/common.sass

.InstantSearch-Header--largeScreen
  display: flex
  +InstantSearch--smallScreen
    display: none

.InstantSearch-FiltersHeader--smallScreen
  padding-left: 30px
  padding-right: 30px

  .InstantSearch-HeaderControls
    margin-top: 12px

  .InstantSearch-HeaderControl
    padding-bottom: 10px

.InstantSearch-Header--largeScreen
  .InstantSearch-HeaderControls
    display: flex
    align-items: center
  .InstantSearch-ClearRefinements
    margin-left: var(--unit)

.InstantSearch-Header--smallScreen
  display: none
  +InstantSearch--smallScreen
    display: block
    text-align: center

.InstantSearch-HeaderControl
  select
    font-size: var(--InstantSearch-HeaderControl-fontSize)

.InstantSearch-HeaderControlLabel
  margin-right: var(--InstantSearch-HeaderControlLabel-marginRight)
  text-transform: var(--InstantSearch-HeaderControl-textTransform)
  font-size: var(--InstantSearch-HeaderControlLabel-fontSize)
  font-weight: 300
  color: var(--InstantSearch-HeaderControlLabel-color)
  padding-top: .1em

  &:after
    display: inline-block
    content: var(--InstantSearch-HeaderControlLabel-content--after)

.InstantSearch-HeaderControlLabel--smallScreen
  display: inline-block
  padding-top: 2px
  padding-bottom: 3px
  font-size: var(--InstantSearch-HeaderControlLabel-fontSize--smallScreen)

.InstantSearch-HeaderControlSeparator
  margin-left: var(--InstantSearch-HederControlSeparator-marginLeft)
  margin-right: var(--InstantSearch-HederControlSeparator-marginRight)
  width: var(--InstantSearch-HederControlSeparator-width)
  height: 50%
  background-color: var(--gray-color)

.InstantSearch-FiltersHeader--largeScreen
  +InstantSearch--smallScreen
    display: none

.InstantSearch-FiltersHeader--smallScreen
  display: none
  +InstantSearch--smallScreen
    display: block
