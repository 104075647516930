html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

$scrollbarWidth: 0.875rem;
$scrollbarColor: var(--color-gray-20);

*::-webkit-scrollbar {
  width: $scrollbarWidth;
  height: $scrollbarWidth;
  border: 1px solid var(--color-gray-50);
}

*::-webkit-scrollbar-track {
  background: $scrollbarColor;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-300);
  border: #{($scrollbarWidth - .5rem) / 2} solid $scrollbarColor;
  border-radius: 3.125rem;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray-400);
}

body {
  margin: 0;
  font: var(--font);
  color: var(--color-text-primary);
  // Always show the vertical scrollbar so that page width doesn't jump when navigating from one page to another.
  overflow-y: scroll;
  // Hide the horizontal scrollbar its usesufull for Sticky component
  overflow-x: hidden;
}

#react, // Courseshare // Although courseshare doesn't seem to `import` this CSS file at all as of Apr 23th, 2024.
#__next {
  // Used padding-top on the #__next element instead of body
  // to avoid the issue with external libraries that rely on body element
  padding-top: var(--Header-height);
}

.underline {
  text-decoration: underline;
}

.danger {
  color: var(--color-red-400);
}

.block {
  display: block;
}

.control-icon {
  width: 1rem;
  height: 1rem;

  &--small {
    width: .5rem;
    height: .5rem;
  }

  &--extra-small {
    width: .25rem;
  }

  &--left {
    margin-right: .5rem;

    &-small {
      margin-right: .25rem;
    }
  }

  &--right {
    margin-left: .5rem;

    &-small {
      margin-left: .25rem;
    }
  }

  &--bottom {
    margin-top: .5rem;
  }

  &--top {
    margin-bottom: .5rem;
  }
}
