@import "~@acadeum/ui/src/styles/common";

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include bp(md) {
    margin-left: var(--AppSidebar-width);

    &--menuItemsStyle-column {
      margin-left: var(--AppSidebar-menuItemsStyle-column-width);
    }

    &--minimized {
      margin-left: var(--AppSidebar-minimized-width);
    }

    &--full-screen {
      margin-left: 0;
    }
  }
}

.main {
  // This style makes `<main/>` element to be of at least 100% height of the browser window,
  // minus the header height. That's because the parent element of `<main/>` is declared as
  // `display: flex` and `flex-direction: column`.
  flex-grow: 1;

  // These styles make the `<main/>` element into a "flexbox" one.
  // This way, a child element of `<main/>`, such as `<AcadeumBackground/>`,
  // can stretch itself vertically to be of at least 100% height of the browser window,
  // minus the header height. For that, the child element should be styled with `flex-grow: 1`.
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - var(--Header-height));
}

.paddedContent {
  padding: 1.5rem var(--main-horizontal-spacing) 2rem;
}
