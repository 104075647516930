@import "../../styles/common";

.root {
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.item {
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 1.5rem;
  min-width: 1.5rem;
  padding: 0 .5rem;
  border: none;
  box-shadow: var(--shadow-pagination-item);
  background-color: transparent;
  border-radius: .25rem;
  color: var(--color-gray-500);
  font-size: .875rem;

  &[aria-current="page"] {
    color: var(--color-black);
    background-color: var(--color-gray-50);
  }

  @include bp-down(md) {
    min-height: 2.25rem;
    min-width: 2.25rem;
  }

  @include onFocus--keyboard {
    @include focus-ring();
  }
}

.prev,
.next {
  padding-left: .25rem;
  padding-right: .25rem;
}
