@import ../../styles/common.sass

.InstantSearch-Toggle
  margin-top: var(--InstantSearch-Toggle-marginTop)
  margin-bottom: var(--InstantSearch-Toggle-marginTop)

  +InstantSearch--smallScreen
    margin-left: 20px
    margin-right: 20px

  .rrui__switch__label
    color: var(--graphite-color)
