@import "../../styles/sass_variables";

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem .75rem;
  gap: .75rem;
  border-radius: .25rem;
  border: none;
  box-shadow: var(--shadow-border-light);
  background: var(--color-white);
  color: var(--color-black);
  cursor: pointer;

  &:hover {
    box-shadow: var(--shadow-border-purple);
  }
}

.content {
  width: 22rem;
  max-width: calc(100vw - 2rem);
}

.dropdown {
  background-color: var(--color-gray-25);

  &__button {
    border: 1px solid var(--color-grayish-100);
    text-transform: none;
    padding: .25rem .5rem;
    color: var(--color-black);
    width: 100%;
    justify-content: space-between;

    &:focus,
    &:hover,
    &.active {
      color: var(--color-black);
      border-color: var(--color-purple-600);

      &:before {
        content: none;
      }
    }
  }

  &__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 2rem;
    padding: .25rem .5rem;
    border: none;
    border-radius: 4px;
    background-color: var(--color-gray-25);


    &:not(:disabled):focus,
    &:not(:disabled):active,
    &:not(:disabled):hover {
      box-shadow: var(--shadow-border-purple);
      background-color: var(--color-white);

      &:before {
        content: none;
      }
    }
  }

  &__toggleValue {
    border: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    background-color: inherit;
  }

  &__toggleIcon {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
  }

  &__menu {
    padding: 1rem;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    /* Main Colors/Stroke */
    border: 1px solid #dee3ec;
    min-width: 22rem;
  }

  &__institution {
    margin-top: .75rem;
  }

  &--filter {
    border-radius: .25rem;

    .dropdown__toggle {
      box-shadow: var(--shadow-border-light);
      background-color: var(--color-white);
    }
  }
}

.radioWrap {
  margin: 0 0 .5rem;
  display: flex;
  gap: .5rem;
}

.inputWrap {
  margin-bottom: .5rem;
}

.label-field-input {
  margin-top: .5rem;
  display: block;
}

.field-active {
  color: #000;
}
