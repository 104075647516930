@import "~@acadeum/ui/src/styles/common";

.BentoBox {
  &__toggle {
    position: relative;
    display: flex;
    align-items: center;
    padding: .25rem;
    margin: 0;
    background-color: transparent;
    border-color: transparent;
    color: var(--color-gray-400);
    outline: none;

    &:after {
      display: none;
    }

    &:hover {
      color: var(--color-purple-500);
    }

    @include onFocus--keyboard {
      @include focus-ring(var(--color-purple-600), 0, 0);
      color: var(--color-purple-500);
    }
  }

  &__menuItem {
    color: var(--color-gray-700);
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    outline-color: var(--color-purple-600);

    &:focus,
    &:hover {
      color: var(--color-white);
      background-color: var(--color-purple-500);
    }

    &:global(.disabled),
    &:disabled {
      color: var(--color-gray-400);
    }

    svg {
      flex-shrink: 0;
      margin-right: .5rem;
    }

    &--active {
      &:global(.disabled),
      &:disabled,
      &[data-disabled]{
        color: var(--color-purple-600);
        background-color: transparent;
      }
    }
  }

  &__actionsItem {
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    text-decoration: underline;
    justify-content: flex-start;

    &,
    &:hover {
      color: var(--color-purple-600);
    }

    &:hover {
      background-color: transparent;
      text-decoration: none;
    }
  }

  &__divider {
    margin: .25rem 0;
  }
}

@keyframes showOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideOverlay {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showMenu {
  0% {
    transform: translateY(2.5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hideMenu {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(2.5rem);
  }
}

.BentoBoxMobile {

  &__toggle {
    display: flex;
    align-items: flex-end;
    width: 2.25rem;
    height: 2.25rem;
    padding-bottom: .5rem;
    margin: -.25rem;
    margin-right: auto;
    font-size: 1.5rem;
    color: var(--color-gray-650);

    &:after {
      display: inline-block;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }

    &:hover {
      color: var(--color-purple-500);
    }

    @include onFocus--keyboard {
      @include focus-ring(var(--color-purple-600), 0, 0);
      color: var(--color-purple-500);
    }
  }

  &__overlay {
    position: fixed;
    top: var(--Header-height);
    left: 0;
    width: 100%;
    background-color: rgba(#000, .3);
    height: calc(100% - 4rem);
    //transform: translateY(-3rem);
    transition: .3s;

    &:global(.show) {
      animation: showOverlay .15s linear;

      .BentoBoxMobile__menu {
        animation: showMenu .15s linear;
      }
    }

    &:global(.hide) {
      animation: hideOverlay .15s linear;
      opacity: 0;

      .BentoBoxMobile__menu {
        animation: hideMenu .15s linear;
      }
    }
  }

  &__menu {
    margin-top: 1px;
    background-color: var(--color-white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
    padding: .5rem 0;
  }
}
