@import ../common.sass

.form
  width: 100%

.form__field,
.form .form__button--inline-except-xs
  margin-bottom: var(--FormField-marginTop)

.form--no-margin,
.outputs--no-margin
  margin-bottom: calc(var(--FormField-marginTop) * -1)

.form--no-margin-xs
  +xs
    margin-bottom: calc(var(--FormField-marginTop) * -1)

.form__buttons
  .form__button
    margin-right: var(--unit)
    &:last-child
      margin-right: 0

.form__input-error
  color: var(--error-color)

.form__errors
  margin-top: var(--FormField-marginTop)
  color: var(--error-color)

.form__errors--not-severe
  color: var(--black-color)

.form__divider
  float: left
  margin-right: var(--unit)

.form__divider
  width: 100%
  position: relative
  overflow: hidden
  font-size: 20px
  line-height: 80px
  z-index: 0

  .form__divider-title
    position: relative
    z-index: 1
    padding-right: 20px
    background-color: var(--white-color)

  &:after
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 100%
    height: 1px
    margin: auto
    content: ""
    background-color: var(--gray-color-400)

.form__field
  .rrui__file-upload__area
    width: 100%

.form__field__error-color
  color: var(--error-color)
