@import "../../styles/common/variables";

.FormDescription {
  margin-bottom: 1.5rem;

  &--dark {
    color: $dark;
  }

  &--smallBottomIndent {
    margin-bottom: .5rem;
  }
}
