@import "../../../../styles/common";

.TopToolbar {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  gap: 1rem;

  @include bp(md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.leftSide,
.rightSide {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 100%;
}

.leftSide {
  margin-right: auto;
}

.rightSide {
  margin-left: auto;
}
