.CopyToClipboard {
  color: var(--color-gray-700);
  border-radius: .25rem;
  padding: .25rem .5rem;
  margin-left: -.5rem;
  gap: .5rem;
  display: inline-flex;
  align-items: flex-start;

  &:hover,
  &:focus {
    background-color: var(--color-gray-15);
    color: var(--color-gray-700);
  }

  &__span {
    margin-top: .25rem;
    margin-bottom: .25rem;
    display: inline-block;
  }

  &__copy {
    display: none;
    color: var(--color-purple-600);
    align-items: center;
    gap: .25rem;
  }

  &:hover &__copy,
  &:focus &__copy {
    display: flex;
  }
}