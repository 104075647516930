@import ../../styles/common.sass

.InstantSearch-SearchInput
  margin-right: auto
  margin-left: auto
  text-align: center

  .ais-SearchBox-form
    // `position: relative` is for the reset button positioning.
    position: relative

  .ais-SearchBox-input
    width: 100%
    height: var(--InstantSearch-SearchInput-height)
    font-size: var(--InstantSearch-SearchInput-fontSize)
    position: relative
    padding: 0
    padding-left: var(--InstantSearch-SearchInput-paddingLeft)
    padding-right: var(--InstantSearch-SearchInput-paddingLeft)
    padding-bottom: var(--InstantSearch-SearchInput-paddingBottom)
    background-image: var(--InstantSearch-SearchInput-backgroundImage)
    background-repeat: no-repeat
    background-position: var(--InstantSearch-SearchInput-backgroundPosition)
    background-size: var(--InstantSearch-SearchInput-backgroundSize)

  &--newCourseSearchDesign .ais-SearchBox-input
    height: var(--InstantSearch-TextInput-height)
    font-size: 1rem

  .ais-SearchBox-input:valid ~ .ais-SearchBox-reset
    display: block

  .ais-SearchBox-reset
    display: none
    width: calc(var(--InstantSearch-SearchInputReset-width) * 1.5)
    height: calc(var(--InstantSearch-SearchInputReset-width) * 1.5)
    position: absolute
    top: calc((100% - var(--InstantSearch-SearchInputReset-width) * 1.5) / 2)
    right: var(--InstantSearch-SearchInputReset-right)
    margin: 0
    padding: 0
    font-size: inherit
    background: transparent
    border: none
    cursor: pointer
    fill: var(--gray-color)
    user-select: none
    border-radius: 50%
    +focus-outline

    svg
      width: var(--InstantSearch-SearchInputReset-width)
      height: var(--InstantSearch-SearchInputReset-width)

  .ais-SearchBox-resetIcon
    position: absolute
    left: calc((100% - var(--InstantSearch-SearchInputReset-width)) / 2)
    top: calc((100% - var(--InstantSearch-SearchInputReset-width)) / 2)
    width: var(--InstantSearch-SearchInputReset-width)
    height: var(--InstantSearch-SearchInputReset-width)
    fill: var(--InstantSearch-SearchInputReset-color)

  // No need to show the submit button because the search is "instant"
  .ais-SearchBox-submit
    display: none

.InstantSearch-SearchInput--smallScreen
  display: none
  margin-bottom: calc(var(--unit) * 2)
  +InstantSearch--smallScreen
    display: block

.InstantSearch-SearchInput--banner
  max-width: 745px
  margin-left: auto
  margin-right: auto

.InstantSearch-SearchInputInlineContainer
  margin-top: var(--InstantSearch-SearchInput-marginTop--inline)
  margin-bottom: var(--InstantSearch-SearchInput-marginBottom--inline)
  +InstantSearch--smallScreen
    display: none

.InstantSearch-SearchInputInlineLabel
  position: absolute
  top: -12px
  font-size: 15px
  color: var(--InstantSearch-SearchInputLabel-color)

.InstantSearch-SearchInput
  .ais-SearchBox-input
    &:focus
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='100%' height='100%' style='opacity: 1'><path d='M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z' fill='%235000B2' fill-rule='evenodd'></path></svg>")

.InstantSearch-SearchInput
  .ais-SearchBox-input:not(:focus)
    border: 1px solid #8e8b8b !important

