.Saturation {
  width: 100%;
  padding-bottom: 8.5rem;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(0deg, #000, transparent), linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
}

.Pointer {
  transform: translate(-50%, -50%);
}
