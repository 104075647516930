@import "~@acadeum/ui/src/styles/common";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.svg {
  position: absolute;
  width: min(max(160px, 16vw), 230px);
}

.PageLayoutHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 3.5rem 1rem 6.5rem;
  background: #6b16d4;
  min-height: 320px;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: clip;
  }

  &,
  &__overlay {
    .svg {
      &:nth-child(1) {
        left: 184px;
        top: 0;
      }

      &:nth-child(2) {
        left: -10px;
        top: calc(100% - 140px);
      }

      &:nth-child(3) {
        left: 55%;
        top: 45%;
        transform: rotate(81deg);

        @include bp-down(md) {
          display: none;
        }
      }

      &:nth-child(4) {
        right: 5%;
        top: 0;
        transform: translate(0, -20%) rotate(143deg);
      }

      &:nth-child(5) {
        right: 12%;
        bottom: 0;
        transform: translate(0, 70%) rotate(143deg);
      }

      &:nth-child(6) {
        right: 0;
        bottom: 0;
        transform: translate(50%, 30%);
      }
    }
  }

  &__title {
    color: white;
    z-index: 1;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 3rem;
    line-height: 1;

    @include bp(md) {
      font-size: 4.5rem;
      line-height: 1.35;
    }
  }

  &__subtitle {
    max-width: 46.25rem;
    text-align: center;
    color: #faf8ff;
    opacity: 0.8;
    z-index: 1;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.PageLayoutBody {
  position: relative;
  flex: 1 0 auto;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 5;
    margin-top: -3.5rem;
  }

  &__overlay {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .svg:nth-child(1) {
    left: 9%;
    bottom: 9%;
  }

  .svg:nth-child(2) {
    left: 26%;
    top: 3%;
  }

  .svg:nth-child(3) {
    left: 46%;
    bottom: 0;
    transform: translate(0, 62%);
  }

  .svg:nth-child(4) {
    left: 59%;
    top: 42%;
  }

  .svg:nth-child(5) {
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
  }
}

.BackButton {
  position: absolute;
  left: 0;
  top: 1.5rem;
}
