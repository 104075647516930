@mixin onFocus--keyboard {
  [data-whatinput="keyboard"] &:focus {
    @content;
  }
}

@mixin onKeyboardFocusOrHover {
  [data-whatinput="keyboard"] &:focus,
  &:hover {
    @content;
  }
}

@mixin onKeyboardFocusOrWhen($otherCaseSelector) {
  [data-whatinput="keyboard"] &:focus,
  &#{$otherCaseSelector} {
    @content;
  }
}

@mixin onFocus--nonKeyboard {
  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    @content;
  }
}

// Solving Sticky Hover States
// https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
@mixin hover() {
  @media (hover: hover) {
    &:hover {
      @content
    }
  }
}
