@import '../../../styles/common';
@import '~@acadeum/ui/src/styles/common';

.Info {
  &,
  &__header {
    max-width: 57rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  &__header-right {
    display: flex;
    gap: .5rem;
    align-items: center;
  }

  &__subsection,
  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__subsection {
    padding: 2rem;
    border-radius: .25rem;
    border: 1px solid var(--color-grayish-100);
    margin-bottom: 1.5rem;
  }

  &__item {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: .25rem 70px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__dt {
    font-weight: 400;
  }

  &__itemDescription {
    color: var(--color-gray-500);
    margin: 0;
  }
}

.mark {
  color: #fff;
  background-color: #6f37b3;
}
