@import "../../styles/common";

.ToggleButton {
  display: inline-flex;
  flex-wrap: wrap;
  gap: .5rem;
  border-radius: .25rem;
  padding: .25rem;
  border: 1px solid var(--color-grayish-100);
  color: var(--color-black);

  @include bp-down(md) {
    display: flex;
  }

  &__btn {
    flex: 1 1 auto;
    border-radius: .25rem;
    padding: .25rem .75rem;
    @include text-style-subtitle-2;

    &[aria-pressed="true"] {
      background-color: var(--color-gray-50);
    }
  }
}
