@import ../../styles/common.sass

.InstantSearch-NoResults
  margin-top: var(--InstantSearch-NoResults-marginTop)
  font-size: var(--InstantSearch-NoResults-fontSize)
  font-weight: var(--InstantSearch-NoResults-fontWeight)
  text-align: center
  +InstantSearch--smallScreen
    margin-top: var(--InstantSearch-NoResults-marginTop--smallScreen)
    font-size: var(--InstantSearch-NoResults-fontSize--smallScreen)

.InstantSearch-ResultsInfiniteScroll
  display: none
  +InstantSearch--smallScreen
    display: block

.InstantSearch-ResultsPaginated
  +InstantSearch--smallScreen
    display: none

.InstantSearch-Results
  margin-top: var(--InstantSearch-Results-marginTop)
  +InstantSearch--smallScreen
    margin-top: var(--InstantSearch-Results-marginTop--smallScreen)

.InstantSearchResults-list
  list-style: none
  padding-left: 0

