.Listbox {
  padding: 0;
  margin: 0;
  overscroll-behavior: contain;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 10rem;

  &:focus-visible {
    outline: none;
  }

  li {
    list-style: none;
  }
}
