@import ../../styles/common

.grading-scale__table
  margin-top: var(--unit)

  td
    &:nth-child(1)
      width: 10%
    &:nth-child(2), &:nth-child(3), &:nth-child(4)
      width: 30%

  // The leftmost <th/> is left-aligned
  // in the design mock-ups.
  // I'm not sure it looks good,
  // but since the designer drew it that way.
  th:first-child
    padding-left: 0

.grading-scale__table + .grading-scale__link
  margin-top: var(--unit)
  // Without `display: inline-block` the `margin-top` wouldn't work.
  display: inline-block
