.Flex {
  display: flex;
}

.inline {
  display: inline-flex;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignStretch {
  align-items: stretch;
}

.alignEnd {
  align-items: flex-end;
}

.directionRow {
  flex-direction: row;
}

.directionColumn {
  flex-direction: column;
}

.gapXs {
  gap: 0.25rem;
}

.gapSm {
  gap: 0.5rem;
}

.gapMd {
  gap: 1rem;
}

.gapLg {
  gap: 1.5rem;
}

.gapXl {
  gap: 2rem;
}

.gap2xl {
  gap: 3rem;
}

.gap3xl {
  gap: 4rem;
}

.max {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}
