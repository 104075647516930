@import ../../styles/common.sass

.DefaultLayout
  // Flex styles stretch the main content to the page's height.
  flex: 1
  display: flex
  flex-direction: column
 
  +xs

  &__breadcrumbs
    margin-top: calc(var(--unit) * 2)
    +xs
      margin-top: var(--unit)
