@import ../../styles/common

\:root
  --Button-color--dark: var(--white-color)
  --Button-backgroundColor--dark: #222222
  --Button-backgroundColor--darkActive: #111111
  --Button-outlineColor--dark: rgba(0,0,0,0.3)

  --Button-color--light: var(--white-color)
  --Button-backgroundColor--light: var(--accent-color-very-light)
  --Button-backgroundColor--lightActive: var(--accent-color-very-light)

.form__button:not(.form__button--text),
.form__button--textSecondary
  display: flex
  align-items: center
  justify-content: center
  white-space: nowrap
  height: var(--Button-height)
  padding-left: var(--Button-paddingLeft)
  padding-right: var(--Button-paddingLeft)
  font-size: var(--Button-fontSize)
  line-height: var(--Button-lineHeight)
  font-weight: var(--Button-fontWeight)
  text-transform: var(--Button-textTransform)
  letter-spacing: var(--Button-letterSpacing)
  border-radius: var(--Button-borderRadius)

// The `.form__button.` prefix is for CSS selector specificity.
.form__button.form__button--inline
  display: inline-flex

.form__button:not(.form__button--text):not(.form__button--dark)
  +focus-outline(var(--outline-width), rgba(60, 0, 91, .66))

.form__button--text
  display: inline-flex

.form__button--tableRowAction
  line-height: 1.75rem

.form__button--text
  height: auto

.form__button--link
  // Prevents the link from expanding to `width: 100%`.
  display: inline-flex

.form__button--primary
  background-color: var(--accent-color-lighter)

  &:active
    background-color: var(--accent-color-lighter-2)

.form__button--danger
  background-color: var(--error-color)

  &, &:hover, &:active
    color: var(--white-color)

.form__button--dark
  background-color: var(--Button-backgroundColor--dark)
  +focus-outline(var(--outline-width), var(--Button-outlineColor--dark))

  &, &:hover, &:active
    color: var(--Button-color--dark)

  &:active
    color: var(--Button-color--dark)
    background-color: var(--Button-backgroundColor--darkActive)

.form__button--light
  background-color: var(--Button-backgroundColor--light)

  &, &:hover, &:active
    color: var(--Button-color--light)

  &:active
    background-color: var(--Button-backgroundColor--lightActive)

.form__button--wide
  width: 100%

.form__button--text
  height: auto
  padding: 0
  background-color: transparent

  +xs
    // Duplicate the styles for XS screen for CSS selector priority.
    // Just in case any other rule attempts to apply custom XS button styling.
    height: auto
    padding: 0

  &:focus
    box-shadow: none
    text-decoration: underline

  .rrui__button__busy
    left: 0
    width: 100%

.form__button--primary
  transition: background-color 60ms
  color: var(--white-color)
  background-color: var(--accent-color)
  border: none

  &:hover
    color: var(--white-color)
    // background-color: var(--accent-color-darkest)

  &:active
    background-color: var(--accent-color-lighter-2)
    color: var(--white-color)

  &:disabled
    opacity: 0.7

    // &:hover
    //   opacity: 0.7
    //   background-color: var(--accent-color)

.form__button--secondary
  background-color: transparent

  &.form__button--border
    border: 1px solid var(--accent-color)

  &:active
    background-color: var(--accent-color-lightest)
    color: var(--accent-color-lighter)
    &.form__button--border
      border-color: var(--accent-color-lighter)

  &.form__button--dark
    color: var(--Button-backgroundColor--dark)

    &.form__button--border
      border: 1px solid var(--Button-backgroundColor--dark)

    &:active
      background-color: var(--Button-backgroundColor--dark)
      color: var(--Button-color--dark)
      &.form__button--border
        border-color: var(--Button-backgroundColor--dark)

  &.form__button--borderDark
    background-color: transparent
    color: var(--dark-black-color)
    border: 1px solid var(--dark-black-color)

.form__button--link
  &:hover
    text-decoration: none

.form__button--primary
  +focus-outline
