@import ../../styles/common

.Section
  margin-top: var(--unit)
  &:first-child
    margin-top: 0

.Section--marginTopLarge
  &:not(:first-child)
    margin-top: 2rem

.Section__header
  display: flex
  +xs
    flex-direction: column
  &--lg
    margin-bottom: 2rem
  &--md
    margin-bottom: 1.5rem
  &--sm
    margin-bottom: 1rem


.Section__heading
  flex: auto
  font-weight: 500
  font-size: 1.75rem

  &--smallHeading
    font-weight: 400
    font-size: 1.5rem

.Section__description
  font-size: 1.25rem
  margin-bottom: 2rem

  &--smallHeading
    max-width: 38.5rem
    font-size: 1rem
    color: var(--gray-color-900)

.Section__header + .Section__description
  margin-top: -1.25rem

.Section__header--smallHeading
  margin-bottom: 1.5rem

  & + .Section__description
    margin-top: -1.75rem

.Section__description--smallMargin
  margin-bottom: 0.5rem

.Section__headerActions
  display: flex
  flex-direction: column
  align-items: flex-start
  // `margin-top` depends on `.Section__header__action`'s `height`.
  margin-top: 10px
  +xs
    margin-top: var(--unit)

.Section__headerActions--inline
  flex-direction: row
  margin-left: 1.5rem
  margin-top: 4px
  +xs
    margin: 0
    flex-direction: column
    align-items: flex-start

.Section__header--inlineActions
  align-items: center
  +xs
    align-items: normal

  .Section__heading
    flex: none
