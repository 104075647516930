@import ../../styles/common.sass

.collapsible
  border-bottom: 1px solid transparent
  background-color: transparent !important

.collapsible__titleHeading
  margin-bottom: 0

.collapsible__title
  position: relative
  width: 100%
  color: var(--CollapsibleTitle-color)
  background-color: var(--CollapsibleTitle-backgroundColor)
  cursor: pointer
  // `font-weight: 300` makes the text unreadable on Windows (blue on gray).
  font-weight: normal
  user-select: none
  text-align: left

  +focus-outline

.collapsible__details
  height: 0
  opacity: 0
  overflow-y: hidden

  // Without `visibility: hidden` the focus would get "swallowed" by collapsible's content
  // even when it's in "collapsed" state. For example, if the content contains hyperlinks,
  // and the panel is in "collapsed" state, and the user tabs over it — the focus marker
  // disappears from the screen, and then reappears only after the user has tabbed over
  // every hyperlink in that "collapsed" panel's content.
  visibility: hidden

  // Padding animation creates the illusion of height animation
  transition: opacity 0.3s ease, padding-top 0.3s ease, padding-bottom 0.3s ease

  &:after
    display: table
    content: ''
    clear: both

  > p
    margin-top: 12px
    margin-bottom: 12px

    &:first-child
      margin-top: 0

    &:last-child
      margin-bottom: 0

.collapsible__details--open
  height: auto
  overflow-y: auto
  visibility: initial
  padding-top: var(--CollapsibleContent-marginTop)
  padding-bottom: var(--CollapsibleContent-marginTop)
  opacity: 1

// .collapsible__details--no-scroll
//   overflow-x: hidden

.collapsible--overflow-visible.collapsible--open
  .collapsible__details
    overflow: visible

// The default style is "big"
.collapsible__title
  font-size: var(--CollapsibleTitle-fontSize)
  font-family: var(--CollapsibleTitle-fontFamily)
  font-weight: var(--CollapsibleTitle-fontWeight)
  line-height: var(--CollapsibleTitle-height)
  +xs
    font-size: var(--CollapsibleTitle-fontSize--smallScreen)

.collapsible__arrow
  position: absolute
  right: var(--Collapsible-paddingLeft)
  transition: transform 240ms ease-in-out
  color: var(--CollapsibleArrow-color)
  // For some reason `will-change` removes the arrow jitter
  will-change: transform
  +xs
    right: var(--unit)

.collapsible__arrow--default
  top: calc((100% - (var(--CollapsibleArrow-width--default) * 7 / 12)) / 2)
  width: var(--CollapsibleArrow-width--default)
  height: calc(var(--CollapsibleArrow-width--default) * 7 / 12)
  display: var(--CollapsibleArrow-display--default)

.collapsible__arrow--triangle
  top: calc((100% - (var(--CollapsibleArrow-width--triangle) / 2)) / 2)
  width: var(--CollapsibleArrow-width--triangle)
  height: calc(var(--CollapsibleArrow-width--triangle) / 2)
  display: var(--CollapsibleArrow-display--triangle)

.collapsible__arrow--open
  transform: rotate(180deg)

.collapsible__title,
.collapsible__details
  padding-left: var(--Collapsible-paddingLeft)
  padding-right: var(--Collapsible-paddingLeft)
  +xs
    padding-left: var(--Collapsible-paddingLeft--smallScreen)
    padding-right: var(--Collapsible-paddingLeft--smallScreen)

.collapsible__content--less-side-padding
  margin-left: calc(-1 * var(--unit) * 1)
  margin-right: calc(-1 * var(--unit) * 1)

  +xs
    margin-left: calc(-1 * var(--unit) * 0.5)
    margin-right: calc(-1 * var(--unit) * 0.5)

.collapsible__content--less-side-padding-restore-padding-for-content
  padding-left: calc(var(--unit) * 1)
  padding-right: calc(var(--unit) * 1)

  +xs
    padding-left: calc(var(--unit) * 0.5)
    padding-right: calc(var(--unit) * 0.5)

.collapsible--medium
  .collapsible__title
    font-size: 16px
    line-height: calc(var(--unit) * 3.5 - 1px)

  .collapsible__arrow
    top: 14px
    right: var(--unit)

  .collapsible__details
    font-size: 14px
    line-height: 17px

.collapsible--medium .collapsible__title,
.collapsible--medium .collapsible__details
  padding-left: var(--unit)
  padding-right: var(--unit)

// ASP reskin styles.
.collapsible__title
  font-size: 1rem
  height: 3rem
  line-height: 3rem
  // background-color: #f7f7f7
  background-color: transparent
  background-color: #fafafa
  border-radius: 5px
  color: var(--dark-black-color)
  // color: var(--graphite-color)
  // border: 1px solid #f7f7f7
  border: 1px solid #e5e5e5
  // +focus-outline

  @include onFocus--keyboard
    color: var(--purple-color)
    @include focus-outline-thin

  &:hover
    color: var(--purple-color)
    @include focus-outline-thin

.collapsible__arrow
  color: #595959

.collapsibles
  > .collapsible
    margin-top: 0.25rem
    margin-bottom: 0.25rem

    &:first-child
      margin-top: 0

    &:last-child
      margin-bottom: 0

      > .collapsible__details-background > .collapsible__details
        margin-bottom: calc(-1 * var(--CollapsibleContent-marginTop))
