@import ../../styles/common

.form .form__buttons .form__button
  margin-bottom: var(--FormField-marginTop)

.form__buttons--no-margin
  margin-bottom: calc(var(--FormField-marginTop) * -1)

.form__buttons
  display: flex
  justify-content: flex-end
  margin-top: var(--FormField-marginTop)

.form__buttons--left
  justify-content: flex-start

.form__buttons--apart
  justify-content: space-between

  > :first-child
    margin-right: var(--FormField-marginTop)
