@import "~@acadeum/ui/src/styles/common";

.root {
  display: inline-flex;
  border-radius: .25rem;
  padding: .25rem;
  border: 1px solid var(--color-grayish-100);
  background-color: var(--color-white);
}

.item {
  all: unset;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem .75rem;
  color: var(--color-black);
  border-radius: .25rem;
  user-select: none;
  @include text-style-subtitle-2;

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:hover {
    //background-color: var(--violet-3);
  }
  &[data-state="on"] {
    background-color: #F0F0F0;
  }
  &:focus {
    outline: 1px solid var(--color-purple-600);
  }
}
