@import "~@acadeum/ui/src/styles/common";

.ManageCreditCard {

  &__description {
    margin-bottom: 2rem;
  }

  &__cardSection {
    position: relative;
    max-width: 38.5rem;
    border: 1px solid var(--color-grayish-100);
    border-radius: .25rem;
    padding: 1.5rem;
    min-height: 26rem;
  }

  &__cardForm {
    margin-top: 1.5rem;
  }

  &__actions {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
}
