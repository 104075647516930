@import ../../styles/common.sass

.AdminUserLog-header
  .Section__header
    margin-bottom: 2rem
    padding-bottom: 2rem
    border-bottom: 1px solid var(--gray-line-color-darker)

.AdminUserLog-actionsContainer
  font-size: 1rem
  margin-top: 0.5rem
  margin-left: 1rem
