.UserAuthHistoryTable {

  &__help {
    margin-bottom: 1.5rem;
  }

  &__skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 47vw;
    max-height: 45rem;
    min-height: 25rem;
    background: #f0f0f0;

    p {
      max-width: 20rem;
      text-align: center;
    }
  }
}
