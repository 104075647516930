@import ../../styles/common

.form-input
  // For the error label which is positioned absolutely
  position: relative

  &__label-inline
    display: flex
    justify-content: space-between

    .form-input__label
      flex-basis: 35%

    .form-input__input
      flex-basis: 65%
      margin-left: 1rem

    /* `.form-select` is occupied by Bootstrap. */
    .form-select-component
      flex-basis: 65%
      margin-left: 1rem

  .rrui__input-error
    position: absolute
    left: 0
    margin-top: 0
    font-size: .75rem

  // `position: relative` is required so that validation error labels
  // on `<input/>` fields are positioned correctly.
  .form-input__input
    position: relative

  // // On student form, the "ZIP" input field doesn't work with
  // // `.form__input-input` selector for some reason.
  // // `.rrui__text-input` is used isntead.
  // .rrui__text-input
  //   .rrui__input-error
  //     right: var(--column-gap)

  // // Overrides previously set CSS rules
  // abbr[title]
  //   border-bottom: none

// Checkbox error label style
.form__field.rrui__checkbox
  .rrui__input-error
    margin-top: -0.5rem

.form__field
  .rrui__input-label
    display: none

.form-inputs
  display: flex
  justify-content: space-between

  .rrui__date-picker:first-child
    margin-right: calc(var(--unit) * 2)

.form-input__error
  position: absolute
  color: #d0021b

.form-input__input
  display: block

.form-input__description,
.form-input__example
  //

.form-input__description
  margin-bottom: 0.25rem
