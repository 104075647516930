.Alert {
  display: flex;
  padding: .5rem 1rem;
  border-radius: .25rem;
  box-shadow: inset 0 0 0 1px currentColor;

  &__icon {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .5rem;
    margin-top: .125rem;
  }

  &--info {
    background-color: #faf8ff;
    color: #5000b2;
  }

  &--warn {
    background-color: #fef8e3;
    color: #ebc248;
  }

  &--error {
    background-color: #ffe1e1;
    color: #cd3d64;
  }

  &--borderNone {
    box-shadow: none;
  }

  &__content {
    color: #000;
  }
}

.CloseButton {
  align-self: center;
  margin-left: auto;
}

.action {
  align-self: center;
  margin-left: auto;
}
