@import "../../styles/common";

.Radio {
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  color: #000;
  cursor: pointer;
  position: relative;

  &:not(.Radio--disabled):hover .toggle {
    box-shadow: var(--shadow-card-hover), var(--shadow-border-purple);
  }

  &--border {
    padding: 0.5rem 0.75rem;
    min-width: 8rem;
    border-radius: 0.25rem;
    box-shadow: var(--shadow-border-light);

    &.Radio--checked {
      box-shadow: var(--shadow-border-purple);
      background: var(--color-gray-15);
    }

    &.Radio--error {
      box-shadow: var(--shadow-border-error);
    }

    &:focus-within {
      @include focus-ring();
    }
  }

  &--borderLarge {
    padding: 1.25rem 1rem;
  }

  &--disabled {

    &,
    .input {
      cursor: not-allowed;
    }

    .checkmark {
      opacity: .5;
    }
  }

  &--readOnly {

    &,
    .input {
      cursor: default;
    }
  }

  &--description {
    width: 100%;
    align-items: baseline;

    & .RadioInput {
      transform: translateY(2px);
    }
  }
}

.icon {
  font-size: 1.5rem;
  margin-right: .25rem;
  color: var(--color-gray-400);
}

.hidden {
  display: none;
}

.description {
  display: block;
  margin-top: .5rem;
  color: #595959;
}

.RadioInput {
  position: relative;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;

  .input {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .toggle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: var(--shadow-border-base);

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 62.5%;
      height: 62.5%;
      color: #5000b2;
      background-color: #5000b2;
      opacity: 0;
    }
  }

  .input:checked + .toggle {
    box-shadow: var(--shadow-border-purple);

    &:after {
      opacity: 1;
    }
  }

  .input {
    @include onFocus--keyboard {
      & + .toggle {
        &:not(.toggle-border) {
          @include focus-ring(var(--color-purple-600), -.25rem, -.25rem, 1px);
        }
      }
    }
  }
}
