@import "../../styles/common";

[data-buttongroup-full-width="true"] .root {
  display: flex;
  width: 100%;
}

$button-border-width: 1px;

.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
  min-width: 2.25rem;
  padding: calc(.5rem - #{$button-border-width}) 1.5rem;
  border-width: $button-border-width;
  border-style: solid;
  border-radius: .25rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  outline: none;

  &:where(&--noChildren) {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  &,
  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
    opacity: .5;
  }

  @include onFocus--keyboard {
    @include focus-ring();
  }

  &--primary {
    border-color: var(--color-purple-600);
    background-color: var(--color-purple-600);

    &, &:hover {
      color: var(--color-white);
    }

    &:hover {
      border-color: var(--color-purple-500);
      box-shadow: var(--shadow-element);
      background-color: var(--color-purple-500);
    }
  }

  &--secondary {
    border-color: var(--color-purple-600);
    background-color: var(--color-white);

    &, &:hover {
      color: var(--color-purple-600);
    }

    &:hover {
      box-shadow: var(--shadow-element);
    }
  }

  &--tertiary {
    border-color: var(--color-gray-50);
    background-color: var(--color-gray-50);

    &, &:hover {
      color: var(--color-black);
    }

    &:hover {
      box-shadow: var(--shadow-element);
    }
  }

  &--black {
    border-color: var(--color-black);
    background-color: var(--color-black);

    &, &:hover {
      color: var(--color-white);
    }

    &:hover {
      border-color: var(--color-gray-700);
      box-shadow: var(--shadow-element);
      background-color: var(--color-gray-700);
    }
  }

  &--black-outline {
    border-color: var(--color-black);
    background-color: var(--color-white);

    &, &:hover {
      color: var(--color-black);
    }

    &:hover {
      box-shadow: var(--shadow-element);
    }
  }

  &--danger {
    border-color: var(--color-red-400);
    background-color: var(--color-red-400);

    &, &:hover {
      color: var(--color-white);
    }

    &:hover {
      box-shadow: var(--shadow-element);
    }

    &.text {
      background-color: var(--red-100);
    }

    @include onFocus--keyboard {
      @include focus-ring(var(--color-red-400));
    }
  }

  &--danger-outline {
    border-color: var(--color-red-400);
    background-color: var(--color-white);

    &, &:hover {
      color: var(--color-red-400);
    }

    &:hover {
      box-shadow: var(--shadow-element);
    }

    @include onFocus--keyboard {
      @include focus-ring(var(--color-red-400));
    }
  }

  &--text,
  &--text-with-padding,
  &--text-inline {
    border: 0;
    background: transparent;
    text-transform: none;
    font-weight: inherit;
    font-size: inherit;

    &, &:hover {
      color: var(--color-purple-600);
    }
  }

  &--textWhite {
    padding: 0;
    border: 0;
    background: transparent;
    text-transform: none;
    font-weight: inherit;
    font-size: inherit;
    color: var(--color-white);

    &:hover {
      color: var(--color-white);
      text-decoration: underline;
    }
  }

  &--text,
  &--text-inline {
    padding: 0;
    letter-spacing: inherit;
  }

  &--text-inline {
    line-height: inherit;
    min-height: auto;
  }

  &--white {
    color: var(--color-black);
    border-color: var(--color-white);
    background-color: var(--color-white);

    &, &:hover {
      color: var(--color-black);
    }
  }

  &--monochrome.root--text,
  &--monochrome.root--text-with-padding,
  &--monochrome.root--text-inline {
    color: inherit;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  &--hasIcon:not(&--text):not(&--text-inline):not(&--noChildren) {
    padding-right: .75rem;
    padding-left: .875rem;
  }

  &--small {
    min-height: 1.375rem;
    padding: 0 .375rem;
  }

  @include bp-down(md) {
    min-height: 2.25rem;
  }
}

.focusRing {
  @include focus-ring();
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ButtonIcon {
  width: 1rem;
  height: 1rem;

  &--left {
    margin-right: .375rem;
  }

  &--right {
    margin-left: .375rem;
  }
}

.Spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
  animation: loading 1.1s infinite linear;
}

.Svg {
  width: 1rem;
  height: 1rem;
  margin-left: .5rem;
  transition: transform 0.2s ease-in-out;

  &--expanded {
    transform: rotate(180deg);
  }
}
