@import ../../styles/common.sass

.ConsortiumCard-link
  position: relative
  color: var(--contrast-black-color)
  transition: box-shadow 0.12s

  &:hover
    text-decoration: none
    color: var(--contrast-black-color)

  &:active
    box-shadow: 0px 1px 4px rgba(40, 20, 90, 0.5)

.ConsortiumCard-content
  width: 100%
  min-height: 4rem
  display: flex
  align-items: center
  justify-content: center

.ConsortiumCard-logoContainer
  display: flex
  align-items: center
  justify-content: center
  width: 3rem
  height: 3rem
  margin-right: 2rem

.ConsortiumCard-logo
  max-width: 3rem
  max-height: 3rem

.ConsortiumCard-logoAndName
  flex: 1
  display: flex
  align-items: center

.ConsortiumCard-nameContainer
  flex: 1

.ConsortiumCard-name
  font-size: 20px
  line-height: 32px
  font-weight: bold

  // https://stackoverflow.com/questions/16100956/prevent-after-element-from-wrapping-to-next-line
  // http://jsfiddle.net/Bk38F/65/
  &:after
    display: inline-block
    content: ''
    width: 0px
    height: 0px
    margin-right: -1rem
    margin-left: 0.5rem
    margin-bottom: 0.025em
    border-left: 0.25rem solid currentColor
    border-top: 0.25rem solid transparent
    border-bottom: 0.25rem solid transparent

.ConsortiumCard-memberCount
  font-size: 1rem
  color: #595959
