.toolbar {
  justify-content: space-between;
  position: relative;
  padding: .5rem 1rem;
  border-radius: 0.1875rem 0.1875rem 0 0;
  border: 1px solid #dee3ec;
  background: var(--color-white);
}

.toolbar,
.inner {
  display: flex;
  flex-wrap: wrap;
  gap: .25rem;
}

.item {
  padding: .25rem;
  border-radius: .25rem;
  color: #666;

  &.active {
    background-color: var(--color-gray-100);
    color: var(--color-black);
  }

  &[disabled] {
    opacity: .5;
  }

  &:hover:not([disabled]) {
    background-color: var(--color-gray-200);
  }
}

.mlAuto {
  margin-left: auto;
}

.divider {
  width: 1px;
  background: var(--color-grayish-100);
  margin-left: .5rem;
  margin-right: .5rem;
}
