@import ../../styles/common

.home-page__course-view-section-view-toggle
  display: flex
  margin-top: 28px
  font-size: 25px
  font-weight: 300
  line-height: 30px
  justify-content: center
  +xs
    margin-bottom: 12px

.home-page__course-view-section-view-toggle__buttons
  display: flex
  margin-left: 12px

.home-page__course-view-section-view-toggle__button
  padding-left: 8px
  padding-right: 8px
  padding-bottom: 2px
  border-radius: 4px
  font-size: 20px
  line-height: 36px
  color: #404040
  margin-right: 4px
  margin-top: -2px

  @include onFocus--keyboard
    @include focus-outline-thin()

  &:last-child
    margin-right: 0

  &:active
    background-color: var(--accent-color-light)
    color: white

.home-page__course-view-section-view-toggle__button--selected
  // color: white
  // background-color: #828282
  box-shadow: 0 0 0 1px inset #c0c0c0
  background-color: white

  &:active
    box-shadow: 0 0 0 1px inset var(--accent-color-light)
