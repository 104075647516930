@import ../../styles/common

.AdvisorRecommendationSection
  margin-bottom: calc(var(--unit) * 3)

  &:last-child
    margin-bottom: 0

.AdvisorRecommendationSection--selectable
  position: relative
  padding-left: calc(0.9em + 12px)

.AdvisorRecommendationSection-checkbox
  position: absolute
  left: 0
  top: -0.2rem
