@import "../../../../styles/common";

.ErrorBlock {
  color: var(--color-red-400);
  display: flex;

  svg {
    width: 1rem;
    height: 1rem;
    margin-left: .25rem;
  }
}