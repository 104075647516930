@import ../../styles/common.sass

.InstantSearch-MultiSelectList
  .ais-RefinementList-label,
  .ais-RefinementList-count
    user-select: none

  .ais-RefinementList-count
    float: right
    margin-left: 0.5em

  .ais-RefinementList-label
    font-size: var(--InstantSearch-ListLabel-fontSize)
    +InstantSearch--smallScreen
      font-size: var(--InstantSearch-ListLabel-fontSize--smallScreen)

  .ais-RefinementList-count
    margin-top: var(--InstantSearch-ListCount-marginTop)
    font-size: var(--InstantSearch-ListCount-fontSize)
    +InstantSearch--smallScreen
      margin-top: var(--InstantSearch-ListCount-marginTop--smallScreen)
      font-size: var(--InstantSearch-ListCount-fontSize--smallScreen)

  .ais-RefinementList-list
    list-style: none
    margin: 0
    padding: 0

  // The following removes the search box when there are
  // no available refinements but also removes the search box
  // when its contents match no options from the list.
  // Hence, the search box is not being hidden when there're no results.
  //
  // .ais-RefinementList--noRefinement
  //   .ais-RefinementList-searchBox
  //     display: none

  .ais-RefinementList-noResults
    display: none

  .ais-RefinementList-searchBox
    .ais-SearchBox-form
      position: relative
      margin-bottom: var(--InstantSearch-ListSearchInput-marginBottom)
      +InstantSearch--smallScreen
        margin-bottom: var(--InstantSearch-ListSearchInput-marginBottom--smallScreen)

    .ais-SearchBox-input
      width: 100%
      height: var(--InstantSearch-TextInput-height)
      padding-left: var(--InstantSearch-TextInput-paddingLeft)
      padding-right: var(--InstantSearch-TextInput-paddingLeft)
      border: var(--InstantSearch-TextInput-borderWidth) solid var(--InstantSearch-TextInput-borderColor)
      border-radius: var(--InstantSearch-TextInput-borderRadius)
      +InstantSearch--smallScreen
        height: var(--InstantSearch-TextInput-height--smallScreen)
      &:focus
        border-color: var(--InstantSearch-TextInput-borderColor--focus)
        background-color: var(--InstantSearch-TextInput-backgroundColor--focus)
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='100%' height='100%' style='opacity: 1'><path d='M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z' fill='%235000B2' fill-rule='evenodd'></path></svg>")
        background-repeat: no-repeat
        background-size: 1rem 1rem
        background-position: center right 0.75rem

        &:not(:placeholder-shown)
          background-image: none

    .ais-SearchBox-reset
      position: absolute
      right: var(--InstantSearch-TextInputReset-marginRight)
      top: calc((100% - var(--InstantSearch-TextInputReset-width) - 2 * var(--InstantSearch-TextInputReset-padding)) / 2)
      padding: var(--InstantSearch-TextInputReset-padding)
      background: none
      border: none
      font-size: 0
      cursor: pointer
      border-radius: 50%
      +focus-outline

      +InstantSearch--smallScreen
        top: var(--InstantSearch-TextInputReset-marginTop--smallScreen)
        right: var(--InstantSearch-TextInputReset-marginRight--smallScreen)
        padding: 0

    .ais-SearchBox-resetIcon
      width: var(--InstantSearch-TextInputReset-width)
      height: var(--InstantSearch-TextInputReset-width)
      fill: var(--InstantSearch-TextInputReset-color)

    .ais-SearchBox-submit
      display: none

  .ais-RefinementList-checkbox
    position: absolute
    opacity: 0
    cursor: pointer
    // Reset default browser's margin.
    margin: 0
    // Place <input/> above the fake checkbox
    // so that when clicking on the fake checkbox
    // the focus would be placed at the <input/>.
    z-index: 1

    &:focus + .ais-RefinementList-labelText:before
      +outline(3px, var(--accent-color-light))

  .ais-RefinementList-checkbox,
  .ais-RefinementList-labelText:before
    width: var(--InstantSearch-ListCheckbox-width)
    height: var(--InstantSearch-ListCheckbox-width)
    border-radius: var(--InstantSearch-ListCheckbox-borderRadius)
    +InstantSearch--smallScreen
      width: var(--InstantSearch-ListCheckbox-width--smallScreen)
      height: var(--InstantSearch-ListCheckbox-width--smallScreen)

  .ais-RefinementList-checkbox
    margin-top: var(--InstantSearch-ListCheckbox-marginTop)
    +InstantSearch--smallScreen
      margin-top: var(--InstantSearch-ListCheckbox-marginTop--smallScreen)

  .ais-RefinementList-labelText:before
    // For some reason setting `position: absolute`
    // would make the checkbox border go out of its
    // containing `<Collapsible/>` panel in terms of `z-index`.
    // position: absolute
    // This is the workaround for the `z-index` bug.
    // The formula for the negative margin is:
    // -1 * ("checkbox size" + "checkbox margin top").
    position: relative
    left: calc(-1 * (var(--InstantSearch-ListCheckbox-width) + var(--InstantSearch-ListCheckbox-marginRight)))
    top: var(--InstantSearch-ListCheckbox-marginTop)
    margin-right: calc(-1 * var(--InstantSearch-ListCheckbox-width)) // + var(--InstantSearch-ListCheckbox-borderWidth)))
    margin-bottom: calc(-1 * var(--InstantSearch-ListCheckbox-width)) //  + var(--InstantSearch-ListCheckbox-borderWidth)))
    // Rest.
    display: block
    content: ''
    background-color: var(--InstantSearch-ListCheckbox-backgroundColor)
    border: var(--InstantSearch-ListCheckbox-borderWidth) solid var(--InstantSearch-ListCheckbox-borderColor)
    border-radius: 2px
    vertical-align: middle

    +InstantSearch--smallScreen
      top: var(--InstantSearch-ListCheckbox-marginTop--smallScreen)
      left: calc(-1 * (var(--InstantSearch-ListCheckbox-width--smallScreen) + var(--InstantSearch-ListCheckbox-marginRight)))
      // The formula for the negative margin is:
      // -1 * ("checkbox size" + "checkbox margin top").
      margin-right: calc(-1 * var(--InstantSearch-ListCheckbox-width--smallScreen))
      margin-bottom: calc(-1 * var(--InstantSearch-ListCheckbox-width--smallScreen))

  .ais-RefinementList-item:active
    .ais-RefinementList-labelText:before
      background: var(--InstantSearch-ListCheckbox-backgroundColor--active)
      border: none

  .ais-RefinementList-item--selected
    .ais-RefinementList-count,
    .ais-RefinementList-label
      color: var(--InstantSearch-ListLabel-color--checked)

    .ais-RefinementList-labelText:before
      background-color: var(--InstantSearch-ListCheckbox-backgroundColor--checked)
      background-image: var(--InstantSearch-ListCheckbox-backgroundImage--checked)
      background-repeat: no-repeat
      background-position: 50%
      background-size: var(--InstantSearch-ListCheckbox-backgroundSize--checked)
      border: var(--InstantSearch-ListCheckbox-borderWidth--checked) solid var(--InstantSearch-ListCheckbox-borderColor--checked)
      +InstantSearch--smallScreen
        background-size: var(--InstantSearch-ListCheckbox-backgroundSize--checked--smallScreen)

  // .ais-RefinementList-item--selected:active
  //   .ais-RefinementList-labelText:before
  //     background: var(--white-color)
  //     border: 1px solid var(--gray-color)

  .ais-RefinementList-item
    margin-top: var(--InstantSearch-ListItem-marginTop)
    +InstantSearch--smallScreen
      margin-top: var(--InstantSearch-ListItem-marginTop--smallScreen)
    &:first-child
      margin-top: 0

  .ais-RefinementList-label
    display: flex
    // Aligning content to top instead of center
    // because labels can be multiline when they're long.
    align-items: flex-start
    cursor: pointer

  .ais-RefinementList-labelText
    flex: auto
    margin-left: calc(var(--InstantSearch-ListCheckbox-width) + var(--InstantSearch-ListCheckbox-marginRight))
    +InstantSearch--smallScreen
      margin-left: calc(var(--InstantSearch-ListCheckbox-width--smallScreen) + var(--InstantSearch-ListCheckbox-marginRight))

  .ais-RefinementList-showMore
    margin-top: var(--InstantSearch-ListShowMore-marginTop)
    font-size: 1rem

    +InstantSearch--smallScreen
      margin-top: var(--InstantSearch-ListShowMore-marginTop--smallScreen)

  .ais-RefinementList-showMore
    font-size: 14px

  .ais-RefinementList-showMore--disabled
    display: none
