@import ../../styles/common

.icon-button
  // Display `<a/>` links as "blocks".
  // Otherwise setting `height` wouldn't work.
  display: block
  width: calc(var(--icon-button-width) + 2 * var(--icon-button-padding))
  height: calc(var(--icon-button-width) + 2 * var(--icon-button-padding))
  padding: var(--icon-button-padding)
  margin: calc(-1 * var(--icon-button-padding))
  // Set <button/> colors.
  color: var(--accent-color)
  &:active
    color: var(--accent-color-light)
  &:focus
    position: relative
    outline: auto

.icon-button + .icon-button
  margin-left: calc(-1 * var(--icon-button-padding) + var(--unit))

.icon-button__icon
  width: 100%
  height: 100%

.icon-button--inline
  display: inline-block
  position: relative
  top: 0.15em
