.root {
  display: flex;
  align-items: center;
  padding-left: .5rem;
  border-radius: var(--radius-select-border);
  background-color: var(--color-gray-50);
  color: var(--color-black);
  white-space: nowrap;

  &:not(.withRemoveButton) {
    padding-inline: .5rem;
  }
}

[aria-expanded="false"] {
  .root {
    max-width: 100%;
  }

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.removeButton {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  color: var(--color-gray-500);
  padding: 0 .25rem;

  &:hover,
  &:focus-visible {
    svg {
      background-color: var(--color-red-100);
    }
  }

  svg {
    width: .875rem;
  }
}
