.FacultyCredentialsTab {
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.credential {
  border: 1px solid #DEE3EC;
  border-radius: 4px;
  padding: 1.5rem;

  & + & {
    margin-top: 1rem;
  }
}

.credential__header {
  display: flex;
  justify-content: space-between;
}
