.ShowMore {
  display: flex;
  align-items: center;

  &:after {
    content: "";
    display: inline-block;
    margin-top: .25rem;
    margin-left: .5rem;
    margin-right: .5rem;
    border-left: .25rem solid transparent;
    border-right: .25rem solid transparent;
    border-bottom: .25rem solid transparent;
    border-top: .25rem solid currentColor;
  }

  &--expanded:after {
    margin-top: 0;
    margin-bottom: .25rem;
    border-top-color: transparent;
    border-bottom-color: currentColor;
  }
}
