@import "../../styles/common";

@mixin generate-24-col($size) {
  [data-col].col-24 {
    @for $i from 1 through 24 {
      &-#{$size}-#{$i} {
        flex-basis: calc(100% / 24 * $i);
        max-width: calc(100% / 24 * $i);
      }
    }
  }
}

@mixin generate-24-offsetCol($size) {
  [data-col].offset-24 {
    @for $i from 1 through 24 {
      &-#{$size}-#{$i} {
        margin-left: calc(100% / 24 * $i);
      }
    }
  }
}

@mixin generate-12-col($size) {
  [data-col].col-12 {
    @for $i from 1 through 12 {
      &-#{$size}-#{$i} {
        flex-basis: calc(100% / 12 * $i);
        max-width: calc(100% / 12 * $i);
      }
    }
  }
}

@mixin generate-12-offsetCol($size) {
  [data-col].offset-12 {
    @for $i from 1 through 12 {
      &-#{$size}-#{$i} {
        margin-left: calc(100% / 12 * $i);
      }
    }
  }
}

@include generate-24-col(col);
@include generate-24-offsetCol(col);
@include generate-12-col(col);
@include generate-12-offsetCol(col);

@include bp(xl) { // (≥1280)
  @include generate-24-col(lg);
  @include generate-24-offsetCol(lg);
  @include generate-12-col(lg);
  @include generate-12-offsetCol(lg);
}

@include bp(md) { // (≥768)
  @include generate-24-col(md);
  @include generate-24-offsetCol(md);
  @include generate-12-col(md);
  @include generate-12-offsetCol(md);
}

@include bp(sm) { // (≥414px)
  @include generate-24-col(sm);
  @include generate-24-offsetCol(sm);
  @include generate-12-col(sm);
  @include generate-12-offsetCol(sm);
}

@include bp-down(sm) { // xs (<414px)
  @include generate-24-col(xs);
  @include generate-24-offsetCol(xs);
  @include generate-12-col(xs);
  @include generate-12-offsetCol(xs);
}
