@import "~@acadeum/ui/src/styles/common";

.buttonTrigger {
  background-color: transparent;
  outline: none;
  padding: 0;
  border: none;
  position: relative;

  @include onFocus--keyboard {
    @include focus-ring();
  }

  &[data-state="open"] {

    .icon {
      transform: rotate(-180deg);
    }
  }
}

.icon {
  width: 1rem;
  transition: all .4s;
}

.InstitutionMenu {
  display: flex;
  align-items: center;
  gap: .25rem;

  &__link {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__logo {
    height: 2rem;
    aspect-ratio: 1/1;
  }

  &__dropdown {
    z-index: 50;
    padding: 1rem;
    min-width: 30rem;
    background-color: var(--color-white);
    border-radius: 0;
    border-width: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .24);
  }
}
