// Utility classes.

// Can be used for `<span/>`s inside `<button/>`s
// because `<button/>` don't allow `<div/>`s inside.
.display-block
  display: block

.center-content
  text-align: center

.single-line
  white-space: nowrap

.children-space-between
  display: flex
  justify-content: space-between

.align-children-center
  display: flex
  align-items: center
  justify-content: center

.align-children-center-vertically
  display: flex
  align-items: center

.align-children-center-bottom
  display: flex
  align-items: flex-end
  justify-content: center

.align-children-bottom
  display: flex
  align-items: flex-end

.align-children-bottom-right
  display: flex
  align-items: flex-end
  justify-content: flex-end

.align-children-top
  display: flex
  align-items: flex-start

.expand-child
  flex: 1

.d-flex
  display: flex

.justify-content-end
  justify-content: flex-end

.justify-content-start
  justify-content: flex-start

.align-items-center
  align-items: center
