@import "../../styles/common";

.RadioCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: var(--color-white);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__radio {
    padding: 1.5rem 1rem;
    box-shadow: inset 0 0 0 1px var(--color-grayish-100);
    border-radius: 4px;
    z-index: 1;

    &--active {
      box-shadow: inset 0 0 0 1px var(--color-purple-600);
      background-color: var(--color-gray-15);
    }
  }

  &__content {
    position: relative;
    top: -.25rem;
    padding: 1.75rem 2.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--radius-select-border);
    border-bottom-right-radius: var(--radius-select-border);
    border: 1px solid var(--color-grayish-100);
    border-top: none;
  }
}
