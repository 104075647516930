@import "../../../../styles/common";

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .25rem;

  &:not([disabled]) {
    cursor: pointer;
  }

  @include onFocus--keyboard {
    @include focus-ring($color: var(--color-purple-600), $position-y: -1px, $position-x: -1px);
  }
}
