@import "../../styles/common";

.root {
  position: relative;
  margin-bottom: 1.75rem;

  &--wide {
    width: 100%;
  }

  &--password {
    margin-bottom: 2.25rem;
  }

  &--noMargin {
    margin-bottom: 0;
  }
}

.body {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.children {
  width: 100%;
}
