.TopRow {
  border-bottom: 1px solid var(--color-grayish-100);
  background: var(--color-blue-25);

  .stickyContent {
    position: sticky;
    width: var(--tableWrapper-width);
    left: 0;
    display: flex;
    justify-content: center;
    padding: 1.25rem .5rem;
  }

  .imitateActionsColumn {
    position: absolute !important;
    height: 100%;
    top: 0;
  }

  .BaseButton {
    display: inline-flex;
    color: var(--color-blue-600);;
  }
}
