@import "../../styles/common";

.Loader {
  position: relative;
  overflow: hidden;

  &.topOffset {
    &-small {
      margin-top: 1.5rem;
    }

    &-large {
      margin-top: 4rem;
    }
  }

  &.isImposter {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Spinner {
  &:before {
    content: '';
    display: block;

    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    margin-right: auto;

    border-radius: 50%;
    border-top: .125rem solid var(--color-gray-50);
    border-right: .125rem solid var(--color-gray-50);
    border-bottom: .125rem solid var(--color-gray-50);
    border-left: .125rem solid var(--color-purple-600);

    animation-name: circle;
    animation-iteration-count: infinite;
    animation-duration: 1500ms;
    animation-timing-function: linear;
  }
}

.SpinnerSmall {
  &:before {
    width: 1rem;
    height: 1rem;
  }
}

.GradientSpinner {
  display: flex;
  justify-content: center;

  &__line {
    margin-left: auto;
    margin-right: auto;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(from 180.13deg at 50.12% 50.12%, rgba(255, 255, 255, .31) 0deg, var(--color-purple-600) 360deg);
    box-sizing: border-box;
    position: relative;

    animation-name: circle;
    animation-iteration-count: infinite;
    animation-duration: 1500ms;
    animation-timing-function: linear;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color-white);
      width: 2.185rem;
      height: 2.185rem;
      border-radius: inherit;
    }
  }
}

.Logo {

  svg {
    color: var(--color-purple-600);
  }

  rect {
    animation-duration: 1500ms;
    animation-timing-function: cubic-bezier(.25, 1, 0.5, 1);
  }

  &:not(.isDeterminate) {
    rect {
      animation-name: loading-logo;
      animation-iteration-count: infinite;
    }
  }

  &.isDeterminate {
    rect {
      animation-name: loading-logo-determinate;
      animation-iteration-count: 1;
    }
  }
}

.Linear {
  width: 100%;
  background: #e9def5;
  height: .25rem;

  &.isSticky {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 90;
  }

  &:before {
    content: '';
    position: relative;
    display: block;
    height: .25rem;
    background: var(--color-purple-600);
    transform-origin: left;
  }

  &:not(.isDeterminate) {
    &:before {
      width: 25%;
      animation: linear-animation 1.5s cubic-bezier(.25, 1, 0.5, 1) infinite;
    }
  }

  &.isDeterminate {
    &:before {
      animation: linear-animation-width 1.5s cubic-bezier(.25, 1, 0.5, 1);
    }
  }
}

@keyframes linear-animation {
  0% {
    left: 0;
    right: auto;
  }

  100% {
    left: 100%;
  }
}

@keyframes linear-animation-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes loading-logo-determinate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes loading-logo {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}
