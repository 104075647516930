@import "../../styles/common";

.KebabButton {
  min-height: 2rem;
  width: 2.5rem;
  border-right: .25rem;
  border-color: var(--color-gray-50);
  background-color: var(--color-gray-50);

  &,
  &:hover {
    color: var(--color-black);
  }

  &:hover {
    box-shadow: var(--shadow-element);
  }
}

.KebabToggle {
  color: var(--color-gray-400);

  &:hover {
    color: var(--color-purple-500);
  }

  &:disabled {
    color: var(--color-black);
    opacity: .3;
  }

  @include onFocus--keyboard {
    @include focus-ring();
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.DropdownMenuContent {
  min-width: 12rem;
}
