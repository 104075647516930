@import "../../styles/common.sass";

.RadioButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: .85rem;

  &__radio {
    margin-right: .5rem;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    border-radius: 50%;
    background-color: var(--white-color);
    border: 1px solid var(--graphite-color);

    &_isError {
      border-color: var(--error-color);
    }
  }

  &__input {
    // Hide `<input type="radio"/>`.
    // https://stackoverflow.com/questions/18078871/hide-check-radio-button-with-css
    position: fixed;
    opacity: 0;
    pointer-events: none;

    &:focus + .RadioButton__radio {
      box-shadow: 0 0 0 0.2em var(--outline-color);
    }

    &:checked + .RadioButton__radio {
      border-color: var(--accent-color);
    }

    &:checked + .RadioButton__radio:before {
      content: '';
      background-color: var(--accent-color);
      width: .625rem;
      height: .625rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: 2;
      display: block;
    }

    &__label {
      color: var(--dark-black-color);

      // &_isError {
      //   color: var(--error-color-darker);
      // }
    }
  }
}
