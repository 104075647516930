.CourseBadges {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: .5rem;
}

.reverse {
  flex-direction: row-reverse;
}
