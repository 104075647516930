.CircularProgress {
  &:before {
    content: "";
    display: block;

    margin-left: auto;
    margin-right: auto;

    border-radius: 50%;
    border-top: .125rem solid var(--color-gray-50);
    border-right: .125rem solid var(--color-gray-50);
    border-bottom: .125rem solid var(--color-gray-50);
    border-left: .125rem solid var(--color-purple-600);

    animation-name: circle;
    animation-iteration-count: infinite;
    animation-duration: 1500ms;
    animation-timing-function: linear;
  }
}

.md:before {
  width: 1.5rem;
  height: 1.5rem;
}

.sm:before {
  width: 1rem;
  height: 1rem;
}

@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}
