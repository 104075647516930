@import "../../styles/common";

.Accordion {
  display: flex;
  flex-direction: column;
}

.button {
  display: flex;
  width: 100%;
  gap: .5rem;
  height: 1.5rem;
}

.icon {
  margin-left: auto;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform .2s ease-in-out;

  &.open {
    transform: rotate(180deg);
  }
}
