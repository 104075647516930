.Loader
  position: relative
  overflow: hidden

  &.isImposter
    height: 100%
    display: flex
    justify-content: center
    align-items: center

  &--logo
    
    svg
      color: #5000b2
    
    rect
      animation-duration: 1500ms
      animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1)
    
    &:not(.isDeterminate)
      rect
        animation-name: loading-logo
        animation-iteration-count: infinite
    
    &.isDeterminate
      rect
        animation-name: loading-logo-determinate
        animation-iteration-count: 1

@keyframes loading-logo-determinate
  0%
    transform: translateX(100%)
    
  100%
    transform: translateX(0)

@keyframes loading-logo
  0%
    transform: translateX(100%)
  100%
    transform: translateX(-100%)
