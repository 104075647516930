@import "../../styles/common";

.AcadeumBackground {
  position: relative;
  min-height: calc(100vh - var(--Header-height));
  // flex-grow: 1;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221280%22%20height%3D%22400%22%20viewBox%3D%220%200%201280%20400%22%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M1280%200L630.448%20400H1280z%22%2F%3E%3C%2Fg%3E%3Cg%20opacity%3D%22.8%22%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M962.88%20195.615L0%20399.662l646.922.338z%22%2F%3E%3C%2Fg%3E%3Cg%20opacity%3D%22.3%22%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M981.045%20236.979L10.083%20400%201270.978%2064.318z%22%2F%3E%3C%2Fg%3E%3Cg%20opacity%3D%22.3%22%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M764.18%20400H0V167.531z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-color: var(--color-white);
  background-repeat: no-repeat;
  background-position: center 3rem;
  background-size: cover;

  @include bp-down(md) {
    background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22480%22%20height%3D%22589%22%20viewBox%3D%220%200%20480%20589%22%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M480%200L236.418%20588.71H480z%22%2F%3E%3C%2Fg%3E%3Cg%20opacity%3D%22.8%22%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M365.336%20288.95L0%20588.215l247.422.497z%22%2F%3E%3C%2Fg%3E%3Cg%20opacity%3D%22.3%22%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M367.892%20348.78L3.782%20588.71%20476.616%2094.662z%22%2F%3E%3C%2Fg%3E%3Cg%20opacity%3D%22.3%22%3E%3Cpath%20fill%3D%22%23543992%22%20d%3D%22M286.567%20588.71H0V204.275z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-position: top;
  }

  padding-top: 4rem;
  padding-left: var(--Header-horizontal-spacing);
  padding-right: var(--Header-horizontal-spacing);

  &__title {
    color: var(--color-purple-600);
  }

  &__subtitle {
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
}
