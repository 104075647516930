.ChangeEnrollmentStatusModal {
  border-radius: .25rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  &--canInputGradesForSomeEnrollments {
    border: 1px solid var(--gray-line-color);
  }

  &__field {
    background-color: #fff;
  }

  &:not(&--canInputGradesForSomeEnrollments) {
    background: var(--color-gray-25);
  }
}
