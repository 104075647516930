$sizes: (
  "none": 0rem,
  "auto": auto,
  "xs": 0.25rem,
  "sm": 0.5rem,
  "md": 1rem,
  "lg": 1.5rem,
  "xl": 2rem,
  "2xl": 3rem,
  "3xl": 4rem,
  "-xs": -0.25rem,
  "-sm": -0.5rem,
  "-md": -1rem,
  "-lg": -1.5rem,
  "-xl": -2rem,
  "-2xl": -3rem,
  "-3xl": -4rem
);

@mixin m($componentName) {
  @each $name, $size in $sizes {
    .m-#{$componentName}-#{$name} {
      margin: $size;
    }
  }
}

@mixin mt($componentName) {
  @each $name, $size in $sizes {
    .mt-#{$componentName}-#{$name} {
      margin-top: $size;
    }
  }
}

@mixin mr($componentName) {
  @each $name, $size in $sizes {
    .mr-#{$componentName}-#{$name} {
      margin-right: $size;
    }
  }
}

@mixin mb($componentName) {
  @each $name, $size in $sizes {
    .mb-#{$componentName}-#{$name} {
      margin-bottom: $size;
    }
  }
}

@mixin ml($componentName) {
  @each $name, $size in $sizes {
    .ml-#{$componentName}-#{$name} {
      margin-left: $size;
    }
  }
}

@mixin mx($componentName) {
  @each $name, $size in $sizes {
    .mx-#{$componentName}-#{$name} {
      margin-left: $size;
      margin-right: $size;
    }
  }
}

@mixin my($componentName) {
  @each $name, $size in $sizes {
    .my-#{$componentName}-#{$name} {
      margin-top: $size;
      margin-bottom: $size;
    }
  }
}

@include mx(margin);
@include my(margin);

@include mt(margin);
@include mr(margin);
@include mb(margin);
@include ml(margin);
