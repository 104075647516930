.root {
  min-height: calc(100vh - var(--Header-height));
}

@import "~@acadeum/ui/src/styles/common";

.icon {
  width: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

