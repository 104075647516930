@import ../../../../styles/common

.CourseOverviewSection
  color: var(--color-gray-500)

  &__subsectionDescription
    margin-bottom: 1.5rem

  &__columnValue
    color: var(--color-black)
    display: flex
    flex-wrap: nowrap
    gap: .25rem
