@import '~@acadeum/ui/src/styles/common';

.SavedSearches {
  padding: .5rem 1rem;
  border: 1px solid var(--gray-line-color);
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  background-color: var(--white-color);

  &--hideOnSmallScreen {
    @include bp-down(md) {
      display: none;
    }
  }

  &--active {
    border-bottom-color: var(--white-color);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__button {
    color: var(--dark-black-color);
    min-height: auto;
  }

  &__wraper {
    margin-top: 1rem;
    display: flex;
    transform: translateY(1px);
    @include bp-down(md) {
      align-items: center;
    }
    @include bp(md) {
      gap: .25rem;
    }
  }

  &__kebab {
    margin-left: auto;
  }
}
