@import "~@acadeum/ui/src/styles/common";

.LoggedInAsBanner {
  background-color: var(--color-purple-600);
  color: var(--color-white);
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.LoggedInAsBanner__name {
  font-weight: bold;
}
