// Spacing 1 = 4px
@import "../../styles/common";

.root {
  min-width: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-wrap: var(--Grid-wrap);
  gap: var(--Grid-row-spacing) var(--Grid-column-spacing);
  flex-direction: var(--Grid-flex-direction);
}

@function previous-breakpoint($current-breakpoint) {
  $keys: map-keys($grid-breakpoints);
  $index: index($keys, $current-breakpoint);

  @if $index == null or $index <= 1 {
    @return null;
  } @else {
    $previous-key: nth($keys, $index - 1);
    @return $previous-key;
  }
}

@each $breakpoint, $size in $grid-breakpoints {
  @include bp($breakpoint) {
    .root {
      &[data-grid-columns-#{$breakpoint}] {
        --Grid-columns: var(--Grid-columns-#{$breakpoint});
      }

      &[data-grid-parent-columns-#{$breakpoint}] {
        --Grid-parent-columns: var(--Grid-parent-columns-#{$breakpoint});
      }

      &[data-grid-row-spacing-#{$breakpoint}] {
        --Grid-row-spacing: var(--Grid-row-spacing-#{$breakpoint});
      }

      &[data-grid-parent-row-spacing-#{$breakpoint}] {
        --Grid-parent-row-spacing: var(--Grid-parent-row-spacing-#{$breakpoint});
      }

      &[data-grid-column-spacing-#{$breakpoint}] {
        --Grid-column-spacing: var(--Grid-column-spacing-#{$breakpoint});
      }

      &[data-grid-parent-column-spacing-#{$breakpoint}] {
        --Grid-parent-column-spacing: var(--Grid-parent-column-spacing-#{$breakpoint});
      }

      &[data-grid-offset-#{$breakpoint}] {
        --Grid-offset: var(--Grid-offset-#{$breakpoint});
      }

      &[data-grid-flex-direction-#{$breakpoint}] {
        --Grid-flex-direction: var(--Grid-flex-direction-#{$breakpoint});
      }

      &[data-grid-wrap-#{$breakpoint}] {
        --Grid-wrap: var(--Grid-wrap-#{$breakpoint});
      }

      &[data-grid-size-#{$breakpoint}='grow'] {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      &[data-grid-size-#{$breakpoint}='auto'] {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: none;
        width: auto;
      }

      &[data-grid-size-#{$breakpoint}='number'] {
        --Grid-size: var(--Grid-size-#{$breakpoint});
        flex-grow: 0;
        flex-basis: auto;
        width: calc(100% * var(--Grid-size) / var(--Grid-parent-columns) - (var(--Grid-parent-columns) - var(--Grid-size)) * (var(--Grid-parent-column-spacing) / var(--Grid-parent-columns)));
      }

      &[data-grid-offset-#{$breakpoint}='auto'] {
        margin-left: auto;
      }

      &[data-grid-offset-#{$breakpoint}='number'] {
        margin-left: calc(100% * var(--Grid-offset) / var(--Grid-parent-columns) + (var(--Grid-parent-columns) - var(--Grid-offset)) * (var(--Grid-parent-column-spacing) / var(--Grid-parent-columns)));
      }
    }
  }
}
