@import "../../styles/common";

.root {
  margin-top: 2rem;

  @include bp(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}
