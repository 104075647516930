@import ../../styles/common

.enrollments-table__request
  td
    vertical-align: middle

.enrollments-table__details
  font-size: 14px

  td
    padding-top: 16px

    &:before
      position: absolute
      bottom: -20px
      left: 0
      width: 95%
      content: ""
      border-bottom: 1px solid var(--gray-color-border)

.enrollments-table__request-institute-logo
  width: 2.5rem
  margin-right: 1rem

.enrollments-table__secondary
  color: #7f7f7f
