@import "~@acadeum/ui/src/styles/common";

.PageSidebar {
  position: fixed;
  top: var(--Header-height);
  left: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--Header-height));
  background: var(--color-gray-700);
  color: var(--color-gray-50);
  z-index: var(--layer-2);
  padding: 1rem;

  @include bp(md) {
    padding: 1.5rem .75rem .75rem .75rem;
    width: var(--AppSidebar-width);

    &--menuItemsStyle-column {
      width: var(--AppSidebar-menuItemsStyle-column-width);
    }

    &--minimized {
      width: var(--AppSidebar-minimized-width);
      padding-left: .25rem;
      padding-right: .25rem;

      &:after {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background-color: var(--color-purple-600);
        opacity: 0;
        transition: opacity .15s ease-in-out;
        z-index: var(--layer-1);
      }

      &:hover:after {
        opacity: 1;
      }
    }

    &:hover {
      .MinimizeButton {
        opacity: 1;
      }
    }
  }

  @include bp-down(md) {
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1.5rem;
    width: 90%;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    transform: translateX(-101%);
    transition: transform .2s ease-out;

    &--expanded {
      transform: translateX(0);
    }
  }

  &__footer {
    margin-top: auto;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.Sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-right: 0.125rem;

  @include bp(md) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: .25rem;
    }
  }
}

.Divider {
  height: 1px;
  background-color: var(--color-gray-50);
  opacity: 0.2;
  width: 100%;
}

.UserInfo {
  position: relative;
  display: flex;
  align-items: center;
  gap: .5rem;
  width: calc(100% - 2rem);
  margin-bottom: 1rem;
  background-color: transparent;
  color: var(--color-gray-50);
  border: none;
  outline: none;

  @include onFocus--keyboard {
    @include focus-ring(var(--color-purple-600), 0, 0);
  }

  &__avatar {
    margin-right: .5rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
  }

  &__name {
    color: inherit;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  &__roles {
    font-size: .75rem;
    line-height: 1.125rem;
  }

  &__arrow {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
}

.InstitutionLink {
  a {
    color: var(--color-white);

    &:hover {
      color: var(--color-gray-50);
    }
  }
}

.editInstitution {
  text-decoration: underline;
  justify-content: flex-start;
  color: var(--color-white);
}