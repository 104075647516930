@import ../../styles/common

.dashboard-table-wrapper
  // padding: 16px
  border-radius: 8px

.dashboard-table
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 50px 0px
  color: var('--black-color')
  thead
    // text-align: center
    background-color: var(--green-color)
    color: white
    tr
      th
        text-align: center
        // padding-right: 30px
        padding-top: 8px
        padding-bottom: 8px
        font-size: 20px
        font-weight: normal

  tbody
    font-weight: lighter
    white-space: nowrap
    font-weight: normal

    tr
      // margin: 5px
      // background: rgba(41, 179, 74, .1)
      &:nth-child(odd)
        background: rgba(80, 0, 178, 0.05)
      td
        padding-top: 0.5rem
        padding-bottom: 0.5rem
        padding-left: 1rem
        padding-right: 1rem
        border-right: 1px solid var(--gray-color-light)

        &.number
          text-align: right
          padding-right: 1rem

        &:not(:first-child)
          text-align: right
