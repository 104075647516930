@import "../../styles/common";

.rearrangeableWrapper {
  display: flex;
  gap: 1rem;
}

.rearrangeableInner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Table {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &[data-loading="true"] {
    opacity: 0.8;
    pointer-events: none;
    user-select: none;
    touch-action: none;
  }
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-table);
  border-radius: 0.25rem;
  overflow: hidden;
  height: 100%;
}

.tableInner {
  flex: 1;
  position: relative;
  display: block;
  width: 100%;
  max-height: 30.15rem;

  &.withScroll {
    overflow: auto;
  }
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  caption-side: bottom;
}

[data-loading="true"] .table {
  opacity: 0.7;
}
