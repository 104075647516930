@mixin hideArrowsFromNumberInput() {
  // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  &[type="number"] {
    /* Firefox */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
