@import "../../styles/common/variables";
@import "../../styles/utility/mixins";

.FormFieldErrorBadge {
  display: flex;
  align-items: center;
  color: $danger;
  font-size: .75rem;
  cursor: default;

  &--inside {
    position: absolute;
    line-height: 1rem;
    top: 2.5rem;
    right: 1rem;
    /* `z-index: 1` is added so that it's rendered above the `<input/>`. */
    z-index: 1;
  }
}

.FormFieldErrorBadge-text {
  letter-spacing: $letter-spacing;
  margin-right: .5rem;
  text-transform: uppercase;
}

.FormFieldErrorBadge-icon {
  width: 1em;
  height: 1em;
}
