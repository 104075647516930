@import "../../styles/common";

.ColorInput {
}

.Toggle {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  padding: .25rem .5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--grayish-grayish-100, #dee3ec);
  background: var(--neutrals-white, #fff);

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &[aria-expanded="true"] {
    border: 1px solid var(--purple-purple-600, #5000B2);

    svg {
      transform: rotate(180deg);
    }
  }
}

.Popper {
  z-index: var(--layer-top);
}

.hidden {
  display: none;
}

.PopoverContent {
  //width: var(--radix-popover-trigger-width);
}

.ColorPicker {
  width: 100%;
}

.preview {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 5rem;
  flex-shrink: 0;
  background: #000;
}
