@import "../../styles/common";

.root {
  display: flex;
  justify-content: space-between;
  gap: .5rem;
  -webkit-tap-highlight-color: transparent;
}

.hidden {
  @include visually-hidden();
}

.requiredIndicator:before {
  content: "*";
  color: var(--color-red-400);
  margin-right: -.25rem;
}

.text {
  display: flex;
  align-items: center;
  gap: .5rem;
  @include fix-text-offset(1, 1.5);
  cursor: pointer;
  color: var(--color-black);
}

.action {
  margin-left: .5rem;
  padding: 0;
  letter-spacing: inherit;
  text-decoration: underline;
}

.error {
  margin-left: auto;
  font-size: .75rem;
  line-height: .875rem;
  letter-spacing: 1.5px;
  color: var(--color-red-400);
  text-transform: uppercase;
}
