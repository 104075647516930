@import "~@acadeum/ui/src/styles/common";

.PaymentCard {
  margin-top: 1.5rem;
  padding: 1.5rem;
  border: 1px solid var(--color-grayish-100);
  border-radius: .25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 38.5rem;
  min-height: 8.5rem;
  margin-bottom: 1rem;

  &__title {
    display: flex;
    justify-content: space-between;
    color: var(--color-black);
    font-size: 1.125rem;
    line-height: 1.5rem;
    width: 100%;
  }

  &__paymentMethodStatusContainer {
    display: flex;
    gap: .5rem;
    margin-top: .5rem;
    text-transform: capitalize;
  }
}
