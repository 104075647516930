.Student {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-weight: 400;
    margin-bottom: .5rem;
  }

  &__studentId {
    font-weight: 400;
    margin-bottom: 1rem;
  }
}
