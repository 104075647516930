@import "../../styles/common.sass";

.RequestMoreInfoModal {
  &__sectionTitle {
    margin-bottom: 0.5rem;
    font-weight: normal;
    font-size: 1rem;
    color: var(--dark-black-color);
  }

  &__cancel {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 14px;
  }

  &__cancelButton {
    text-align: center;
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
