@import "../../styles/common";

.white {
  background-color: white;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-black);
  background-color: var(--color-gray-25);
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  transition: 0.15s ease-in-out;
  min-height: 2rem;

  &:has(.input:disabled) {
    opacity: .5;
  }

  &:where(:has(.input:read-only)),
  &:where(:has(.input[readonly])) {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px #dee3ec;
  }

  &:where(:has(.input:focus)) {
    background-color: var(--color-white);
    box-shadow: var(--shadow-border-purple);
  }

  &.error {
    box-shadow: var(--shadow-border-error);
  }
}

.addon {
  display: flex;
  align-items: center;
  color: var(--color-gray-500);
  flex-shrink: 0;
}

.addonPrefix {
  padding-right: .5rem;
}

.addonSuffix {
  padding-left: .5rem;
}

.inputWrapper {
  display: inline-flex;
  width: 100%;
  min-width: 0;
}

.input {
  outline: 0;
  display: block;
  min-width: 100%;
  border: none;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  background-color: inherit;

  @include hideArrowsFromNumberInput;

  @include unify-parent("textarea") {
    resize: none;
  }

  @include unify-parent("input") {
    @include bp-down(md) {
      line-height: 2.25rem;
    }
  }
}

.LoadingEllipsis {
  width: 1rem;
  flex-shrink: 0;
  margin-left: .125rem;
}
