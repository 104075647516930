@import ../../styles/common

.output__value
  display: flex
  align-items: center
  font-weight: 400

// .output__value--single-line
//   overflow: hidden;
//   // Ellipsis doesn't show on multi-word overflow,
//   // only on single-word overflow.
//   text-overflow: ellipsis;

.output__value
  color: var(--graphite-color)
  min-height: 1.5rem
  +xs
    min-height: 1.5rem

.output__value--line-height
  line-height: 1.5rem
  +xs
    line-height: 1.5rem

.output__label
  color: var(--contrast-black-color)
  font-weight: bolder
  line-height: 1.5rem
  margin-bottom: 0.5rem

.outputs .output
  margin-bottom: 3rem

.outputs--section
  display: flex
  justify-content: space-between
  margin-bottom: 1rem

  .output
    margin-bottom: 0

    & + .output
      margin-left: 1rem

    &__label
      font-size: 1rem
      color: var(--graphite-color)

    &__value
      font-size: 1.125rem
      color: var(--contrast-black-color)
