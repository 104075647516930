.root {
}

.button {
  width: 100%;
  background-color: transparent;
  padding: .25rem .5rem;
  border-radius: .25rem;
  border: 1px solid var(--color-grayish-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  outline: none;

  &:hover,
  &:focus {
    border-color: var(--color-purple-600);
    box-shadow: none;
  }

  &[data-state="open"] {
    border-color: var(--color-purple-600);

    .icon {
      transform: rotate(-180deg);
    }
  }
}

.icon {
  width: 1rem;
  transition: all .4s;
}

.body {
  display: flex;
  flex-direction: column;
}

.radioWrap {
  margin: 0 0 .25rem;
  display: flex;
  gap: .5rem;
}

.label-field-input {
  margin-top: .5rem;
  display: block;
}
