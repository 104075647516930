.help-page
  h1
    display: block
    margin-top: 50px
    margin-bottom: 25px
    padding-bottom: 0

  h1:first-child
    margin-top: 0

  h2
    margin-top: 25px
    margin-bottom: 25px

  p
    margin-top: 25px
    margin-bottom: 25px

.help-page__heading
  font-size: 1.5rem
  font-weight: normal
