@import "../../styles/common";

.StepsProgress {

  &-step-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-margin-bottom {
    margin-bottom: 2rem;
  }

  &-step {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;

    &:not(:last-child):after {
      position: relative;
      content: '';
      height: 1px;
      width: 3.5rem;
      margin-left: .75rem;
      margin-right: .75rem;
      border-bottom: 1px solid var(--color-green-50);
    }

    &-number {
      flex: none;
      width: 1.5rem;
      height: 1.5rem;
      line-height: calc(1.5rem - 2 * 1px);
      margin-right: .5rem;
      text-align: center;
      border-radius: 50%;
      user-select: none;
      font-size: 14px;
      font-weight: normal;
      color: var(--color-gray-500);
      border: 1px solid var(--color-gray-50);
    }

    &-current {
      .StepsProgress-step-number {
        color: var(--color-white);
        background-color: var(--color-purple-600);
      }
    }

    &-complete {
      .StepsProgress-step-number {
        color: var(--color-black);
        background-color: var(--color-gray-50);
        border: none;
      }
    }
  }
}
