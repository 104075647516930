@import ../../styles/common

.TitlePage__title
  margin-top: 4rem
  margin-bottom: 1rem
  color: var(--accent-color)
  font-weight: normal

.TitlePage__subtitle
  margin-top: 1rem
  margin-bottom: 1rem
  font-size: 1.2rem
  // font-weight: lighter
  letter-spacing: 0.1em
  text-transform: uppercase
  color: var(--graphite-color)

.TitlePage__content
  margin-top: 0
  margin-bottom: 0
