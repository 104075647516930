@import "~@acadeum/ui/src/styles/common";

.root {
  --Horizontal-spacing: 2rem;
  --Header-height: 4rem;
  --Header-horizontal-spacing: var(--Horizontal-spacing);
  --main-horizontal-spacing: var(--Horizontal-spacing);

  @include bp-down(md) {
    --Horizontal-spacing: 1rem;
  }
}
