@import "~@acadeum/ui/src/styles/common";

.BankAccountInfo {
  margin-top: 2rem;

  &__accountInformation {
    position: relative;
    border-radius: .25rem;
    border: 1px solid var(--color-grayish-100);
    padding: 1.5rem;
    max-width: 38.5rem;
  }

  &__actions {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  &__warning {
    margin-bottom: 1rem;
  }
}

.BankAccountInfoRow {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__value {
    text-transform: capitalize;
  }
}
