@import ../../styles/common.sass

.InstantSearch-ClearRefinements
  .ais-ClearRefinements-button
    padding-left: var(--InstantSearch-ClearRefinements-paddingLeft)
    background-image: var(--InstantSearch-ClearRefinements-backgroundImage)
    background-repeat: no-repeat
    background-position: var(--InstantSearch-ClearRefinements-backgroundPosition)
    background-size: var(--InstantSearch-ClearRefinements-backgroundSize)
    font-size: var(--InstantSearch-ClearRefinements-fontSize)
    cursor: pointer
    // outline: none
    white-space: nowrap
    +InstantSearch--smallScreen
      background-position: var(--InstantSearch-ClearRefinements-backgroundPosition--smallScreen)

    &[disabled]
      cursor: default
      color: var(--gray-color-700)
      background-image: var(--InstantSearch-ClearRefinements-backgroundImage--disabled)
