@import ../../styles/common.sass

.JSONEditor__description
  margin-bottom: 10px

.JSONEditor__editor
  border: 1px solid var(--gray-color-light)

.JSONEditor__error
  margin-top: var(--unit)
  color: var(--error-color)
