.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.cropper {
  max-width: 500px;
  max-height: 500px;
  background-color: transparent;
}

.circleStencilBoundingBox {
  box-shadow: 0 0 0 1000px rgba(black, .5);

  :global {
    .advanced-cropper-draggable-element {
      overflow: hidden;
    }
  }
}

.circleStencilOverlay {
  box-shadow: 0 0 0 1000px rgba(black, .2);
}
 
.circleStencilHandler {
  width: 14px;
  height: 14px;
  color: var(--color-black);

  &--westNorth {
    transform: translate(25%, 25%);
    clip-path: polygon(0 0, 100% 0, 100% 25%, 25% 25%, 25% 100%, 0 100%);
  }

  &--eastNorth {
    transform: translate(-25%, 25%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 75% 100%, 75% 25%, 0 25%);
  }

  &--eastSouth {
    transform: translate(-25%, -25%);
    clip-path: polygon(75% 0, 100% 0, 100% 100%, 0 100%, 0 75%, 75% 75%);
  }

  &--westSouth {
    transform: translate(25%, -25%);
    clip-path: polygon(0 0, 25% 0, 25% 75%, 100% 75%, 100% 100%, 0 100%);
  }
}
