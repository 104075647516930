.root {
}

.iconButton {
  color: var(--color-red-400);
  font-size: 1.25rem;
}

.content {
  background-color: var(--color-gray-700);
  color: var(--color-white);
  padding: .25rem .5rem;
}
