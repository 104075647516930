.ColorPicker {
  --Hue-Alpha-height: .75rem;

  background: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
  width: 14.5rem;

  input {
    max-width: 100%;
  }
}

.saturation {

}

.body {
  padding: 1rem;
}

.hue, .alpha {
  height: var(--Hue-Alpha-height);
  position: relative;
  width: 100%;
}
