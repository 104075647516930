.UserAccountRequestsPage {
  &__status {
    max-width: 12.5rem;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    margin-bottom: 1.5rem;
  }
}
