\:root
  // Measurements
  --unit: 12px
  --column-gap: var(--unit)
  --column-gap-large-padding: calc(var(--unit) * 2)
  --page-content-padding: calc(var(--column-gap) * 2)
  --textarea-padding: 19px
  --font-size: 16px
  --outline-width: 3px
  --outline-width-thick: 5px
  --outline-width-very-thick: 7px
  --outline-box-shadow: 0 0 0 var(--outline-width) var(--outline-color)

  // Fonts
  --fontFamily: 'europa', Helvetica, Arial, sans-serif
  --fontFamily--serif: 'baskerville-urw', Helvetica, Arial, sans-serif

  // Z-indexes
  --floating-header-z-index: 10
  --sticky-table-header-z-index: 20
  --below-sidebar-z-index: 29
  --sidebar-z-index: 30
  --above-sidebar-z-index: 31
  // `--main-header-z-index` is more than `--floating-header-z-index`
  // so that floating headers don't overlay main header dropdown user menu.
  --below-main-header-z-index: 39
  --main-header-z-index: 40
  --above-main-header-z-index: 41
  --above-above-main-header-z-index: 42
  --modal-z-index: 50
  --modal-notification-z-index: 51
  --expandable-controls-fullscreen-z-index: 60
  --preloading-screen-z-index: 70

  /* Base color */
  --base-color-lightest: #33D65A
  --base-color-light: #2ABD4D
  --base-color-lighter: #28C94F
  --base-color: #29b34a
  --base-color-darker: #0BA330
  --base-color-dark: #188733
  --base-color-darkest: #0C6622
  /* Accent color */
  --accent-color-lightest: #edf3f9
  --accent-color-very-light: #89B8E8
  --accent-color-light: #7DBEFF
  --accent-color-lighter: #73b9ff
  --accent-color-darker: #2b91d9
  --accent-color-darkest: #337AB7
  /* Grays */
  --gray-color-lightest: #f8f8f8
  --gray-color-light: #f3f3f3
  --gray-color-lighter: #e7e7e7
  --gray-color-100: #FAF8FF
  /* --gray-color-lighter: #dedede; */
  --gray-color-border: #d1d1d1
  --gray-color: #cacaca
  --gray-color-300: #a7a8a8
  --gray-color-400: #9a9b9c
  --gray-color-500: #9e9e9e
  --gray-color-600: #7d7d7d
  --gray-color-650: #777C8C
  --gray-color-700: #636465
  --gray-color-800: #4B5153
  --gray-color-900: #2E2E2E
  /* Black and white */
  --black-color: #555A5E
  --white-color: #ffffff
  --smart-gray-color: #f0f0f0
  /* Error color */
  --error-color: #EB2010
  --error-color-darker: #CD3D64
  // --error-color-darker: #D30F00
  --error-color-light: #F29891
  --error-color-lightest: #FFEDED
  /* Warning color */
  --yellow-color-100: #FEF8E3
  --yellow-color-400: #ffe73e
  --yellow-color-500: #EBC248
  /* Misc colors */
  --notification-badge-color: #E13232
  --collapsible-title-background-color: rgba(215, 215, 215, 0.32)
  --collapsible-title-background-color-lighter: rgba(215, 215, 215, 0.2)
  /* Input colors */
  --input-border-color: #dadada
  --input-background-color: #f8f8f8
  --input-border-color-error: var(--error-color-light)
  /* ASP reskin. */
  --asp-select-option-color-focus: #000000
  --asp-select-option-color-selected: #000000
  --asp-select-option-background-color-focus: #FAF8FF
  --asp-select-option-background-color-selected: #FAF8FF

  --purple-color: #5000B2
  --dark-black-color: #000000
  --accent-color-darkest: #43008e
  --accent-color-darker: #480099
  --accent-color: var(--purple-color)
  --accent-color-lighter: #672bb2
  --accent-color-lighter-2: #6f3ab2
  --accent-color-light: #7b4db2
  --accent-color-very-light: #8c6eb2
  --accent-color-lightest: #FAF8FF
  --green-color: #29B34A
  --green-color-light: #31af75
  --outline-color: rgba(80, 0, 178, 0.25)

  --error-color: #CD3D64
  --graphite-color: #595959
  --contrast-black-color: #000000
  --gray-line-color: #DEE3EC
  --gray-line-color-darker: #bfc2cb
  --input-label-fontWeight: bolder

  // --error-color-darker: #D30F00
  // --error-color-light: #F29891
  // --error-color-lightest: #FFEDED

  // Miscellaneous
  --border-radius: 5px
  --section-heading-font-size-smaller: 28px
  --section-heading-font-size-smaller-line-height: 34px
  --section-heading-font-size-smaller-xs: 20px
  --section-heading-font-size-smaller-xs-line-height: 24px

  // InstantSearch.

  --InstantSearch-baseColor: var(--accent-color)

  --InstantSearch-SearchInput-height: calc(var(--unit) * 4)
  --InstantSearch-SearchInput-paddingLeft: calc(var(--unit) * 4)
  --InstantSearch-SearchInput-paddingBottom: 0px
  --InstantSearch-SearchInput-fontSize: 24px
  --InstantSearch-SearchInput-backgroundImage: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='100%' height='100%' style='opacity: 0.24'><path d='M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z' fill-rule='evenodd'></path></svg>")
  --InstantSearch-SearchInput-backgroundPosition: 16px
  --InstantSearch-SearchInput-backgroundSize: 20px
  --InstantSearch-SearchInput-marginTop--inline: var(--unit)
  --InstantSearch-SearchInput-marginBottom--inline: calc(var(--unit) * 2)

  --InstantSearch-SearchInputLabel-color: var(--graphite-color)

  --InstantSearch-ListCheckbox-width: 16px
  --InstantSearch-ListCheckbox-width--smallScreen: 30px
  // Vertical alignment is done via `margin-top`
  // rather than `flex` and `align-items: center`
  // because checkbox labels may be long and wrap multiple lines.
  --InstantSearch-ListCheckbox-marginTop: 1px
  --InstantSearch-ListCheckbox-marginTop--smallScreen: -6px
  --InstantSearch-ListCheckbox-marginRight: 0.6em
  --InstantSearch-ListCheckbox-borderRadius: 0px
  --InstantSearch-ListCheckbox-borderWidth: 1px
  --InstantSearch-ListCheckbox-borderColor: var(--gray-color-800)
  --InstantSearch-ListCheckbox-backgroundColor: var(--white-color)
  --InstantSearch-ListCheckbox-backgroundColor--checked: var(--InstantSearch-baseColor)
  --InstantSearch-ListCheckbox-backgroundColor--active: var(--InstantSearch-baseColor)
  --InstantSearch-ListCheckbox-backgroundImage--checked: url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.88l2.378 2.435L9.046 1.6' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>")
  --InstantSearch-ListCheckbox-backgroundSize--checked: 12px
  --InstantSearch-ListCheckbox-backgroundSize--checked--smallScreen: 16px
  --InstantSearch-ListCheckbox-borderWidth--checked: 0px
  --InstantSearch-ListCheckbox-borderColor--checked: var(--InstantSearch-ListCheckbox-backgroundColor--checked)

  --InstantSearch-ListLabel-color--checked: var(--accent-color)
  --InstantSearch-ListLabel-fontSize: inherit
  --InstantSearch-ListLabel-fontSize--smallScreen: 16px

  --InstantSearch-ListCount-marginTop: 0px
  --InstantSearch-ListCount-marginTop--smallScreen: var(--InstantSearch-ListCount-marginTop)
  --InstantSearch-ListCount-fontSize: var(--InstantSearch-ListLabel-fontSize)
  --InstantSearch-ListCount-fontSize--smallScreen: var(--InstantSearch-ListLabel-fontSize--smallScreen)

  --InstantSearch-ListItem-marginTop: 0.3em
  --InstantSearch-ListItem-marginTop--smallScreen: 21px

  --InstantSearch-ListSearchInput-marginBottom: var(--unit)
  --InstantSearch-ListSearchInput-marginBottom--smallScreen: 20px

  --InstantSearch-ListShowMore-marginTop: 8px
  --InstantSearch-ListShowMore-marginTop--smallScreen: 18px

  --InstantSearch-HeaderControl-textTransform: uppercase
  --InstantSearch-HeaderControl-fontSize: 14px
  --InstantSearch-HeaderControl-color: var(--accent-color)
  --InstantSearch-HeaderControl-backgroundImage--select: url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%236199d1' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>")
  --InstantSearch-HeaderControl-backgroundSize--select: 12px
  --InstantSearch-HeaderControl-backgroundPosition--select: center right 6px
  --InstantSearch-HeaderControl-paddingRight--select: calc(var(--unit) * 2)

  --InstantSearch-HederControlSeparator-width: 1px
  --InstantSearch-HederControlSeparator-marginLeft: 1em
  --InstantSearch-HederControlSeparator-marginRight: 1.3em

  --InstantSearch-HeaderControlLabel-marginRight: 0.8em
  --InstantSearch-HeaderControlLabel-color: #585858
  --InstantSearch-HeaderControlLabel-fontSize: 14px
  --InstantSearch-HeaderControlLabel-fontSize--smallScreen: 16px
  --InstantSearch-HeaderControlLabel-content--after: ''

  --InstantSearch-Line-margin: 10px
  --InstantSearch-Line-color--header: var(--gray-color-light)

  --InstantSearch-Refinement-height: calc(var(--unit) * 3.5)
  --InstantSearch-Refinement-lineHeight: var(--InstantSearch-Refinement-height)
  --InstantSearch-Refinement-marginRight: 5px
  --InstantSearch-Refinement-paddingLeft: 14px
  --InstantSearch-Refinement-borderWidth: 0px
  --InstantSearch-Refinement-borderColor: var(--InstantSearch-Refinement-backgroundColor)
  --InstantSearch-Refinement-borderRadius: 0px
  --InstantSearch-Refinement-color: var(--white-color)
  --InstantSearch-Refinement-backgroundColor: var(--accent-color)
  --InstantSearch-Refinement-fontSize: 14px

  --InstantSearch-Refinement-height--smallScreen: 30px
  --InstantSearch-Refinement-lineHeight--smallScreen: var(--InstantSearch-Refinement-height--smallScreen)

  --InstantSearch-ClearRefinement-width: 8px

  --InstantSearch-NoResults-marginTop: calc(var(--unit) * 6)
  --InstantSearch-NoResults-marginTop--smallScreen: calc(var(--unit) * 2)
  --InstantSearch-NoResults-fontSize: 24px
  --InstantSearch-NoResults-fontSize--smallScreen: 18px
  --InstantSearch-NoResults-fontWeight: inherit
  --InstantSearch-Results-marginTop: 15px
  --InstantSearch-Results-marginTop--smallScreen: 27px
  --InstantSearch-Results-marginBottom: 0px

  --InstantSearch-ResultsCount-fontSize: 18px

  --InstantSearch-FiltersHeading-marginBottom: 0px
  --InstantSearch-FiltersHeading-fontSize: 18px
  --InstantSearch-FiltersHeading-letterSpacing: 0px
  --InstantSearch-FiltersHeading-textTransform: none

  --InstantSearch-SearchInputReset-width: 15px
  --InstantSearch-SearchInputReset-right: 13px
  --InstantSearch-SearchInputReset-color: var(--gray-color-border)

  --InstantSearch-ClearRefinements-fontSize: 14px
  --InstantSearch-ClearRefinements-fontSize--smallScreen: 18px
  --InstantSearch-ClearRefinements-fontWeight--smallScreen: 300
  --InstantSearch-ClearRefinements-backgroundImage: none
  --InstantSearch-ClearRefinements-backgroundImage--disabled: var(--InstantSearch-ClearRefinements-backgroundImage)
  --InstantSearch-ClearRefinements-backgroundSize: 0px
  --InstantSearch-ClearRefinements-backgroundPosition: left center
  --InstantSearch-ClearRefinements-backgroundPosition--smallScreen: var(--InstantSearch-ClearRefinements-backgroundPosition)
  --InstantSearch-ClearRefinements-paddingLeft: 0px
  --InstantSearch-ClearRefinements-marginTop--refinements: 0px
  --InstantSearch-ClearRefinements-marginRight--refinements: 0px

  --InstantSearch-ClearRefinementOutline-width--focus: 1px

  --InstantSearch-TextInput-color: inherit
  --InstantSearch-TextInput-color--focus: inherit
  --InstantSearch-TextInput-color--placeholder: inherit
  --InstantSearch-TextInput-height: calc(var(--unit) * 2.5)
  --InstantSearch-TextInput-height--smallScreen: 38px
  --InstantSearch-TextInput-paddingBottom: 0px
  --InstantSearch-TextInput-paddingLeft: var(--unit)
  --InstantSearch-TextInput-fontSize: 14px
  --InstantSearch-TextInput-borderRadius: 5px
  --InstantSearch-TextInput-borderWidth: 1px
  --InstantSearch-TextInput-borderColor: #eaeaea
  --InstantSearch-TextInput-borderColor--focus: var(--accent-color)
  --InstantSearch-TextInput-backgroundColor: var(--white-color)
  --InstantSearch-TextInput-backgroundColor--focus: var(--InstantSearch-TextInput-backgroundColor)

  --InstantSearch-TextInputReset-marginRight: 5px
  --InstantSearch-TextInputReset-marginRight--smallScreen: 12px
  --InstantSearch-TextInputReset-marginTop: 5px
  --InstantSearch-TextInputReset-marginTop--smallScreen: 14px
  --InstantSearch-TextInputReset-padding: 5px
  --InstantSearch-TextInputReset-width: 10px
  --InstantSearch-TextInputReset-color: var(--gray-color-300)

  --InstantSearch-Toggle-marginTop: var(--unit)
  --InstantSearch-Toggle-marginTop--negative: 0px
  --InstantSearch-Toggle-marginLeft: 0.5em
  --InstantSearch-Toggle-fontSize: 14px
  --InstantSearch-Toggle-backgroundColor: white
  --InstantSearch-Toggle-backgroundColor--on: var(--InstantSearch-baseColor)

  --InstantSearch-ToggleKnob-backgroundColor: white
  --InstantSearch-ToggleKnob-backgroundColor--on: var(--InstantSearch-ToggleKnob-backgroundColor)

  --InstantSearch-Filters-padding: 0
  --InstantSearch-Filters-backgroundColor: transparent
  --InstantSearch-Filters-borderWidth: 0px
  --InstantSearch-Filters-borderColor: transparent
  --InstantSearch-Filters-borderRadius: 0px

  --InstantSearch-SectionHeadingLine-height: 1px

  --Collapsible-paddingLeft: calc(var(--unit) * 1.5)
  --Collapsible-paddingLeft--smallScreen: var(--unit)
  --CollapsibleTitle-height: calc(var(--unit) * 4)
  --CollapsibleTitle-fontSize: 20px
  --CollapsibleTitle-fontSize--smallScreen: 16px
  --CollapsibleTitle-fontWeight: normal
  --CollapsibleTitle-fontFamily: inherit
  --CollapsibleTitle-backgroundColor: var(--collapsible-title-background-color)
  --CollapsibleTitle-color: var(--accent-color)
  --CollapsibleArrow-display--default: block
  --CollapsibleArrow-display--triangle: none
  --CollapsibleArrow-width--default: 12px
  --CollapsibleArrow-width--triangle: 8px
  --CollapsibleArrow-color: var(--accent-color)
  --CollapsibleContent-marginTop: var(--unit)

  --InstantSearch-Pagination-color: var(--InstantSearch-baseColor)
  --InstantSearch-Pagination-color--selected: inherit
  --InstantSearch-Pagination-color--arrow: var(--InstantSearch-baseColor)
  --InstantSearch-Pagination-backgroundColor--arrow: white
  --InstantSearch-Pagination-textDecoration: underline
  --InstantSearch-Pagination-textDecoration--selected: none
  --InstantSearch-Pagination-width--arrow: 16px
  --InstantSearch-Pagination-fontWeight--selected: bold
  --InstantSearch-PaginationItem-marginRight: 16px
  --InstantSearch-PaginationItem-marginRight--nextPage: 0px
  --InstantSearch-PaginationItem-marginRight--smallScreen: 12px
  --InstantSearch-PaginationItem-display--firstPage: none
  --InstantSearch-PaginationItem-display--lastPage: none
  --InstantSearch-Pagination-marginTop--arrow: 0px

  --InstantSearch-ScrollTop-fontSize: 14px

  --InstantSearch-HeaderControl-backgroundImage--select: url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%235000B2' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>")
  --InstantSearch-Refinement-backgroundColor: var(--accent-color-light)

  --InstantSearch-ListCheckbox-marginTop: 2px
  --InstantSearch-Pagination-textDecoration: none
  --InstantSearch-Pagination-width--arrow: 2rem
  --InstantSearch-Pagination-color: var(--graphite-color)
  --InstantSearch-Pagination-color--arrow: var(--graphite-color)
  --InstantSearch-Toggle-fontSize: 1rem
  --InstantSearch-TextInput-fontSize: 1rem

  // Miscellaneous
  // This `--Header-height` should be equal to the value returned from
  // `components/DefaultTemplate/useHeaderHeight.js`.
  --Header-height: 4rem
  // `--Header-height--smallScreen` should be equal to `--Header-height`.
  // Otherwise, implement some dynamic `useScreenSize()` React hook
  // which will return the header height for the current window width.
  --Header-height--smallScreen: var(--Header-height)
  --SideNav-height--smallScreen: 3rem
  --main-navigation-bar-height-xs: calc(var(--unit) * 4)
  --main-navigation-bar-icon-width: 1.5rem
  --main-navigation-bar-icon-width-xs: 1.5rem
  --main-navigation-bar-width: 17rem
  --main-navigation-bar-width-s: 4rem
  --main-navigation-bar-width-m: 5rem
  --main-navigation-bar-width--largeScreenMinimized: var(--main-navigation-bar-width-s)
  --main-navigation-bar-active-item-marker-width: 0px // 4px
  --table-divider-color: #DEE3EC
  --section-heading-font-size: 32px
  --form-input-label-height: 2rem
  --textarea-padding: 11px
  --ReactTable-lineHeight: calc(var(--unit) * 3)

  /* `react-responsive-ui` */
  --rrui-list-item-background-color-selected: var(--asp-select-option-background-color-selected)
  --rrui-list-item-text-color-selected: var(--asp-select-option-color-selected)
  --rrui-list-item-text-color-focus: var(--asp-select-option-color-focus)
  --rrui-list-item-background-color-focus: var(--asp-select-option-background-color-focus)

  --rrui-switch-groove-border-color: var(--gray-color-800)
  --rrui-switch-focus-outline-shadow: 0 0 3px .15rem var(--purple-color)

  --rrui-checkbox-color-checked: var(--accent-color)
  --rrui-checkbox-color-active: var(--accent-color-light)
  --rrui-checkbox-color-focus: var(--accent-color-lighter)
  --rrui-checkbox-color-checked-focus: var(--accent-color)

  --rrui-input-height: 2rem

  --rrui-button-border-radius: 5px

  --rrui-fullscreen-input-height: 2rem

  --rrui-input-field-border-radius: 5px
  --rrui-input-field-border-color: transparent
  --rrui-input-field-background-color: #F4F5F7
  --rrui-input-field-background-color-error: white
  --rrui-input-field-border-color-error: var(--error-color)
  // --rrui-input-field-background-color-focus: #FAF8FF
  --rrui-input-field-background-color-focus: white
  --rrui-input-field-text-color: var(--contrast-black-color)

  --rrui-checkbox-stroke-color: var(--graphite-color)
  --rrui-checkbox-background-color: white

  --Button-height: 2rem
  --Button-paddingLeft: 1.5rem
  --Button-fontSize: 12px
  --Button-lineHeight: 14px
  --Button-fontWeight: bold
  --Button-letterSpacing: 1.5px
  --Button-textTransform: uppercase
  --Button-borderRadius: 5px

  /* Table Header */
  --table-header-fontSize: 14px
  --table-header-fontWeight: bolder
  --table-header-letterSpacing: 1.5px
  --table-header-textAlign: left
  --table-header-textTransform: uppercase
  --table-header-color: var(--contrast-black-color)

  --CourseSection-fontSize: 1rem
  --Tabs-borderBottomColor: #DEE3EC
  --FormField-marginTop: 2rem
  --Modal-fontSize: 18px

  // <IconButton/>
  --icon-button-padding: 4px
  --icon-button-width: 16px
