.root {
}

.wide {
  max-width: 100%;
}

.narrow {
  max-width: 56rem;
}
