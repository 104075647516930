.TextColorButton {
}

.Popper {
  z-index: var(--layer-top);
}

.hidden {
  display: none;
}
