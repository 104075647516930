.Viz__section
  margin-bottom: 1.5rem

  h1
    font-size: 1rem
    font-weight: bolder

.Viz__section--form
  margin-top: 2rem
  margin-bottom: -1rem

.viz__node
  font-family: var(--fontFamily)
  font-weight: 300
  font-size: 16px
  fill: #bbb
  cursor: pointer

.viz__node:hover
  fill: #000

.viz__link
  stroke: url(#svgGradient)
  stroke-opacity: 0.2
  fill: none
  pointer-events: none

.viz__node:hover,
.viz__node--source,
.viz__node--target
  font-weight: 700

.viz__node--source
  fill:  #29b34a

.viz__node--target
  fill: #6199d1

.viz__link--source,
.viz__link--target
  stroke-opacity: .6
  stroke-width: 2px

.viz__link--source
  stroke: #6199d1

.viz__link--target
  stroke: #29b34a

.viz__chart--hover
  .viz__node
    fill: #dddddd
  .viz__node--source
    fill: #29b34a
  .viz__node--target
    fill: #6199d1
  .viz__node--source.viz__node--target
    fill: #18d6c3
  .viz__node:hover
    fill: #000000
  .viz__link
    display: none
  .viz__link--source,
  .viz__link--target
    display: block
