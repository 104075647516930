.root {
  position: relative;
  color: var(--color-red-400);
}

.withIcon {
  padding-left: 1.25rem;
}

.icon {
  position: absolute;
  top: 2px;
  left: 0;
}
