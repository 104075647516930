@import "../../../styles/common";

.EmptyOption {
  width: 100%;
  margin: 0;
  padding: 0.25rem .75rem;
  white-space: nowrap;
  text-align: center;
  color: var(--color-black);
  outline: none;
}
