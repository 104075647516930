@import ../../styles/common.sass

.UserView-deactivated
  margin-bottom: 3rem

.UserView-deactivatedIcon
  position: relative
  top: 0.05em
  width: 0.8rem
  height: 0.8rem
  margin-right: 0.5rem
  color: var(--error-color)
