.table2
  tr
    border-bottom: 1px solid var(--gray-color-lighter)

    &:last-child
      border: none

  th, td
    padding-bottom: calc(var(--unit) * 2)
    padding-right: 3em

  th
    color: var(--table-header-color)
    font-weight: var(--table-header-fontWeight)
    letter-spacing: var(--table-header-letterSpacing)
    font-size: var(--table-header-fontSize)
    text-align: var(--table-header-textAlign)
    text-transform: var(--table-header-textTransform)

  td
    padding-top: calc(var(--unit) * 2)
    vertical-align: top

    &:last-child
      padding-right: 0

  .table2__section-header
    padding-bottom: var(--unit)
    font-weight: bold
    text-transform: uppercase

  .table2__section-header-row
    border-bottom: 0

  .table2__section-row--last
    border-bottom: 0
