.root {
}

.inner {
  display: flex;
  gap: 1rem;
}

.placeholder,
.picture {
  border-radius: .25rem;

  [data-picture-upload-shape="circle"] & {
    border-radius: 50%;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-gray-25);
  border: 1px solid var(--color-gray-100);

  [data-picture-upload-error="true"] & {
    border-color: var(--color-danger);
  }

  [data-picture-upload-size="xl"] & {
    width: 9rem;
    height: 9rem;
  }

  [data-picture-upload-size="lg"] & {
    width: 6rem;
    height: 6rem;
  }

  [data-picture-upload-size="md"] & {
    width: 3.75rem;
    height: 3.75rem;
  }
}

.picture {
  object-fit: cover;

  [data-picture-upload-size="xl"] & {
    height: 9rem;
  }

  [data-picture-upload-size="lg"] & {
    height: 6rem;
  }

  [data-picture-upload-size="md"] & {
    height: 3.75rem;
  }

  [data-picture-upload-size="xl"][data-picture-upload-shape="circle"] & {
    width: 9rem;
    height: 9rem;
  }

  [data-picture-upload-size="lg"][data-picture-upload-shape="circle"] & {
    width: 6rem;
    height: 6rem;
  }

  [data-picture-upload-size="md"][data-picture-upload-shape="circle"] & {
    width: 3.75rem;
    height: 3.75rem;
  }
}

.placeholderIcon {
  color: var(--color-gray-400);

  [data-picture-upload-size="xl"] & {
    width: 3rem;
    height: 3rem;
  }

  [data-picture-upload-size="lg"] & {
    width: 2rem;
    height: 2rem;
  }

  [data-picture-upload-size="md"] & {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  [data-picture-upload-size="md"] & {
    gap: .5rem;
  }
}

.PopoverContent {
  padding: 1.5rem;
  width: min(23rem, calc(100vw - 2rem));
}

.FileUpload {
  max-height: 13.5rem;
}

.pictureUrlInput {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.ErrorMessage {
  margin-top: auto;
}
