$top-position: .75rem;

.SkipToMainContent {
  position: absolute;
  left: .75rem;
  top: $top-position;
  z-index: var(--layer-top);
  padding: .5rem;
  transform: translateY(calc(-#{$top-position} - 150%));
  transition: transform 0.25s ease-in-out;

  &:focus {
    transform: translateY(0);
  }
}
