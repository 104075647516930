@import ../../styles/common.sass

.reports-page
  h1:not(:first-child)
    margin-top: 32px

  .accounting-table-wrapper
    // padding: 16px
    border-radius: 8px

  .accounting-table
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 50px 0px
    font-size: 16px
    // color: var(--black-color)
    thead
      // text-align: center
      background-color: var(--base-color)
      color: white
      tr
        th
          text-align: center
          // padding-right: 30px
          padding-top: 8px
          padding-bottom: 8px
          font-size: 20px
          font-weight: normal

    tbody
      font-weight: lighter
      white-space: nowrap
      tr
        &:nth-child(even)
          background: rgba(41, 179, 74, .1)
        td
          padding-top: 8px
          padding-bottom: 8px
          padding-left: 20px
          border-right: 1px solid var(--gray-color-light)
          &.number
            text-align: right
            padding-right: 20px
          &:not(:first-child)
            padding-right: 5px
            text-align: right

.reports-page--financial-breakdown
  .accounting-table
    tbody
      tr
        &:first-child, &:last-child, &:nth-child(5), &:nth-child(9), &:nth-child(11), &:nth-child(14), &:nth-child(16)
          font-weight: bolder
          background: rgba(97, 153, 209, 0.1)
          border-bottom: 1px solid var(--gray-color-light)
          border-top: 1px solid var(--gray-color-light)
        &:first-child, &:last-child, &:nth-child(9), &:nth-child(14)
          font-weight: bold

.reports-page--session-over-session
  .accounting-table
    tbody
      tr
        &:last-child
          font-weight: bolder
          background: rgba(97, 153, 209, 0.1)
          border-bottom: 1px solid var(--gray-color-light)
          border-top: 1px solid var(--gray-color)
        td
          &:nth-child(9), &:nth-child(17), &:nth-child(25), &:nth-child(33)
            border-right: 1px solid var(--gray-color)
            background: rgba(97, 153, 209, 0.1)
          &:last-child
            font-weight: bolder
            background: rgba(97, 153, 209, 0.1)

.FinancialBreakdown-subsection
  padding-left: var(--column-gap)
  padding-right: var(--column-gap)
  margin-bottom: 0.5rem

  +xs
    margin-bottom: -1rem

.FinancialBreakdown__column
  +xs-m
    margin-bottom: 0.5rem
    padding-left: 1rem
    padding-right: 1rem

  +l
    &:first-child
      padding-left: 1.25rem

    &:last-child
      padding-right: 1.25rem

.FinancialBreakdown__columnHeader
  font-weight: 300
  color: var(--gray-color-very-dark)
