@import ../../styles/common.sass

.AcadeumBackground
  min-height: calc(100vh - var(--Header-height))
  background-image: url('https://students.acadeum.com/error_pages.svg')
  background-color: white
  background-repeat: no-repeat
  background-position: center 3rem
  background-size: cover

  +xs
    min-height: 100vh
    background-image: url('https://students.acadeum.com/error_pages_m.svg')
    background-position: top
