$small: 2rem;
$medium: 3rem;
$large: 6rem;

.Avatar {
  display: block;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  flex-shrink: 0;
}

.round {
  border-radius: 50%;
}

.Image {
  object-fit: contain;
}

.Image,
.Svg {
  display: block;
  width: 100%;
  height: 100%;
}
