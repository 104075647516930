/*
 * An auto-filling CSS Grid with max columns of a minimum size
 * How to use

  @include grid-columns();
  --grid-layout-gap: 1rem;
  --grid-column-count: 3
  --grid-item--min-width: 22rem;
*/
@mixin grid-columns() {
  // Calculated values
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(#{var(--grid-item--min-width)}, var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}
