@import "../../styles/common";

.FormRow {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 1rem;
}

.Item {
  flex: 1 1 auto;
  width: calc(50% - .75rem);

  @include bp-down(md) {
    width: 100%;
  }
}
