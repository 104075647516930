@import "../../styles/common";

.root {

  ul {
    padding: 0;
    display: flex;
    border-bottom: 1px solid var(--color-grayish-100);
  }
}

.link {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: .5rem;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;

  &:not(:last-child) {
    margin-right: 1.25rem;
  }

  &[aria-disabled="true"] {
    opacity: .7;
    cursor: not-allowed;
  }

  &:not([aria-disabled="true"]) {
    &:hover {
      color: var(--color-purple-600);
      border-color: var(--color-purple-600);
    }

    @include onFocus--keyboard {
      @include focus-ring();
    }

    &.active {
      color: var(--color-purple-600);
      border-bottom: 2px solid var(--color-purple-600);
    }
  }
}
