@import "../../styles/common";

$tooltipOffset: .75rem;

.tooltip {
  padding: .25rem .5rem;
  background: var(--color-gray-700);
  color: var(--color-white);
  max-width: 26rem;
  z-index: var(--layer-top);

  &:before {
    content: "";
    position: absolute;
  }

  &[data-placement^="top"]:before,
  &[data-placement^="bottom"]:before {
    height: $tooltipOffset;
    width: 100%;
    left: 0;
  }

  &[data-placement^="top"] {
    margin-bottom: $tooltipOffset;

    &:before {
      bottom: 0;
      transform: translateY(100%);
    }

    & > .arrow {
      bottom: -.25rem;
    }
  }

  &[data-placement^="bottom"] {
    margin-top: $tooltipOffset;

    &:before {
      top: 0;
      transform: translateY(-100%);
    }

    & > .arrow {
      top: -.25rem;
    }
  }

  &[data-placement^="left"]:before,
  &[data-placement^="right"]:before {
    width: $tooltipOffset;
    height: 100%;
    top: 0;
  }

  &[data-placement^="left"] {
    margin-right: $tooltipOffset;

    &:before {
      right: 0;
      transform: translateX(100%);
    }

    & > .arrow {
      right: -.25rem;
    }
  }

  &[data-placement^="right"] {
    margin-left: $tooltipOffset;

    &:before {
      left: 0;
      transform: translateX(-100%);
    }

    & > .arrow {
      left: -.25rem;
    }
  }
}

.arrow {
  visibility: hidden;

  &,
  &::before {
    position: absolute;
    width: .5rem;
    height: .5rem;
    background: inherit;
  }

  &::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }
}
