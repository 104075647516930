@import "../../styles/common.sass";

.PublicMarketingPageBanner {
  background-color: var(--rrui-input-field-background-color);
  padding-top: 3rem;
  padding-bottom: 3rem;

  &__additionalInfo {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    @media all and (max-width: $screen-xs-max){
      grid-template-columns: 1fr;
    }
  }

  &__toggleShowAdditionalInfoButton {
    margin-top: 1rem;
  }

  &__toggleShowAdditionalInfoButtonIcon {
    width: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.125rem;
    transition: transform .4s;

    &_isCollapsed {
      transform: rotate(-180deg);
    }
  }
}
