@import ../../styles/common

.Error__title
  margin-top: 0
  margin-bottom: 2rem
  color: var(--accent-color)
  font-weight: normal

.Error__description
  margin-top: 0
  margin-bottom: 0

.Error__button
  margin-top: 2rem
