@import ../../styles/common

.form-input__label
  display: flex
  justify-content: space-between
  align-items: center
  line-height: var(--form-input-label-height)
  // font-size: 14px
  color: var(--contrast-black-color)

  .FormFieldErrorBadge
    margin-left: 1rem

  .UnstyledTooltip
    position: relative
    top: -0.15em
    margin-left: 0.25em

.form-input__label-left
  display: flex
  justify-content: space-between
  align-items: center

  .info-tooltip
    position: static
    display: inline-block
    margin-left: 0.5rem

.form-input__label-text
  // text-transform: uppercase
  font-weight: var(--input-label-fontWeight)

.form-input__label-asterisk
  color: var(--error-color)

.form-input__label-inline
  .FormFieldErrorBadge
    position: absolute
    right: 0
    top: -1.75rem
