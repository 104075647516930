@import ../../styles/common

.notifications
  //background-color: var(--gray-color-light)

.notification-bar
  display: flex
  align-items: center
  min-height: calc(var(--unit) * 4)
  margin-bottom: var(--unit)
  padding-top: var(--unit)
  padding-bottom: var(--unit)
  padding-left: var(--unit)
  padding-right: var(--unit)
  color: var(--black-color)
  // border-left: 3px solid
  background-color: var(--white-color)
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12)
  // transition: all 130ms ease-out
  border-radius: 5px

  &:hover
    text-decoration: none
    box-shadow: 0 0px 3px #8c6eb2

  &:active
    box-shadow: 0 0px 3px #672bb2

  &:first-child
    margin-top: var(--unit)

  +s-l
    padding-right: calc(var(--unit) * 2)

  +xs
    min-height: 0
    padding-top: calc(var(--unit) / 2)
    padding-bottom: calc(var(--unit) / 2)
    font-size: 13px

    &, &:first-child, &:last-child
      margin-top: calc(var(--unit) / 2)
      margin-bottom: calc(var(--unit) / 2)

.notification-bar__message
  flex: 1
  margin-right: 60px
  color: #505050

  +xs
    margin: 0

.notification-bar__icon
  padding-right: 10px

.notification-bar__action
  color: inherit

.notification-bar--notice
  color: var(--accent-color)
  border-left-color: var(--accent-color)

.notification-bar__icon-circle
  width: 0.5rem
  height: 0.5rem
  border-radius: 50%
  margin-left: 0.5rem
  margin-right: 1rem
  // background-color: var(--error-color)
  // box-shadow: 0 0 6px #ffb2b2

// Pulsing dot animation.
// https://www.florin-pop.com/blog/2019/03/css-pulse-effect/
// https://codepen.io/FlorinPop17/pen/drJJzK
.notification-bar__icon-circle
  background: rgba(255, 82, 82, 1)
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1)
  animation: pulse-red 2s infinite

@keyframes pulse-red
  0%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7)

  70%
    transform: scale(1)
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0)

  100%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0)
