.root {
  display: block;
  padding: 1.5rem;
  background-color: var(--color-white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  font-size: 1.25rem;
}

.sessions-list {
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.sessions {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: .5rem;

}

.sessions__span:not(:last-child)::after {
  content: '·';
  display: inline;
  font-weight: 400;
  margin-left: .5rem;
  margin-right: .5rem;
}

.price {
  margin-right: .25rem;
}