$primary: #5000b2;
$primary-light: #6000D6;
$secondary: #6000d6;
$dark: #000;
$light: #fff;
$warning: #ffc107;
$danger: #af0000;

//Font
$fontSize: 16px;
$font-family: 'europa', Helvetica, Arial, sans-serif;
$font-family-add: 'baskerville-urw', Helvetica, Arial, sans-serif;

$letter-spacing: 1.8px;

// Input
$Input-loadingDot-width: 20%;
$Input-loadingDot-margin: 20%;
$Input-loadingDot-backgroundColor: rgba(0, 0, 0, .5);
$Input-loadingDot-backgroundColor--transparent: rgba(0, 0, 0, .1);
