@import "../../styles/common/variables";
@import "../../styles/utility/mixins";

.ConfirmationModal {
  &__title {
    // font-family: $font-family-add;
    font-weight: normal;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__text {
    margin-bottom: 2rem;

    @include small-screen--xs() {
      margin-bottom: 1rem;
    }
  }

  &--noButton & {
    &__text {
      margin-bottom: 0;
    }
  }

  &__buttons {
    justify-content: flex-end;

    @include small-screen--xs() {
      justify-content: center;
    }

    button {
      font-weight: bolder;
    }
  }
}
