@import ../../styles/common.sass

.side-nav
  position: fixed
  left: 0
  top: var(--Header-height)
  width: var(--main-navigation-bar-width)
  height: calc(100% - var(--Header-height))
  padding-top: calc(var(--unit) * 2)
  padding-bottom: calc(var(--unit) * 2)
  background-color: #2E2E2E
  color: var(--white-color)
  user-select: none
  // overflow: auto

  /* `z-index` rule here places the sidebar menu above all other page content.
    Otherwise, there were weird UI bugs when some page elements
    were rendered above the sidebar menu. */
  z-index: 1

  +xs
    top: auto
    bottom: 0
    width: 100%
    height: var(--SideNav-height--smallScreen)
    padding-top: 0
    padding-bottom: 0
    padding-left: calc(var(--unit) * 2)
    padding-right: calc(var(--unit) * 2)
    z-index: var(--sidebar-z-index)

  +s
    width: var(--main-navigation-bar-width-s)

  +m
    width: var(--main-navigation-bar-width-m)

  .simplebar-scrollbar:before
    background-color: var(--white-color)

.side-nav--minimized
  +l
    width: var(--main-navigation-bar-width--largeScreenMinimized)

.side-nav__scrollable-list
  height: 100%

.side-nav__list
  margin: 0
  padding-left: 0

  +xs
    display: flex
    // `height: 100%` doesn't work with `simplebar`.
    // height: 100%
    height: var(--main-navigation-bar-height-xs)

.side-nav__list-item
  margin-left: auto
  margin-right: auto

  // +larger-than-xs
  //   margin-top: 0.5rem

  //   &:first-child
  //     margin-top: 0

  +xs
    flex: 1

.side-nav__item
  display: flex
  justify-content: center
  +xs
    height: 100%

.side-nav__link
  position: relative
  display: flex
  // flex-direction: column
  width: 100%
  align-items: center
  margin-top: 1px
  margin-bottom: 1px
  margin-left: 0.5rem
  margin-right: 0.5rem
  border-radius: 4px
  padding-left: 12px
  padding-right: 12px
  padding-top: calc(var(--unit) * 0.75)
  padding-bottom: calc(var(--unit) * 0.75)
  // transition: background-color 60ms, color 60ms
  color: var(--smart-gray-color)
  border-left: var(--main-navigation-bar-active-item-marker-width) solid transparent
  border-right: var(--main-navigation-bar-active-item-marker-width) solid transparent

  +onFocus--keyboard
    +focus-outline-thin(var(--smart-gray-color))

  +xs
    padding: 0
    border-left: none
    border-right: none
    justify-content: center

  &:hover
    text-decoration: none
    color: var(--smart-gray-color)

  &:hover:not(.side-nav__link--selected),
  &:focus:not(.side-nav__link--selected)
    background-color: #404040 // var(--accent-color-lighter)

    +xs
      background-color: transparent

.side-nav__link--selected
  color: var(--white-color)
  background-color: #404040 // var(--accent-color-lighter)
  border-left-color: var(--white-color)

.side-nav__icon-container
  position: relative
  display: inline-block
  width: var(--main-navigation-bar-icon-width)
  height: var(--main-navigation-bar-icon-width)
  // Don't shrink the icon in width
  // when a menu item's text is long
  // and spans multiple lines.
  flex-shrink: 0

  +xs
    width: var(--main-navigation-bar-icon-width-xs)
    height: var(--main-navigation-bar-icon-width-xs)

.side-nav__icon
  display: inline-block
  width: 100%
  height: 100%
  padding: 2px
  // Doesn't work in IE 11.
  // margin-left: auto
  // margin-right: auto

.side-nav__icon--enrollments
  padding: 3px

.side-nav__icon--student-requests
  padding: 1px
  padding-top: 2px

.side-nav__icon--consortia
  padding: 0

.side-nav__icon--search
  padding-bottom: 3px

.side-nav__icon--settings
  padding-top: 3px

.side-nav__icon--admin-tools
  padding-top: 1px

.side-nav__link-title
  margin-top: .1em
  margin-left: 12px
  // line-height: 1em

  // Handle multi-line labels correctly.
  // text-align: center
  line-height: 1.35em

  +xs
    display: none
  +s
    display: none
  +m
    display: none

.side-nav__notification
  position: absolute
  top: -9px
  right: -9px
  width: 1.25rem
  line-height: 1.25rem
  font-size: 12px
  font-weight: bold
  text-align: center
  color: var(--white-color)
  border-radius: 50%
  background-color: var(--notification-badge-color)

  +xs
    top: -2px
    right: -6px

.side-nav__link-with-tooltip
  width: 100%

.side-nav__tooltip
  position: relative
  height: 31px
  line-height: 31px
  font-size: 14px
  padding-left: 10px
  padding-right: 13px
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5)

  &:not(.side-nav__tooltip--required)
    +xs
      display: none
    +l
      display: none

  &:before
    content: ""
    position: absolute
    width: 22px
    height: 22px
    background-color: var(--black-color)
    transform: scale(0.82, 1) rotate(45deg)
    left: -11px
    top: 4.5px
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5)
    z-index: -2

  &:after
    content: ""
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: var(--black-color)
    z-index: -1

.side-nav__minimize
  display: none
  width: 100%
  height: calc(var(--unit) * 2)
  padding-right: var(--unit)
  text-align: right
  +l
    display: block

  &:hover
    background-color: #404040 // var(--accent-color-lighter)

  @include onFocus--keyboard
    background-color: #404040
    @include focus-outline-thin(#fff)

.side-nav__minimize-icon
  color: var(--white-color)
  width: 12px
  height: 12px
  transform: rotate(90deg)

.side-nav__minimize-icon--expand
  color: var(--white-color)
  width: 12px
  height: 12px
  transform: rotate(-90deg)
