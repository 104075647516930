@import "~@acadeum/ui/src/styles/global_courseshare"

// `react-credit-cards` styles.
@import ~react-credit-cards/lib/styles.scss

// Bootstrap
@import "~bootstrap/scss/bootstrap.scss"

// `components/Spreadsheet.js` styles.
@import ./x-data-spreadsheet@1.1.9/xspreadsheet.css

// Mixins and constants.
// This file is usually imported in all other files.
@import common.sass

// CSS grid styles and mixins.
@import grid.sass

// "Base" HTML element styles.
@import base.sass

// "Utility" CSS classes.
@import utility.sass

// `react-responsive-ui` custom styles.
@import react-responsive-ui.sass

@import constants.sass

// Don't edit this file.
// This file will be autogenerated in case this application is migrated to Next.js.
// https://github.com/Acadeum/Tickets/issues/1009
@import components.sass

// `react-table` custom styles.
@import ./components/ReactTable.sass

// `Pagination` styles.
@import ./components/Pagination.sass

// `<form/>` styles.
@import ./components/form.sass

// `<table/>` styles.
@import ./components/table.sass
@import ./components/table2.sass

// Various shared "utility" CSS classes.
@import ./components/details-page.sass
@import ./components/instant-search-actions.sass
@import ./components/label-button.sass
@import ./components/logo-grid.sass
