.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.badgesAndActionsWrapper {
  margin-top: 1rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.actions {
  display: flex;
  gap: 1rem;
}
