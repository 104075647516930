@import "../../styles/common";

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.FileUpload {
  position: relative;
  height: 17.5rem;
  padding: 2.5rem 2rem 2rem;
  border: 1px dashed var(--color-gray-500);
  border-radius: .25rem;
  background-color: var(--color-gray-25);

  &.dragging {
    border: 2px dashed var(--color-purple-600);
  }

  &__uploadContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 1rem;
    text-align: center;
    color: var(--color-gray-500);

    // Upload Icon
    svg {
      color: var(--color-purple-600);
      width: 2rem;
      height: 2rem;
    }
  }

  &__uploadedContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__loader,
  &__checkmarkIcon {
    color: var(--color-purple-600);
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 1rem;
  }

  &__fileNames {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.RemoveButton {
  margin-top: 1rem;
  background-color: transparent;
}

.Alert {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
