@import ../../styles/common.sass

.course-search-result
  height: calc(var(--unit) * 30)
  +xs
    height: auto !important

// .course-search-result--course
//   height: calc(var(--unit) * (30 - 2))

.course-search-result--with-price
  height: calc(var(--unit) * 33)
// &.course-search-result--course
//   height: calc(var(--unit) * (33 - 2))

.course-search-result
  font-size: 14px

.course-search-result__sessions
  display: flex
  align-items: center
  height: 40px

.course-search-result__sessions:not(.course-search-result__sessions--multiple)
  .course-search-result__sessions-count
    margin-right: 0.75em

.course-search-result__sessions-count
  display: flex
  align-items: center
  justify-content: center
  width: 1.25em
  height: 1.25em
  border-radius: 50%
  margin-right: 0.4em
  background-color: var(--gray-color-lighter)
  color: var(--black-color)

.course-search-result__available-sessions-count
  display: flex
  align-items: center

.course-search-result__cost
  color: var(--gray-color-600)
  font-weight: normal
  height: 24px
  font-size: 18px

.course-search-result__term
  color: var(--gray-color-600)
  white-space: nowrap
  line-height: normal

.course-search-result__description
  margin-top: 6px
  padding-bottom: 4px

.course-search-result__description-text
  white-space: nowrap
  overflow: visible

.course-search-result__cost
  margin-bottom: 12px

.course-search-result__info
  list-style-type: none
  padding: 0
  margin-bottom: 0
  line-height: 22px
  color: var(--gray-color-600)

.course-search-result__date
  margin-top: 8px
  font-size: 12px
  line-height: normal

.course-search-result__info-divider
  margin-top: var(--unit)
  margin-bottom: calc(var(--unit) - 1px)

.course-search-result__consortial-or-approved
  display: flex
  align-items: center
  margin-bottom: 6px

.course-search-result__consortial-or-approved__separator
  width: 1px
  height: 10px
  margin-left: 0.7em
  margin-right: 0.7em
  background-color: var(--gray-color-300)

.course-search-result__approved,
.course-search-result__consortial
  display: flex
  align-items: center
  font-weight: bold

.course-search-result__consortial-or-approved__icon
  width: 0.8em
  height: 0.8em
  margin-right: 0.35em

.course-search-result__approved
  color: var(--green-color)

.course-search-result__consortial
  color: var(--accent-color)

.course-search-result--consortial-or-approved
  .course-search-result__description
    margin-top: 0
    padding-bottom: 0

  .card__see-details
    line-height: 14px

  .course-search-result__heading
    border-top-color: var(--gray-color-lighter)

  .course-search-result__cost
    margin-bottom: 9px

  .course-search-result__info-divider
    margin-top: 10px
    margin-bottom: calc(10px - 1px)

+xs
  .course-search-result__details
    margin-top: var(--unit)

  .course-search-result__info-hr,
  .course-search-result__info
    display: none

.card__institution-logo-and-name
  margin-top: calc(var(--unit) * -0.5)

.card__institution-logo-and-name__logo-container
  width: calc(var(--unit) * 4)
  height: calc(var(--unit) * 4)
  margin-right: var(--unit)

.card__institution-logo-and-name__logo
  max-width: calc(var(--unit) * 4)
  max-height: calc(var(--unit) * 4)

.card__institution-logo-and-name__name-container
  max-height: calc(var(--unit) * 4)
  font-size: 14px
  font-weight: normal
  line-height: 17px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  padding: .25rem
  margin:  -.25rem

.card__institution-logo-and-name__name
  font-size: 1rem
  color: var(--graphite-color)

  &:hover
    color: var(--graphite-color)

.card__see-details
  display: inline-block
  margin-bottom: 12px
  font-size: 14px
  font-weight: normal
  line-height: 24px
  color: var(--accent-color)

  &:hover
    text-decoration: none

.card__see-details__arrow
  position: relative
  top: -1px

.course-search-result
  height: auto !important
  padding: 1.5rem

  .card__institution-logo-and-name
    margin-bottom: 0.5rem
    +xs
      margin-top: 0.5rem

  .card__see-details__arrow
    display: none

.course-search-result__actions
  display: flex
  justify-content: flex-end
  flex-wrap: wrap
  gap: .5rem
  margin-top: 0.5rem

  +xs
    margin-top: 1rem

.course-search-result__heading
  display: flex
  justify-content: space-between
  margin: 0 0 .5rem
  padding: 0
  border: none
  height: auto
  overflow: visible

  +xs
    flex-direction: column

.course-search-result__title
  font-size: 20px
  font-weight: bold
  flex: 1
  margin-top: 2px
  padding-right: 1rem

  +xs
    margin-bottom: 1rem

.course-search-result__name
  color: var(--dark-black-color)

  &:hover
    color: var(--dark-black-color)

  // The `:after` element is a "right caret" arrow at the end.
  //
  // It also uses some CSS trickery in order to not wrap to next line on overflow.
  // I.e. there'd be no such situation when the "right caret" arrow sits exclusively
  // on the next line.
  //
  // https://stackoverflow.com/questions/16100956/prevent-after-element-from-wrapping-to-next-line
  // http://jsfiddle.net/Bk38F/65/
  //
  &:after
    display: inline-block
    content: ''
    width: 0px
    height: 0px
    margin-right: -1rem
    margin-left: 0.5rem
    margin-bottom: 0.025em
    border-left: 0.25rem solid currentColor
    border-top: 0.25rem solid transparent
    border-bottom: 0.25rem solid transparent

.course-search-result__header-badges
  margin-left: 1rem
  padding: 0

  +xs
    margin-left: 0

.course-search-result__consortial,
.course-search-result__approved
  color: #000000

.course-search-result__sessions-table
  margin-top: -0.5rem

  +xs
    display: none

  td
    font-size: 1rem
    padding-right: 2rem
    padding-top: 0.5rem

    &:first-child
      font-weight: bold
      min-width: 8.5rem

    &:nth-child(2)
      min-width: 10rem

    &:last-child
      padding-right: 0


.course-search-result__sessions-list--course-view
  display: none
  +xs
    display: flex

.course-search-result__sessions-list
  flex-direction: column

.course-search-result__sessions-list-row
  display: flex
  font-size: 1rem
  margin-bottom: 0.5rem

  +xs
    display: flex
    flex-direction: column
    margin-bottom: 1.5rem

    &:last-child
      margin-bottom: 0.5rem

  &:first-child
    margin-top: 0

  > p
    +xs
      padding-right: 2rem

    &:first-child
      font-weight: bold

    &:first-child,
    &:nth-child(2)
      &:after
        display: inline
        content: '·'
        font-weight: normal
        margin-left: 0.5rem
        margin-right: 0.5rem
        +xs
          display: none

    &:last-child
      padding-right: 0

.course-search-result__sessions-list:not(.course-search-result__sessions-list--show-price)
  .course-search-result__sessions-list-row
    > p
      &:nth-child(2)
        &:after
          display: none

.course-search-result__no-sections-available
  font-size: 1rem
  color: #000000

.course-search-result__price__currency
  margin-right: 0.25rem
