@import ../../styles/common

.AdminStudentUser-statusIcon
  width: 1.25rem
  height: 1.25rem
  margin-left: 0.6rem
  margin-bottom: -0.1rem
  // color: var(--accent-color-very-light)
  color: var(--graphite-color)

  &--regular
    width: 1rem
    height: 1rem

.AdminStudentUser-header
  .Section__header
    margin-bottom: 2rem
    padding-bottom: 2rem
    border-bottom: 1px solid var(--gray-line-color-darker)

.AdminStudentUser-actionsContainer
  font-size: 1rem
  margin-top: 0.5rem
  margin-left: 1rem
