.FiltersWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: .25rem;
  border: 1px solid var(--color-border);
}

.filters,
.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.filters {
  > * {
    min-width: 8.5rem;
  }
}
