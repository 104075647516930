@import "~@acadeum/ui/src/styles/common";

.MinimizeButton {
  opacity: 0;
  position: absolute;
  width: 2rem;
  height: 2rem;
  // Overrides `min-width` set by `<Button/>`.
  // Overrides `min-height` set by `<Button/>`.
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 50%;
  bottom: 15%;
  right: -1rem;
  transition: opacity .15s ease-in-out;
  z-index: var(--layer-2);

  &--minimized {
    right: -.5rem;
  }

  &:focus {
    opacity: 1;
  }

  @include bp-down(md) {
    display: none;
  }
}
