@import "../../styles/common";

.Breadcrumbs {
  margin-bottom: 1.5rem;

  &__items {
    color: var(--color-black);
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item {
    display: inline-block;
    font-size: 14px;

    &:after {
      display: inline-block;
      content: '';
      margin-left: 0.75em;
      margin-right: 0.75em;
      width: 0;
      height: 0;
      border-left: 4px solid currentColor;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      color: var(--color-gray-500);
    }

    &:last-child:after {
      display: none;
    }
  }
}
