@mixin focus-ring($color: var(--color-purple-600), $position-y: -.25rem, $position-x: -.25rem, $border-width: 2px) {

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    display: block;
    top: #{$position-y};
    left: #{$position-x};
    right: #{$position-x};
    bottom: #{$position-y};
    border: #{$border-width} solid #{$color};
    border-radius: inherit;
    pointer-events: none;
  }
}

@mixin no-focus-ring {
  &::before {
    content: none;
  }
}
