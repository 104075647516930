@import ../../../../styles/common

.SectionCard
  margin-top: 1.5rem
  color: var(--color-gray-500)

  &__sectionTitle
    color: var(--color-gray-700)
    margin-bottom: 1rem
    font-size: .75rem
    font-style: normal
    font-weight: 700
    line-height: .875rem
    letter-spacing: 1.5px
    text-transform: uppercase

  &__columnValue
    color: var(--color-black)
    display: flex
    flex-wrap: nowrap
    gap: .25rem
