// Bootstrap grid

.container
  margin-left: auto
  margin-right: auto
  padding-left: var(--page-content-padding)
  padding-right: var(--page-content-padding)
  box-sizing: border-box
  // For cases when a container is inside a modal, for example
  max-width: 100%

.container--modal
  max-width: calc(var(--unit) * 66)
  padding-left: 0
  padding-right: 0

.row
  // #TODO: Remove this
  // this can only be done when the row is nested inside a container that has the opposite positive margin
  // otherwise you have this weird space if you scroll to the right
  margin-left: calc(-1 * var(--column-gap))
  margin-right: calc(-1 * var(--column-gap))

.row--large-padding
  margin-left: calc(-1 * var(--column-gap-large-padding))
  margin-right: calc(-1 * var(--column-gap-large-padding))

.row--flex
  display: flex
  flex: 0 1 auto
  flex-direction: row
  flex-wrap: wrap
  box-sizing: border-box

.row:before,
.row:after,
.container:before,
.container:after
  display: table
  content: " "

.row:after,
.container:after
  clear: both

// https://github.com/twbs/bootstrap/blob/76d8291e3846d95b40dc4509a2e1bf36b0681a28/dist/css/bootstrap-grid.css

=defineColumns($infix)
  // http://getbootstrap.com.vn/examples/equal-height-columns/
  .col#{$infix}-1,
  .col#{$infix}-2,
  .col#{$infix}-3,
  .col#{$infix}-4,
  .col#{$infix}-5,
  .col#{$infix}-6,
  .col#{$infix}-7,
  .col#{$infix}-8,
  .col#{$infix}-9,
  .col#{$infix}-10,
  .col#{$infix}-11,
  .col#{$infix}-12
    float: left

    // Don't know what `position: relative` is for
    position: relative

    // Don't know what `min-height: 1px` is for
    min-height: 1px
    box-sizing: border-box
    padding-left: var(--column-gap)
    padding-right: var(--column-gap)

  .row--large-padding
    .col#{$infix}-1,
    .col#{$infix}-2,
    .col#{$infix}-3,
    .col#{$infix}-4,
    .col#{$infix}-5,
    .col#{$infix}-6,
    .col#{$infix}-7,
    .col#{$infix}-8,
    .col#{$infix}-9,
    .col#{$infix}-10,
    .col#{$infix}-11,
    .col#{$infix}-12
      padding-left: var(--column-gap-large-padding)
      padding-right: var(--column-gap-large-padding)

  .col#{$infix}-0
    display: none

  .col#{$infix}-1
    width: 8.333333%

  .col#{$infix}-2
    width: 16.666667%

  .col#{$infix}-3
    width: 25%

  .col#{$infix}-4
    width: 33.33333%

  .col#{$infix}-5
    width: 41.666667%

  .col#{$infix}-6
    width: 50%

  .col#{$infix}-7
    width: 58.333333%

  .col#{$infix}-8
    width: 66.66666%

  .col#{$infix}-9
    width: 75%

  .col#{$infix}-10
    width: 83.333333%

  .col#{$infix}-11
    width: 91.666667%

  .col#{$infix}-12
    width: 100%

  .row--flex > .col#{$infix}-1,
  .row--flex > .col#{$infix}-2,
  .row--flex > .col#{$infix}-3,
  .row--flex > .col#{$infix}-4,
  .row--flex > .col#{$infix}-5,
  .row--flex > .col#{$infix}-6,
  .row--flex > .col#{$infix}-7,
  .row--flex > .col#{$infix}-8,
  .row--flex > .col#{$infix}-9,
  .row--flex > .col#{$infix}-10,
  .row--flex > .col#{$infix}-11,
  .row--flex > .col#{$infix}-12
    flex: 0 0 auto
    box-sizing: border-box
    padding-left: var(--column-gap)
    padding-right: var(--column-gap)
    // No `float` is used.
    float: none
    // Don't know what `position: relative` on `col-...`s is for.
    position: static
    // Don't know what `min-height: 1px` on `col-...`s is for
    min-height: 0

  .row--flex > .col#{$infix}-0
    display: none

  .row--flex > .col#{$infix}-1
    flex-basis: 8.333333%
    max-width: 8.333333%

  .row--flex > .col#{$infix}-2
    flex-basis: 16.666667%
    max-width: 16.666667%

  .row--flex > .col#{$infix}-3
    flex-basis: 25%
    max-width: 25%

  .row--flex > .col#{$infix}-4
    flex-basis: 33.33333%
    max-width: 33.33333%

  .row--flex > .col#{$infix}-5
    flex-basis: 41.666667%
    max-width: 41.666667%

  .row--flex > .col#{$infix}-6
    flex-basis: 50%
    max-width: 50%

  .row--flex > .col#{$infix}-7
    flex-basis: 58.333333%
    max-width: 58.333333%

  .row--flex > .col#{$infix}-8
    flex-basis: 66.66666%
    max-width: 66.66666%

  .row--flex > .col#{$infix}-9
    flex-basis: 75%
    max-width: 75%

  .row--flex > .col#{$infix}-10
    flex-basis: 83.333333%
    max-width: 83.333333%

  .row--flex > .col#{$infix}-11
    flex-basis: 91.666667%
    max-width: 91.666667%

  .row--flex > .col#{$infix}-12
    flex-basis: 100%
    max-width: 100%

+defineColumns("")

// Responsive grid

@media (max-width: $screen-xs-max)
  +defineColumns("-xs")

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max)
  +defineColumns("-sm")

  .container
    width: $screen-sm-min

@media (min-width: $screen-md-min) and (max-width: $screen-md-max)
  +defineColumns("-md")

  .container
    width: $screen-md-min

@media (min-width: $screen-lg-min)
  +defineColumns("-lg")

  .container
    width: $screen-lg-min

@media (min-width: $screen-xl-min)
  +defineColumns("-xl")

  .container
    width: $screen-xl-container-max-width

+xs
  .s, .m, .l, .s-m, .s-l, .m-l
    display: none !important

+s
  .xs, .m, .l, .m-l
    display: none !important

+m
  .xs, .s, .l, .xs-s
    display: none !important

+l
  .xs, .xs-s, .xs-m, .s-m
    display: none !important

.row--single-line-equal-height
  display: flex

.row--single-line-equal-height-s-l
  +s-l
    display: flex

.row--single-line-equal-height-m-l
  +m-l
    display: flex
