@import ../../styles/common.sass

.InstantSearch-DateRange
  display: flex
  flex-direction: row

  .InstantSearch-Date--to
    margin-left: 14px

.InstantSearch-Date--from,
.InstantSearch-Date--to
  +InstantSearch--smallScreen
    flex: 1
