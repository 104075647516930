@import "../../styles/common";

$ModalTransition: padding-top 0.15s ease-in-out, padding-bottom 0.15s ease-in-out;

.ModalBackdrop {

  &--loading {
    + [role="dialog"] {
      cursor: wait;
    }
  }
}

.ModalForm,
.ModalContent {

  @include bp(md) {
    max-height: min(750px, 90vh);
  }
}

.ModalDialog {
  --Modal-padding-x: 2rem;
  --Modal-padding-x--small: 1.5rem;

  width: 100vw;
  max-width: 41.25rem;

  &--small {
    max-width: 22.5rem;
    --Modal-padding-x: 1.5rem;
  }

  &--narrow {
    max-width: 32.5rem;
  }

  &--medium {
    max-width: 50rem;
  }

  &--wide {
    max-width: 61rem;
  }

  &:global {
    &.modal-fullscreen {
      width: 100vw;
    }
  }
}

.ModalHeader {
  position: relative;
  padding: 1.5rem var(--Modal-padding-x);
  transition: $ModalTransition;

  &--modalHasScroll {
    border-bottom: 1px solid var(--color-grayish-100);
  }

  &--paddingBottomSmall {
    padding-bottom: .5rem;
  }

  @include bp-down(md) {
    padding: 1rem var(--Modal-padding-x--small);
    border-bottom: 1px solid var(--color-grayish-100);
  }
}

.Loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ModalBody {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 0;
  margin-right: .125rem;
  padding-left: var(--Modal-padding-x);
  padding-right: var(--Modal-padding-x);
  overflow: auto;
  transition: $ModalTransition;

  &--footerNone {
    padding-bottom: 2rem;
  }

  &--headerNone {
    padding-top: 2rem;
  }

  &--modalHasScroll {
    padding-top: 1.25rem;
    padding-right: 1.575rem;
    padding-bottom: 2.25rem;
    margin-right: 0;
  }

  @include bp-down(md) {
    padding: 1.25rem var(--Modal-padding-x--small) 1rem;
  }
}

.ModalFooter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem var(--Modal-padding-x) 2rem;

  @include bp-down(md) {
    margin-top: auto;
  }

  &--hasScroll {
    box-shadow: var(--shadow-footer);
    padding-bottom: 1rem;
  }

  &--pressed {
    padding-bottom: 1rem;
  }

  &--paddingTopLarge {
    padding-top: 2rem;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-start {
    justify-content: start;
  }

  @include bp-down(md) {
    flex-direction: column;
    align-items: stretch;
    gap: .5rem;
    padding: .75rem var(--Modal-padding-x--small);
  }
}
