@import ../../styles/common.sass

.NotificationModal--text
  /* Add `<br/>`s where there're "\n"s in notifications text. */
  /* https://developer.mozilla.org/ru/docs/Web/CSS/white-space */
  white-space: pre-line

.NotificationModal--error
  background-color: var(--error-color-darker)
  color: var(--white-color)

.NotificationModal--warning
  background-color: var(--yellow-color-400)
  font-weight: bolder
  color: var(--gray-color-700)

.NotificationModal__overlay
  // Overrides `react-responsive-ui`'s selector style.
  &.ReactModal__Overlay--after-open
    z-index: var(--above-above-main-header-z-index)
