.InfoStudent {
  border-radius: .25rem;
  background: var(--rrui-input-field-background-color);
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__title {
    margin-bottom: 1rem;
  }
}
