@import "~@acadeum/ui/src/styles/common";

.Footer {
  background: var(--color-black);
  color: var(--color-white);

  @include bp(md) {
    font-size: 1.125rem;
  }

  a {

    &:hover {
      text-decoration: underline;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;

    @include bp-down(md) {
      gap: 1rem;
      padding: 1.5rem 1rem;
    }

    @include bp(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  &__logo {
    max-width: 9rem;
    margin-bottom: 1.5rem;
  }

  &__columnRight {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    @include bp-down(md) {
      gap: 1rem;
    }

    @include bp(md) {
      flex-direction: column;
      text-align: right;
    }
  }

  &__menu {
    display: flex;
    gap: 1rem;
  }

  &__copy {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid var(--color-gray-400);;

    @include bp-down(xl) {
      flex-direction: column-reverse;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include bp-down(md) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}
