@import "../../styles/common";

.root {
  margin-bottom: 1.5rem;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-text-primary);

  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.h1 {
  @include text-style-heading-h1;
}

.h2 {
  @include text-style-heading-h2;
}

.h3 {
  @include text-style-heading-h3;
}

.fontFamily-base {
  font-family: var(--font-family);
}

.fontFamily-add {
  font-family: var(--font-family-secondary);
}

.fontFamily-mono {
  font-family: var(--font-family-mono);
}

.marginBottom-none {
  margin-bottom: 0;
}

.marginBottom-sm {
  margin-bottom: .5rem;
}

.marginBottom-md {
  margin-bottom: .75rem;
}

.marginBottom-lg {
  margin-bottom: 1rem;
}

.marginBottom-xl {
  margin-bottom: 1.5rem;
}
