.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex: .8;
}

.loader {
  &:before {
    content: "";
    display: block;
    width: 3.5rem;
    height: 3.5rem;

    margin-left: auto;
    margin-right: auto;

    border-radius: 50%;
    border-top: .25rem solid transparent;
    border-right: .25rem solid transparent;
    border-bottom: .25rem solid transparent; // var(--color-gray-50)
    border-left: .25rem solid var(--color-gray-400);

    animation-name: circle;
    animation-iteration-count: infinite;
    animation-duration: 1500ms;
    animation-timing-function: linear;
  }
}

@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}

.text{
  position: relative;
  padding-right: 1.25em;

  &::after {
    position: absolute;
    width: 1.25em;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* width: 0; */
    clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  }
}

@keyframes ellipsis {
  to {
    /* width: 1.25em; */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
