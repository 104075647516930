@import "breakpoints";

$fontSize: 16px;
$font-family: "europa", Helvetica, Arial, sans-serif;
$font-family-add: "baskerville-urw", Helvetica, Arial, sans-serif;

@mixin text-style-heading-h1 {
  font-size: 2rem;
  line-height: 2.375rem;
  letter-spacing: 0;

  @include bp-down(md) {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}

@mixin text-style-heading-h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;

  @include bp-down(md) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

@mixin text-style-heading-h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0;

  @include bp-down(md) {
    line-height: 1.375rem;
  }
}

@mixin text-style-subtitle-1 {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  font-weight: 700;
  font-family: var(--font-family-secondary);
}

@mixin text-style-subtitle-2 {
  font-size: .75rem;
  line-height: 0.875rem;
  letter-spacing: 1.5px;
  font-weight: 700;;
  text-transform: uppercase;
}

@mixin text-style-body-lg {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
}

@mixin text-style-body-lg-bold {
  @include text-style-body-lg;
  font-weight: 700;
}

@mixin text-style-body-md {
  font-size: .875rem;
  line-height: 1.125rem;
  letter-spacing: 0;
}

@mixin text-style-body-md-bold {
  @include text-style-body-md;
  font-weight: 700;
}

@mixin text-style-body-sm {
  font-size: .75rem;
  line-height: 1.125rem;
  letter-spacing: 0;
}

@mixin text-style-body-sm-bold {
  @include text-style-body-sm;
  font-weight: 700;
}

@mixin text-style-course-card-title {
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 700;
}

@mixin text-style-sidebar {
  font-size: 1rem;
  line-height: 21px;
  letter-spacing: 0;
}

// Example use: @include(1, 1.5, 0, .5); - all params using rem
@mixin fix-text-offset($fontSize, $lineHeight, $addTopSpacing: 0, $addBottomSpacing: 0) {
  font-size: $fontSize * 1rem;
  line-height: $lineHeight * 1rem;
  margin-top: calc(-0.5 * ((#{$lineHeight} - #{$fontSize}) * 1rem) + #{$addTopSpacing}rem);
  margin-bottom: calc(-0.5 * ((#{$lineHeight} - #{$fontSize}) * 1rem) + #{$addBottomSpacing}rem);
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
