@import ../../styles/common

.ApproveSessionsSwitcher
  display: flex
  position: relative

  &__control
    cursor: pointer
    position: relative
    transform: translateY(2px)

  .checkmark
    position: relative
    display: block
    width: 2rem
    height: 1rem
    border: 1px solid #000
    border-radius: 0.5rem
    transition: all 0.4s

    &::after
      content: ""
      position: absolute
      width: .5rem
      height: .5rem
      background: var(--color-black)
      border-radius: 50%
      top: 50%
      left: .25rem
      transition: all .4s
      transform: translateY(-50%)

  input
    position: absolute
    inset: 0
    z-index: 1
    width: 100%
    height: 100%
    opacity: 0

  input:checked:not([aria-checked]) + .checkmark
    background-color: var(--color-purple-600)
    border-color: var(--color-purple-600)

    &:after
      background-color: var(--color-white)
      transform: translate(1rem, -50%)

  label
    display: flex
    gap: 0.5rem
    align-items: baseline
    color: #000