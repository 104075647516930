.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-td
  padding: 0
  padding-left: var(--unit)
  padding-right: var(--unit)
  height: var(--ReactTable-lineHeight)
  line-height: var(--ReactTable-lineHeight)

.ReactTable .rt-thead.-header
  box-shadow: none
  border-bottom: 1px solid var(--gray-color-light)

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc
  box-shadow: inset 0 -3px 0 0 var(--green-color)

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc
  box-shadow: inset 0 3px 0 0 var(--green-color)

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td
  // outline: none
  transition: none
