// This is for the new `react-responsive-ui@0.14`.

\:root
  /* Base */
  --rrui-unit : var(--unit)

  /* Everything */
  --rrui-input-height            : calc(var(--rrui-unit) * 4)
  --rrui-fullscreen-input-height : calc(var(--rrui-unit) * 4)

  /* `<TextInput multiline/>` */
  --rrui-multiline-text-height                 : calc(var(--rrui-unit) * 7)
  --rrui-multiline-text-input-vertical-padding : var(--textarea-padding)

  /* Colors */
  --rrui-white-color        : var(--white-color)
  --rrui-black-color        : var(--black-color)
  --rrui-accent-color       : var(--accent-color)
  --rrui-accent-color-light : var(--accent-color-lighter)
  --rrui-error-color        : var(--error-color)

  /* Gray is used as `<TextInput/>` `label` placeholder color. */
  --rrui-gray-color         : var(--gray-color)

  /* `<DatePicker/>` disabled day color. */
  --rrui-gray-color-light   : var(--gray-color-lighter)

  /* `<TextInput/>`, `<Select/>` and `<DatePicker/>` border color. */
  --rrui-input-field-border-color : var(--gray-color)
  --rrui-input-field-border-width : 1px

  /* Lightest Gray is used as `<Select/>` options hover background color. */
  /* Lightest Gray is used as `<Select/>`'s currently selected option background color. */
  /* Lightest Gray is used as `<DatePicker/>`'s currently mouse-overed calendar day. */
  --rrui-gray-color-lightest : var(--gray-color-light)

  /* `<Checkbox/>`. */
  --rrui-checkbox-color-focus : var(--gray-color-700)
  --rrui-checkbox-color-checked-focus : var(--green-color)
  // --rrui-checkbox-focus-ring-background-color : rgba(0,0,0,0.07)

  /* `<Switch/>`. */
  // Disable <Switch/> knob :focus colored border.
  --rrui-switch-knob-border-color-focus: transparent
  // <Switch/> :focus outline.
  --rrui-switch-focus-outline-shadow: var(--outline-box-shadow)

  /* `<Modal/>` colors. */
  --rrui-modal-close-button-background-color-focus : rgba(0,0,0,0.07)

  /* z-index */
  --rrui-z-index-overlay       : var(--modal-z-index)
  --rrui-z-index-above-overlay : var(--modal-notification-z-index)

  /* Miscellaneous */
  --rrui-input-field-border-color-focus: var(--accent-color-light)
  --rrui-input-field-border-color-error: var(--input-border-color-error)
  --rrui-input-field-background-color-error: var(--error-color-lightest)
  --rrui-input-field-transition : none
  --rrui-select-toggler-text-color-empty : var(--rrui-black-color)
