@import ../../styles/common.sass

\:root
  --data-table-row-vertical-padding-small: 1rem
  --data-table-row-vertical-padding-big: 1.5rem
  --data-table-secondary-row-padding-top: 1rem
  --data-table-secondary-row-padding-bottom: 0rem
  --data-table-font-size: 1.1rem
  --data-table-font-size--smallScreen: 1rem
  --data-table-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15)
  --data-table-border-radius: 4px

.data-table__wrapper
  &--v2
    .data-table__controls
      // Vertical padding is required for the floating header.
      // It should be defined on the inline element itself
      // rather than in the floating header CSS class
      // so that there's no "jump of content" when the header
      // enters or exits the float state.
      padding-top: 0.5rem
      padding-bottom: 0.5rem
      // The added vertical padding is compensated by the vertical margin.
      margin-bottom: 1rem

    .data-table__search
      margin-left: 1rem

    .data-table__total-results-count
      margin-top: 0

.data-table__container--scrollable
  box-shadow: var(--data-table-box-shadow)
  border-radius: var(--data-table-border-radius)
  overflow-y: scroll

  .data-table
    box-shadow: none
    border-radius: 0

.data-table
  width: calc(100% - 2 * .25rem)
  margin-left: .25rem
  margin-right: .25rem
  box-shadow: var(--data-table-box-shadow)
  border-radius: var(--data-table-border-radius)

  th
    color: var(--table-header-color)
    font-weight: var(--table-header-fontWeight)
    letter-spacing: var(--table-header-letterSpacing)
    font-size: var(--table-header-fontSize)
    text-align: var(--table-header-textAlign)
    text-transform: var(--table-header-textTransform)

  td, th
    vertical-align: top

  td, th
    padding-left: calc(var(--unit) * 2)

    +xs
      padding-left: var(--unit)

    &:first-child
      padding-left: calc(var(--unit) * 1.5)

  .rrui__checkbox
    font-size: 1rem

.data-table__header-checkbox
  width: 3rem
  border-top-left-radius: var(--data-table-border-radius)

.data-table__title
  margin-bottom: 1.5rem
  font-size: 1.5rem
  font-weight: normal

.data-table__subtitle
  margin-top: -1rem
  margin-bottom: 0.5rem
  font-size: 1.2rem

.data-table__checkbox
  line-height: 0

.data-table__header .data-table__checkbox
  margin-top: 4px

.data-table__row .data-table__checkbox
  margin-top: 5px

.data-table__checkbox
  // Has to be on top of selected row background.
  position: relative
  z-index: 0

  .rrui__input
    height: auto

// Because selected rows in a <Table/> have `--gray-color-lightest` background
// the focused checkbox style is adjusted to be visible on such background.
.data-table__row--selected
  .rrui__checkbox__input:focus + .rrui__checkbox__box + .rrui__checkbox__box-background:before
    background-color: var(--gray-color-lighter)

.data-table__heading
  font-size: 1rem

.data-table__header
  border-top-left-radius: var(--data-table-border-radius)
  border-top-right-radius: var(--data-table-border-radius)
  background-color: #F0F0F0

  th, td
    // position: relative
    padding-top: 1rem
    padding-bottom: 1rem

  td:last-child
    border-top-right-radius: var(--data-table-border-radius)

.data-table__row
  font-size: var(--data-table-font-size)
  +xs
    font-size: var(--data-table-font-size--smallScreen)
  td
    vertical-align: top

.data-table__header th:first-child,
.data-table__row td:first-child
  padding-right: var(--unit)

.data-table__header th:last-child
  border-top-right-radius: var(--data-table-border-radius)

.data-table__header th:last-child,
.data-table__row td:last-child
  padding-right: calc(var(--unit) * 1.5)

.data-table__row,
.data-table__secondary-row
  border-bottom: 1px solid var(--table-divider-color)

  &:last-child
    border: none

.data-table__secondary-row
  .rrui__select__arrow
    color: #909090

.data-table__row--has-secondary-row
  border-bottom: none

.data-table__row
  td
    padding-top: var(--data-table-row-vertical-padding-small)
    padding-bottom: var(--data-table-row-vertical-padding-small)

.data-table__row--with-secondary-row
  td
    padding-top: var(--data-table-row-vertical-padding-big)

.data-table__row--with-non-optional-secondary-row
  td
    padding-top: var(--data-table-row-vertical-padding-small)

.data-table__secondary-row
  width: 100%
  min-width: 9.5rem
  font-size: .875rem
  // background-color: var(--rrui-input-field-background-color)
  td
    padding-top: var(--data-table-secondary-row-padding-top)
    padding-bottom: var(--data-table-row-vertical-padding-big)

.data-table__row--selected,
.data-table__secondary-row--selected
  background-color: #f9f3ff

.data-table__total-results-count
  font-weight: bold
  text-transform: uppercase
  font-size: 12px
  line-height: 14px
  letter-spacing: 1.5px

.data-table__total-results-count
  margin-top: 0.5rem
  margin-bottom: 1.5rem

.data-table__section-title
  height: 45px

.data-table__section-title-text-with-line
  position: relative
  font-size: 22px
  line-height: 1

  +xs
    font-size: 18px

  &:before
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 100%
    height: 1px
    z-index: -1
    margin: auto
    content: ""
    background-color: var(--gray-color-400)

.data-table__section-title-text
  background-color: var(--white-color)
  padding-right: var(--unit)

// Make the column next to the checkbox
// align its content left.
// This gives more logical layout.
.data-table
  // Nested for CSS selector priority
  td, th
    &:nth-child(2)
      padding-left: 0
      text-align: left

  th:first-child
    border-top-left-radius: var(--data-table-border-radius)
    text-align: left

.data-table__expandable-section
  td
    padding-top: calc(var(--unit) * 2)
    padding-bottom: var(--unit)

.data-table__expandable-section--first
  td
    padding-top: calc(var(--unit) * 1)

.data-table__expandable-section--empty + .data-table__expandable-section,
.data-table__expandable-section--collapsed + .data-table__expandable-section,
.data-table--empty .data-table__expandable-section:not(.data-table__expandable-section--first)
  td
    padding-top: 0

.data-table__expandable-section-title
  display: flex
  align-items: center
  font-size: 20px
  user-select: none
  +xs
    font-size: 16px

.data-table__expandable-section-button
  +focusable-text-button-on-white(0.3em)

.data-table__expandable-section-count
  margin-left: 0.3em

  &:before
    display: inline-block
    content: '('
    margin-right: 0.12em

  &:after
    display: inline-block
    content: ')'
    margin-left: 0.12em

  &:before,
  &:after
    position: relative
    top: -0.075em
    color: var(--gray-color-400)

.data-table__expandable-section-title-tooltip-container
  position: relative
  align-self: flex-start

.data-table__expandable-section-bullet
  width: 12px
  // `<svg/>` `height` is required to be set
  // explicitly by IE11, otherwise it would be `100%`.
  height: 7px
  margin-right: 4px
  transform: rotate(-90deg)
  transform-origin: 50% 50%
  transition: transform 130ms

.data-table__expandable-section-bullet--open
  transform: rotate(0)

.data-table__expandable-section-checkbox-row
  td
    padding-bottom: var(--unit)

.data-table__details
  display: inline-block
  float: right
  padding-left: 1.5rem
  padding-right: 1.5rem
  margin-right: auto
  font-size: 12px
  font-weight: bold
  height: 2rem
  line-height: 14px
  letter-spacing: 1.5px
  text-transform: uppercase
  text-align: center
  color: var(--accent-color)
  border: none
  border: 1px solid var(--accent-color)
  border-radius: 5px
  background-color: var(--white-color)

  +focus-outline

  &:active
    background-color: var(--accent-color-lightest)

.data-table__inline-button,
.data-table__icon
  height: 1em

.data-table__icon
  // IE11 requires both `height` and `width`.
  width: 1em

.data-table__row-actions
  display: flex
  justify-content: flex-end

.data-table__row-action
  height: calc(var(--unit) * 3)
  margin-top: -4px
  margin-bottom: -4px

.table-heading
  margin-top: 1rem
  margin-bottom: 1.5rem
  font-size: 1.5rem
  font-weight: normal

.table-heading-container
  display: flex

  .table-heading
    flex: 1

.data-table__no-data
  margin-top: calc(var(--unit) * 3)
  font-size: 18px
  text-align: center

.data-table__controls
  padding-top: 6px

  +xs
    padding-top: 0
    padding-bottom: var(--unit)
    font-size: 14px

  &:not(.data-table__controls--sticky)
    .data-table__controls-container
      padding: 0

  .rrui__input,
  .rrui__list__item
    height: 2rem

.data-table__controls-vertical-spacer
  height: calc(var(--unit) * 0.5)

.data-table__controls--sticky
  display: flex
  align-items: center
  z-index: var(--sticky-table-header-z-index)
  // `!important` overrides `react-sticky` inline styles
  left: 0 !important
  top: var(--Header-height) !important
  // Overrides `<Sticky/>`s width of the non-floating filters panel
  width: auto !important
  margin: 0
  margin-left: var(--main-navigation-bar-width)
  padding-top: 0
  border-bottom: 1px solid var(--gray-color)
  background-color: var(--white-color)

  +xs
    top: 0 !important
    // top: var(--Header-height--smallScreen) !important

    margin-left: 0

  +s
    margin-left: var(--main-navigation-bar-width-s)

  +m
    margin-left: var(--main-navigation-bar-width-m)

  .filters-area__row
    display: flex

  .filters-area__right-group
    flex: 1
    text-align: right

  .filters-area__group,
  .filters-area__right-group,
  .filters-area__link
    white-space: nowrap

  .Dropdown-root
    float: none
    display: inline-block
    vertical-align: top

.data-table__controls-container
  display: flex
  align-items: center
  +xs
    flex-wrap: wrap

// .data-table__actions
//   display: flex
//   align-items: center

//   .rrui__button
//     padding-left: var(--unit)
//     padding-right: var(--unit)
//     &:last-child
//       margin-right: calc(var(--unit) * -1)

.data-table__actions
  margin-top: var(--unit)

.data-table__action
  margin-right: var(--unit)

  &:last-child
    margin-right: 0

.data-table__controls-spacer
  flex: 1

.data-table__controls-other
  display: flex
  align-items: center

.data-table__search,
.data-table__controls-other
  +xs
    margin-top: var(--unit)

.data-table__search
  margin-left: calc(var(--unit) * 2)
  +xs
    margin-left: 0

  &--last
    margin-right: 0

  &--wide
    width: 20rem

  &:last-child
    margin-right: 0

  .rrui__select__button
    padding-left: var(--unit)
    padding-right: var(--unit)

  .rrui__select
    position: relative

  .rrui__input-label
    display: none

  .rrui__options-list
    top: -1px
    margin-top: 0

    .rrui__list
      padding-top: 1px
      padding-bottom: 1px

    .rrui__list__item
      padding-left: calc(1px + var(--unit))
      padding-right: calc(1px + var(--unit))

.data-table__empty-cell + td
  padding-left: 20px
  text-align: left

.data-table__user-action
  width: 100%
  font-size: 14px
  color: var(--accent-color)
  border: none

.data-table__controls-separator
  width: 1px
  height: 22px
  background-color: var(--accent-color)
  margin-left: var(--unit)
  margin-right: var(--unit)

.data-table__other-controls-spacer
  width: 24px
  height: 0

.data-table__row-actions
  display: flex
  justify-content: flex-end

.data-table
  &__container
    margin-left: -.25rem
    margin-right: -.25rem
    padding-bottom: .5rem
    overflow-x: scroll

  &__horizontal-scrollable
    &::-webkit-scrollbar
      width: 4px
      height: 6px
      background: #fff

    /* Track */
    &::-webkit-scrollbar-track
      background: #fff

    /* Handle */
    &::-webkit-scrollbar-thumb
      border-radius: 1rem
      background: var(--gray-color-500)

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
      background: var(--gray-color-500)

.data-table__expandable-section-title-tooltip-container
  .UnstyledTooltip
    position: relative
    top: -0.2em
    margin-left: 0.25em
