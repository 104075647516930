@import '../../styles/common/variables';
@import '../../styles/utility/mixins';

.SingleSignOnEnabled {
  color: $dark;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include small-screen() {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  &-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
}
