@import "../../../../styles/common";

.toggleSvg {
  width: 1rem;
  height: 1rem;
  margin-left: .5rem;
  transition: transform 0.2s ease-in-out;

  [aria-expanded="true"] & {
    transform: rotate(180deg);
  }
}

.content {
  min-width: 14rem;
}
