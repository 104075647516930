// Without this import `postcss-calc` wouldn't substitute CSS variables
// for some reason (IE11 doesn't support non-substituted CSS variables).
@import ../../styles/common

.UnstyledTooltip [role="tooltip"]
  z-index: 1

.UnstyledTooltip__trigger
  display: flex
  padding: 0
  margin: 0
  border: 0
  background: transparent

.UnstyledTooltip__content
  width: calc(var(--unit) * 25)
  margin-left: 8px
  padding: 16px 10px
  font-size: 14px
  line-height: 22px
  transition: opacity 0.33s ease-in-out
  background-color: var(--gray-color-lightest)
  color: var(--black-color)
  border: 1px solid var(--gray-color-lighter)
  box-shadow: 0 0 4px rgba(0,0,0,0.08)
  white-space: initial

.info-tooltip__icon
  font-size: 12px
  cursor: pointer
  color: var(--gray-color-800)
