@import "../../styles/common/variables";
@import "../../styles/utility/mixins";

.FormFooter {
  margin-top: 2.5rem;
  text-align: right;

  @include small-screen {
    flex-direction: column;
  }
}

.FormFooter-actionDescription {
  margin-right: 1rem;
  margin-bottom: 0;
  color: $dark;

  @include small-screen {
    margin-bottom: 1rem;
    margin-right: 0;
    line-height: 2rem;
  }
}

.FormFooter {
  &--marginTopNone {
    margin-top: 0;
  }

  &--marginTopSmall {
    margin-top: 1.5rem;
  }
}

$FormFooter-actions-margin: 1rem;

.FormFooter-actions {
  display: flex;
  width: 100%;

  @include small-screen {
    margin-top: calc(-1 * #{$FormFooter-actions-margin} / 2);
    margin-bottom: calc(-1 * #{$FormFooter-actions-margin} / 2);
    flex-direction: column;
    // Don't expand the buttons to full width.
    align-items: center;
  }

  @include medium-or-large-screen {
    width: calc(100% + #{$FormFooter-actions-margin});
    margin-left: calc(-1 * #{$FormFooter-actions-margin} / 2);
    margin-right: calc(-1 * #{$FormFooter-actions-margin} / 2);
    justify-content: flex-end;
  }

  // Margins don't "collapse" inside a flexbox container.
  button {
    @include small-screen {
      margin-top: calc(#{$FormFooter-actions-margin} / 2);
      margin-bottom: calc(#{$FormFooter-actions-margin} / 2);
    }
    @include medium-or-large-screen {
      margin-left: calc(#{$FormFooter-actions-margin} / 2);
      margin-right: calc(#{$FormFooter-actions-margin} / 2);
    }
  }

  &--reverseOrderOnSmallScreens {
    @include small-screen {
      flex-direction: column-reverse;
    }
  }

  &--alignStart {
    justify-content: flex-start;
  }
}
