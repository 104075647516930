@import "../mixins/breakpoints";

:root {
  --font-family: "europa", Helvetica, Arial, sans-serif;
  --font-family-secondary: "baskerville-urw", Helvetica, Arial, sans-serif;
  --font-family-mono: monospace;

  --font-size: 1rem;
  --font-line: 1.5;
  --font: var(--font-size) / var(--font-line) var(--font-family);

  --size-xs: 0.25rem;
  --size-sm: 0.5rem;
  --size-md: 1rem;
  --size-lg: 1.5rem;
  --size-xl: 2rem;
  --size-2xl: 3rem;
  --size-3xl: 4rem;

  --layer-08: 8; // Sticky[position="bottom"]
  --layer-09: 9; // Sticky[position="top"]
  --layer-1: 10; // Datepicker
  --layer-2: 20;
  --layer-3: 30;
  --layer-4: 40;
  --layer-5: 50; // Header
  --layer-6: 60;
  --layer-top: 2147483647; // SoftModal

  --Toasts-layer: var(--layer-top);

  --Horizontal-spacing: 2rem;

  --AppSidebar-width: 15.5rem;
  --AppSidebar-minimized-width: 3.25rem;
  --AppSidebar-menuItemsStyle-column-width: 10.25rem;

  --Header-height: 4rem;
  --Header-horizontal-spacing: var(--Horizontal-spacing);
  --main-horizontal-spacing: var(--Horizontal-spacing);

  @include bp-down(md) {
    --Horizontal-spacing: 1rem;
  }

  --gutter-width: 0.5rem;
  --gutter-compensation: -0.5rem;

  --ContentSection-max-width: 57.5rem;
}
