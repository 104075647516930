@import "../../styles/common";

.Link {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  color: var(--color-purple-600);
  text-decoration: underline;
  outline: none;

  &:where(.Link:not(.monochrome)):hover {
    color: var(--color-purple-500);
    text-decoration: none;
  }

  @include onFocus--keyboard {
    @include focus-ring();
  }
}

.monochrome {
  color: inherit;

  &:where(a:not(.removeUnderline)):hover {
    text-decoration: underline;
  }
}

.removeUnderline {
  text-decoration: none;
}
