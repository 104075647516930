.tr {
  height: 3rem;
  background-color: var(--color-white);
}

.tr {

  &:hover {
    background-color: #faf8ff;
  }

  &.isDisabled {
    opacity: .5;
    pointer-events: none;
  }

  &.isError {
    background-color: var(--color-danger-row-highlight);
  }

  &.isSelected {
    background-color: #f9f6ffcc;
  }

  &:last-child {
    .td:last-child {
      border-bottom: 1px solid var(--color-grayish-100);
    }
  }
}

.td {
  font-size: 0.875rem;
  line-height: 1.125rem;
  border-bottom: 1px solid var(--color-grayish-100);
  color: var(--color-black);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  background-color: inherit;

  &:first-child,
  &.expand + td,
  &.select + td {
    padding-left: 1rem;
  }

  &.expand + td.select {
    padding-left: .5rem;
  }

  &:last-child {
    padding-right: 1rem;
    border-right: 1px solid var(--color-grayish-100);
  }

  &.isError {
    color: var(--color-red-400)
  }

  &.isDisabled {
    opacity: .5;
  }
}

.LargeRow {
  height: 25rem;

  td {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--color-gray-700);
    text-transform: none;
  }
}

.noDataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  svg {
    width: 2.75rem;
    color: var(--color-gray-500);
  }
}
