.UploadCourses {
  &__sticky {
    display: flex;
    justify-content: space-between;
  }

  &__removeFileButton {
    margin-left: .5rem;
  }
}
