.search {
  margin-top: .688rem;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.wrapperRadio {
  display: flex;
  flex-direction: column;
}

.radio {
  border: none;
  position: relative;
  background-color: transparent;
  text-align: left;
  display: flex;
  gap: .5rem;
  padding: .5rem .75rem;
  outline: none;
  align-items: center;

  &[data-state='checked'] {
    color: var(--color-purple-600);
    background-color: var(--color-gray-15);
  }

  [data-state='checked'] {
    margin-left: auto;
  }
}
