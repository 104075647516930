@import "../../styles/common";

.FormGroup {
  color: var(--color-black);

  &__title {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    margin-bottom: 2.5rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;

    &:after {
      color: var(--color-grayish-100);
      content: '';
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto 0 auto 1rem;
    }
  }
}
