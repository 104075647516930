@import "../../styles/common";

.CheckboxWrapper {
  --Checkbox-control-size: .875rem;
  display: flex;
  gap: .5rem;
  align-items: center;
  color: #000;

  @include bp-down(md) {
    --Checkbox-control-size: 1.25rem;
  }

  &, .input {
    cursor: pointer;
  }

  &:not(.CheckboxWrapper--disabled):hover {
    .CheckboxInput .checkmark {
      box-shadow: var(--shadow-card-hover), var(--shadow-border-purple);
    }

    .SwitchInput .checkmark {
      box-shadow: var(--shadow-card-hover);
    }
  }

  &--border {
    padding: 1.25rem 1rem;
    border-radius: .25rem;
    box-shadow: inset 0 0 0 1px #dee3ec;

    &.CheckboxWrapper--checked {
      box-shadow: inset 0 0 0 1px var(--color-purple-600);
      background: var(--color-gray-15);
    }

    &.CheckboxWrapper--error {
      box-shadow: var(--shadow-border-error);
    }
  }

  &--disabled {
    &, .input {
      cursor: not-allowed;
    }

    .checkmark {
      opacity: .5;
    }
  }

  &--readOnly {
    &, .input {
      cursor: default;
    }
  }

  &--error {
    .label {
      color: var(--color-red-400);
    }
  }
}

.required {
  color: var(--color-red-400);
}

.description {
  display: block;
  margin-top: .5rem;
  color: #595959;
}

.input {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;

  @include onFocus--keyboard {
    + .checkmark {
      @include focus-ring()
    }
  }
}

.CheckboxInput {
  position: relative;

  .checkmark {
    display: block;
    width: 1em;
    height: 1em;
    box-shadow: var(--shadow-border-base);
    border-radius: 1px;
    color: var(--color-white);
    transition: box-shadow .15s ease-in-out;
    pointer-events: none;
    font-size: var(--Checkbox-control-size);
    flex-shrink: 0;

    &--error {
      box-shadow: var(--shadow-border-error);
    }

    &:after {
      position: absolute;
      top: 45%;
      inset-inline-start: 23%;
      display: table;
      width: calc(var(--Checkbox-control-size) / 14 * 5);
      height: calc(var(--Checkbox-control-size) / 14 * 8);
      border: 1px solid #fff;
      border-top: 0;
      border-inline-start: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      content: "";
      transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
    }
  }

  .input:checked:not([aria-checked="mixed"]) + .checkmark {
    background-color: #5000b2;

    &:after {
      opacity: 1;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
    }
  }

  .checkmark--indeterminate {
    background-color: #5000b2;

    &:after {
      top: 50%;
      inset-inline-start: 50%;
      width: calc(var(--Checkbox-control-size) / 2);
      height: 1px;
      border-width: 0;
      background-color: #fff;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

.SwitchInput {
  position: relative;
  transform: translateY(2px);

  .checkmark {
    position: relative;
    display: block;
    width: 2rem;
    height: 1rem;
    border: 1px solid #000;
    border-radius: 0.5rem;
    transition: all 0.4s;

    &:after {
      content: "";
      position: absolute;
      width: .5rem;
      height: .5rem;
      background: var(--color-black);
      border-radius: 50%;
      top: 50%;
      left: .25rem;
      transition: all .4s;
      transform: translateY(-50%);
    }
  }

  .input:checked:not([aria-checked="mixed"]) + .checkmark {
    background-color: var(--color-purple-600);
    border-color: var(--color-purple-600);

    &:after {
      background-color: var(--color-white);
      transform: translate(1rem, -50%);
    }
  }

  .checkmark--indeterminate:after {
    transform: translate(.5rem, -50%);
    background-color: var(--color-grayish-300);
  }
}
