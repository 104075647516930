.details-page__group
	margin-bottom: 2.5rem

.details-page__group-name
	position: relative
	display: block
	font-weight: bolder
	color: var(--contrast-black-color)

.details-page__value
	display: block
	color: var(--graphite-color)

.details-page__value--multiline
	white-space: pre-line
