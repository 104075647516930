@import ../../styles/common.sass

.institution-search-result-row
  display: flex
  align-items: center
  height: calc(var(--unit) * 4)

.institution-search-result-row--picked
  .rrui__checkbox__label
    color: var(--accent-color)
    font-weight: normal

.institution-search-result-row__pick
  &,
  .rrui__input
    height: 100%

  .rrui__checkbox__label
    padding-right: var(--unit)

  .rrui__checkbox__box
    color: var(--gray-color-border)

  .rrui__checkbox__box--checked
    color: var(--accent-color)

  &,
  .rrui__checkbox__label
    overflow: hidden
    text-overflow: ellipsis

// This fixes checkbox `:focus` outline on the left side
// of the checkbox being hidden by `overflow: hidden`.
.institution-search-result-row__pick
  padding-left: var(--unit)
  margin-left: calc(-1 * var(--unit))

.institution-search-result-row__pick,
.institution-search-result-row__name
  font-size: 18px

.institution-search-result-row__name
  flex: 1

.institution-search-result-row__details
  width: auto
  padding-left: 1.1em
  padding-right: 1.1em
  height: 34px
  line-height: 34px
  background-color: var(--accent-color)
  color: var(--white-color)

  &:hover
    text-decoration: none
    color: var(--white-color)

  &:active
    background-color: var(--accent-color-lighter)

.institution-search-result-row__details
  height: var(--Button-height)
  text-transform: var(--Button-textTransform)
  font-size: var(--Button-fontSize)
  line-height: var(--Button-lineHeight)
  font-weight: var(--Button-fontWeight)
  letter-spacing: var(--Button-letterSpacing)

.institution-search-result-row__details
  display: inline-flex
  align-items: center
  border-radius: 5px
  +focus-outline
