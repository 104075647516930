@import ../../styles/common

.raw-data-table
  .ReactTable
    margin-top: var(--unit)
    // .rt-td:first-child
    //   position: relative
    //   input,
    //   &:after
    //     position: absolute
    //     top: calc((100% - 25px) / 2)
    //     left: calc((100% - 25px) / 2)
    //     width: 25px
    //     height: 25px
    //   input
    //     margin: 0
    //     opacity: 0
    //     cursor: pointer
    //     z-index: 1
    //   &:after
    //     display: block
    //     content: ''
    //     border: 1px solid #afafaf
    //   input:checked
    //     opacity: 1
    //   input:focus
    //     opacity: 1

.raw-data-table__actions
  display: flex
  margin-top: var(--unit)

.raw-data-table__action
  margin-right: var(--unit)

  &:last-child
    margin-right: 0

.raw-data-table__title
  margin-bottom: var(--unit)

// .ReactTable .-pageSizeOptions select,
// .ReactTable .-pagination button,
// .ReactTable .-pageJump input,
.ReactTable input,
.ReactTable button,
.ReactTable select
  +focus-outline

.RawDataTableCheckbox
  line-height: var(--ReactTable-lineHeight)
  .rrui__input
    height: var(--ReactTable-lineHeight)
  .rrui__checkbox__input
    left: -80%
    top: -80%
    width: 260%
    height: 260%
