.List {
  padding-left: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  list-style: disc outside none;

  + .List {
    margin-top: 1rem;
  }
}

.numbered {
  list-style-type: none;
  padding-left: 0;
  counter-reset: ListItem 0;

  .Item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 2rem;

    &:before {
      counter-increment: ListItem;
      content: counter(ListItem);

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 1px solid var(--color-grayish-100);

      font-size: 0.875rem;
      color: var(--color-gray-500);
      text-align: center;
    }
  }
}

.Item {
  .List:first-child {
    margin-top: .5rem;
  }
}

.unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
