.header {
  display: flex;
}

.background-block {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #f3f3f3;
}