@import "~@acadeum/ui/src/styles/common";

.ContactSection {
  color: #0a0044;
  display: grid;
  padding: 2rem;
  grid-gap: 1.5rem;
  border: 1px solid var(--color-grayish-100);
  border-radius: .25rem;
  margin-top: 1.5rem;
  max-width: 55rem;

  &:last-child {
    margin-bottom: 1.5rem;
  }

  &__notification {
    margin-top: 1.5rem;
  }

  &__card {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: 9.25rem auto;
  }

  &__title {
    color: var(--color-black);
  }

  &__description {
    color: var(--color-gray-500);
  }

  &__addButton {
    margin-top: 1.5rem;
  }
}
