@import "../../styles/common/variables";
// @import "../../styles/utility/mixins";

:root {
  --FormField-dots-width: 1.5em;
  --FormField-dots-marginRight: 0.5rem;
  --FormField-dots-color: #7f7f7f;
}

.FormField {
  margin-bottom: 2rem;

  &--loading {
    .form-input__input:after,
    .rrui__input:before,
    .rrui__input:after {
      display: block;
      content: '';
      position: absolute;
      top: calc((100% - 0.25em) / 2);
      right: 0;
      width: 0.25em;
      height: 0.25em;
      margin-right: var(--FormField-dots-marginRight);
      border-radius: 50%;
      background-color: var(--FormField-dots-color);
      animation: FormField-dotFlashing 0.7s infinite linear alternate;
    }

    .form-input__input:after {
      right: 0;
      animation-delay: 0.7s;
    }

    .rrui__input:before {
      right: calc((var(--FormField-dots-width) - 0.25em) / 2);
      animation-delay: .35s;
    }

    .rrui__input:after {
      right: calc(var(--FormField-dots-width) - 0.25em);
    }
  }
}

// This styling mimics `Students-site`.
.Form--alternativeFieldLabelStyle {
  .FormField {
    .form-input__label-asterisk {
      display: none;
    }

    .form-input__label-text {
      font-weight: normal;
    }

    .rrui__input-field,
    .rrui__input-field::placeholder {
      color: $primary;
    }

    .rrui__input-field:focus {
      color: $dark;
    }

    .rrui__input-field--invalid,
    .rrui__input-field--invalid::placeholder {
      color: $danger;
    }

    .rrui__input-field:not(.rrui__input-field--empty):not(.rrui__input-field--invalid),
    .rrui__select__button:not(.rrui__select__button--empty) {
      border-color: $primary;
    }

    .Input-checkbox {
      color: $dark;

      &:hover,
      &:active {
        color: $primary-light;
      }

      &:hover {
        .rrui__checkbox__box {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
        }
      }

      &.rrui__checkbox--checked {
        .rrui__checkbox__label {
          color: $primary-light;
        }
      }
    }

    &--optional {
      .form-input__label-text {
        display: flex;
        justify-content: space-between;

        &:after {
          display: flex;
          align-items: center;
          content: '(Optional)';
          color: var(--graphite-color);
          line-height: 1.5rem;
          font-size: .75rem;
        }
      }
    }
  }
}

@keyframes FormField-dotFlashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.2;
  }
}
