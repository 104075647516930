@import ../../styles/common

.CreateAdvisorRecommendation
  position: relative

  &-sections
    .form__subheading
      min-height: auto
      margin-bottom: 1rem

  &-sectionsHint
    margin-top: 0
    margin-bottom: 3rem

  &__institutionLink
    margin-bottom: 1rem

  &-note
    padding: 1rem
    background-color: var(--rrui-input-field-background-color)
    border-radius: 4px

  &-addSubstituteNote
    margin-bottom: 1rem
