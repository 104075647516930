.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.border {
  padding: 1rem;
  border: 1px solid #dee3ec;
  border-radius: .25rem;
}

.filters {
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  align-items: center;

  & > * {
    min-width: 6.5rem;
  }
}
