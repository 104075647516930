.EditableInput {
  //width: 100%;

  input {
    width: 100%;
    padding: .25rem;
    border-radius: .5rem;
    //border: none;
    //line-height: 1.5rem;
    //padding: 0 0 0 .5rem;
    //outline: none;
  }
}
