@import "../../styles/common/variables";
// @import "../../styles/utility/mixins";

.TextButton {
  color: $primary;

  &:focus {
    text-decoration: underline;
    color: $primary-light;
  }

  &:hover {
    color: $primary-light;
  }

  &__caret {
    margin-left: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
  }
}
