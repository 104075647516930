.ChoiceList {

  &__header {
    display: flex;
    gap: 1rem 0;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__SearchBar {
    flex: 1 1;
  }

  &__toggleWrap {
    width: 12.25rem;
    max-width: 100%;
  }

  &__commonToggleContainer {
    margin-bottom: 1rem;
    padding: .25rem 0 .25rem .75rem;
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
    line-height: .875rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &__id {
    color: var(--color-gray-500);
    margin-left: 0.5rem;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }
}

.Listbox {
  height: 12.5rem;
  max-height: 12.5rem;

  &--medium {
    height: 17.5rem;
    max-height: 17.5rem;
  }

  &--large {
    height: 28rem;
    max-height: 28rem;
  }
}

.Option {
  min-height: 2.5rem;
  border-bottom: 1px solid #dee3ec;
}

.EmptyOption {
  height: 100%;
  padding: 0;

  // It's a css hack to set a top padding that depends on the current height. (padding-top depends on parent's width)
  // https://stackoverflow.com/questions/4982480/how-to-set-the-margin-or-padding-as-percentage-of-height-of-parent-container/21504320#21504320
  &:before {
    content: "";
    display: block;
    height: 20%;
  }

  svg {
    color: #595959;
  }
}

@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}

.SvgLoading {
  animation-name: circle;
  animation-iteration-count: infinite;
  animation-duration: 1200ms;
  animation-timing-function: linear;
}
