// @TODO: Remove this file and migrate to CSS variables

// Disable smooth scroll effect on css.
// If enabled this conflicts with `useDisableScroll`
$enable-smooth-scroll: false;

/********** Paragraph Start **********/
$paragraph-margin-bottom: 0;
/********** Paragraph END **********/

/********** Modal START **********/
$modal-backdrop-bg: var(--color-black);
$modal-backdrop-opacity: .6;
$modal-inner-padding: 0;
$modal-header-padding-y: 0;
$modal-header-padding-x: 0;
$modal-header-padding: 0;
$modal-header-border-width: 0;
$modal-header-border-color: transparent;
$modal-content-border-radius: 0px;
$modal-content-inner-border-radius: 0px;
$modal-footer-border-width: 0;
$modal-footer-border-color: transparent;
$modal-footer-margin-between: 0;
/********** Modal END **********/

/********** Button START **********/
$btn-font-weight: 700;
$btn-line-height: 2rem;
$btn-white-space: nowrap;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-border-width: 0;
$btn-focus-width: 0;
$btn-box-shadow: none;
/********** Button END **********/

/********** ButtonClose START **********/
$btn-close-width: auto;
$btn-close-height: 1.5rem;
$btn-close-padding-y: 0px;
$btn-close-padding-x: 0px;
// $btn-close-bg
$btn-close-opacity: 1;
$btn-close-hover-opacity: $btn-close-opacity;
$btn-close-focus-shadow: none;
$btn-close-focus-opacity: $btn-close-opacity;
$btn-close-disabled-opacity: .5;
$btn-border-radius: .25rem;
/********** ButtonClose END **********/

/********** Button START **********/
$link-color: initial;
$link-decoration: initial;
$link-hover-color: initial;
/********** Button END **********/

$input-padding-y: .25rem;
$input-padding-x: .75rem;
$input-border-width: .25rem;
$input-font-size: 1rem;
$input-line-height: 1.5;
$input-color: var(--color-black);
$input-border-width: 0;
$input-bg: var(--color-gray-25);
$input-transition: border-color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;

$input-placeholder-color: var(--color-grayish-300);
$input-disabled-bg: $input-bg;

/********** LoadingEllipsis START **********/
$LoadingEllipsis-dot-width: 0.25rem;
$LoadingEllipsis-dot-margin: 0.25rem;
$LoadingEllipsis-width: $LoadingEllipsis-dot-width * 3 + $LoadingEllipsis-dot-margin * 2;
$LoadingEllipsis-color: var(--color-black);
/********** LoadingEllipsis END **********/
