@import "../DatePicker/DatePicker.module";

.DateRangePicker {
  position: relative;
  min-width: 18rem;

  label {
    display: none;
  }
}
