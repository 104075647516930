@import "../../styles/common/variables";
@import "../../styles/utility/mixins";

$Modal-close-fontSize: 11px;

.CloseButton {
  display: flex;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  border-radius: var(--Button-borderRadius);
  opacity: 1;
  text-transform: uppercase;
  font-family: $font-family;
  font-size: $Modal-close-fontSize;
  letter-spacing: $letter-spacing;
  color: $primary;

  // @include onFocus--keyboard {
  //   border-radius: .3rem;
  //   text-decoration: none;
  //   box-shadow: 0 0 0 $Input-borderWidth--focus $primary;
  // }

  &:hover {
    color: $secondary;
  }

  &:focus {
    // outline: none;
    background-color: var(--accent-color-lightest);
  }
}

.CloseButton-icon {
  margin-left: 5px;
  margin-top: -1px;
}
