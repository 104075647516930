.Header {
  padding: 1rem;
  color: var(--color-text-secondary);
}

.navButton {
  width: 1.5rem;
  height: 1.5rem;

  &:disabled {
    opacity: .5;
  }
}
