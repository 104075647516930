@import "~@acadeum/ui/src/styles/common";

.MenuSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  border: 1px solid var(--color-grayish-100);
  border-radius: .25rem;

  @include bp(md) {
    min-height: 20rem;
  }

  &__header {
    display: flex;
    gap: .75rem;
    padding: 1.5rem;
    border-bottom: 1px solid var(--color-grayish-100);
    margin-bottom: 1.5rem;
    color: var(--color-black);

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--color-gray-400);
    }
  }

  &__list {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    min-height: 2.5rem;
    width: 100%;
    padding: .5rem 1.5rem;
    font-size: 1rem;
    border-radius: 0;
    color: var(--color-black);

    svg {
      width: 1.25rem;
      height: 1.25rem;
      color: var(--color-gray-400);
    }

    &:hover {
      color: var(--color-white);
      background-color: var(--color-purple-600);

      svg {
        color: var(--color-white);
      }
    }
  }
}
