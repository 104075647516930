$grid-breakpoints: (
  xs: 0,
  sm: 414px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1440px,
  3xl: 1600px,
  4xl: 1800px,
  5xl: 2600px
);

@function bp-val($name) {
  $val: map-get($grid-breakpoints, $name);

  @if $val == null {
    @error 'Breakpoint #{$name} not found';
  }

  @return $val;
}

@mixin bp($name) {
  $val: bp-val($name);

  @media (min-width: $val) {
    @content;
  }
}

@mixin bp-down($name) {
  $val: bp-val($name);

  @media (max-width: $val - .02) {
    @content;
  }
}

@mixin bp-between($name1, $name2) {
  $min: bp-val($name1);
  $max: bp-val($name2);

  @media (min-width: $min) and (max-width: $max - .02) {
    @content;
  }
}
