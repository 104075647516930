@import ../../styles/common

.Subsection--marginBottomLarge
  margin-bottom: 3rem

.Subsection--marginTopLarge
  margin-top: 3rem

.Subsection--marginTopMedium
  margin-top: 2rem

.Subsection-header
  display: flex
  margin-bottom: .5rem
  align-items: center
  color: #000000
  font-size: 20px
  font-weight: 400
  height: 2rem
  overflow: hidden

  &--marginBottomLarge
    margin-bottom: 2rem

.Subsection-heading
  margin-bottom: 0
  font-size: 1rem
  font-weight: normal
  text-transform: uppercase
  letter-spacing: 1.5px

  &--new
    font-weight: 700
    font-size: 12px
    line-height: 14px

.Subsection-copy
  max-width: 38.5rem
  color: #2e2e2e
  margin-bottom: 1.5rem

.Subsection-headingLine
  height: 1px
  flex: 1
  margin-left: 1rem
  background-color: var(--gray-line-color-darker)
