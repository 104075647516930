@import "~@acadeum/ui/src/styles/common";

.Consortium {

  &__header {
    display: grid;
    grid-template-columns: [start] auto [endText] 1fr [startButton] auto [end];
    align-items: start;
    gap: .5rem;

    &-title,
    &-link {
      grid-column: start / end;
    }

    &-buttons-wrapper {
      grid-column: startButton / end;
    }

    &-button:not(:last-child) {
      margin-right: .5rem;
    }
  }

  &__tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-column: start / end;
    gap: .5rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  &__link-svg {
    margin-left: .25rem;
    width: 1rem;
  }


  &__subsection {
    display: grid;
    grid-template-columns: minmax(75px, 100px) 1fr;
    gap: 76px;

    &-title {
      font-weight: 400;
      color: var(--color-black);
    }
  }
}

