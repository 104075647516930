@import ../../styles/common.sass

.InstantSearch-Banner
  padding-top: 4rem
  padding-bottom: 4rem

  +InstantSearch--smallScreen
    padding-bottom: 1rem

.InstantSearch-Banner
  padding-bottom: calc(var(--unit) * 7)
  +xs
    padding-bottom: calc(var(--unit) * 1)

.InstantSearch-BannerHeading
  margin-top: 0
  margin-bottom: 1rem
  text-align: center
  font-size: 2rem
  font-weight: 300

  +InstantSearch--smallScreen
    font-size: 1.25rem
    font-weight: 400

.InstantSearch--hideResults
  .InstantSearch-Banner
    +InstantSearch--smallScreen
      padding-bottom: 3rem
