@import "~@acadeum/ui/src/styles/common";

.DropdownContainer {
  &__button {
    border: 1px solid var(--color-grayish-100);
    text-transform: none;
    padding: .25rem .5rem;
    color: var(--color-black);
    width: 100%;
    justify-content: space-between;

    &:focus,
    &:hover,
    &.active {
      color: var(--color-black);
      border-color: var(--color-purple-600);

      &:before {
        content: none;
      }
    }
  }

  &__menu {
    padding: 1rem;
    background: #fff;
    /* Main Colors/Stroke */

    border: 1px solid #dee3ec;
  }
}
