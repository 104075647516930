@import "../../styles/common.sass";

.PublicMarketingPageBannerSection {
  padding: 2.25rem;
  background-color: var(--white-color);
  border: 1px solid var(--gray-line-color);
  box-shadow: 0 1px 4px rgba(0, 0, 0, .24);
  border-radius: .25rem;

  &__icon {
    width: 1.5rem;
  }

  &__title {
    margin-top: .75rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.125rem;
  }

  &__points {
    list-style-type: disc;
    padding-left: 1.25rem;

    &__item:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__footer {
    margin-top: 0;
    margin-bottom: 0;
  }
}
