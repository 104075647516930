@import "../../styles/common";

.CloseButton {
  position: relative;
  border-radius: .25rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: var(--color-purple-600);

  @include onFocus--keyboard {
    @include focus-ring(var(--color-purple-600), 0, 0)
  }
}

.content {
  margin-left: .25rem;
}

.icon {
  color: var(--color-gray-400);
}

.sm .icon {
  height: 1rem;
  width: 1rem;
}

.md .icon {
  height: 1.5rem;
  width: 1.5rem;
}
