@import "../../styles/common";

.FormFieldArray {
  display: flex;
  flex-direction: column;

  &.withBorder {
    padding: 1.5rem 2rem 2rem;
    border: 1px solid var(--color-grayish-100);
    border-radius: .25rem;
  }
}

.fieldWrapper {
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-grayish-100);
    margin-bottom: 1.5rem;
  }
}

.FormRow {
  column-gap: 1rem;
  margin-right: 1.5rem;
}

.closeButton {
  flex: 0;
  align-self: flex-end;
}

.addButton {
  align-self: flex-start;
}
