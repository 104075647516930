.approved-courses-page__no-pricing-defined
  margin-top: calc(var(--unit) * 2)
  margin-bottom: calc(var(--unit) * 2)
  code
    color: #cc0000

.approved-courses-page__table-container
  margin-top: calc(var(--unit) * 2)

  .rrui__activity-indicator
    color: var(--gray-color-lighter)
