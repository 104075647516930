@import ../../styles/common.sass

.InstantSearch-Date-label
  margin-bottom: 2px

.InstantSearch-Date-datePickerContainer
  position: relative

.InstantSearch-Date-datePickerIcon
  position: absolute
  top: 9px
  right: 6px
  width: 12px
  height: 12px
  pointer-events: none
  +InstantSearch--smallScreen
    top: 11px
    right: 10px

.InstantSearch-Date
  .rrui__input-field
    padding-left: 8px
    padding-right: 8px
