@import ../../styles/common.sass

.InstantSearch
  line-height: normal

  .rrui__date-picker
    +InstantSearch--smallScreen
      height: 33px

    .rrui__input
      height: calc(var(--unit) * 2.5)
      font-size: 14px

.InstantSearchTextInput
  width: 100%
  height: 30px
  font-size: 14px
  box-sizing: border-box
  border: 1px solid var(--gray-color-light)
  background-color: var(--white-color)
  +InstantSearch--smallScreen
    height: 33px

.InstantSearchTextInput--paddingLeft
  padding-left: 18px

.InstantSearch-ResultsCount--header
  flex: 1
  margin-right: var(--unit)

.InstantSearch-ResultsCount--header--newCourseSearchDesign
  display: flex
  gap: .5rem
  align-items: center

.InstantSearch-ResultsCount--wrapper-refinements
  display: flex
  gap: var(--InstantSearch-Refinement-marginRight)
  flex-wrap: wrap
  align-items: center
  width: 100%

.InstantSearch-ResultsCount--results
  &:after
    display: inline-block
    content: ':'

  +InstantSearch--smallScreen
    display: none

.InstantSearch-SectionHeadingLine
  display: none
  height: var(--InstantSearch-SectionHeadingLine-height)
  flex: 1
  margin-left: calc(var(--unit) * 2)
  background-color: var(--gray-color-400)

.InstantSearch-Line
  height: 1px
  background-color: var(--gray-color-light)

.InstantSearch
  .ais-Highlight-highlighted
    font-style: normal
    // font-weight: bold
    color: var(--accent-color)

// <select/> styles.
.InstantSearch
  .ais-HitsPerPage-select,
  .ais-SortBy-select
    padding-right: var(--InstantSearch-HeaderControl-paddingRight--select)
    color: var(--InstantSearch-HeaderControl-color)
    font-size: var(--InstantSearch-HeaderControl-fontSize)
    font-weight: normal
    background-color: transparent
    background-image: var(--InstantSearch-HeaderControl-backgroundImage--select)
    background-repeat: no-repeat
    background-position: var(--InstantSearch-HeaderControl-backgroundPosition--select)
    background-size: var(--InstantSearch-HeaderControl-backgroundSize--select)
    border: none
    cursor: pointer
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    +focus-outline

.InstantSearch-Results--header
  margin-left: 10px
  flex: 1
  font-size: 18px

.InstantSearch
  .ais-RefinementList-showMore,
  .ais-ClearRefinements-button
    padding: 0
    background: none
    border: none
    color: var(--InstantSearch-baseColor)
    cursor: pointer
    font-weight: normal
    +focus-outline

// `.ais-SearchBox` is used both in "multi-select refinement list"
// and in a "free text search" input.
.InstantSearch
  .ais-SearchBox-input::-webkit-search-decoration,
  .ais-SearchBox-input::-webkit-search-cancel-button,
  .ais-SearchBox-input::-webkit-search-results-button,
  .ais-SearchBox-input::-webkit-search-results-decoration
    display: none

  .ais-SearchBox-input
    -webkit-appearance: none !important
    height: var(--InstantSearch-TextInput-height)
    padding-bottom: var(--InstantSearch-TextInput-paddingBottom)
    font-size: var(--InstantSearch-TextInput-fontSize)
    color: var(--InstantSearch-TextInput-color)
    border-radius: var(--InstantSearch-TextInput-borderRadius)
    border: var(--InstantSearch-TextInput-borderWidth) solid var(--InstantSearch-TextInput-borderColor)
    background-color: var(--InstantSearch-TextInput-backgroundColor)

    &:focus
      outline: none
      color: var(--InstantSearch-TextInput-color--focus)
      border-color: var(--InstantSearch-TextInput-borderColor--focus)
      background-color: var(--InstantSearch-TextInput-backgroundColor--focus)
      box-shadow: 0 0 0 1px var(--InstantSearch-TextInput-borderColor--focus)

    &::placeholder
      color: var(--InstantSearch-TextInput-color--placeholder)

.InstantSearch-ShowMoreResults
  width: calc(var(--unit) * 18)
  height: 50px
  border: 2px solid var(--accent-color)
  color: var(--accent-color)
  border-radius: var(--border-radius)

  &:active
    color: var(--white-color)
    background-color: var(--accent-color)

.InstantSearch-ClearRefinementsContainer--smallScreen
  display: none
  +InstantSearch--smallScreen
    display: block
    text-align: center

  .InstantSearch-ClearRefinements
    margin-top: 9px
    margin-bottom: 9px

    .ais-ClearRefinements-button
      font-weight: var(--InstantSearch-ClearRefinements-fontWeight--smallScreen)
      font-size: var(--InstantSearch-ClearRefinements-fontSize--smallScreen)

.InstantSearch-ShowFilters
  font-size: 18px
  margin-top: var(--unit)
  margin-left: auto
  margin-right: auto
  color: var(--accent-color)
  // Reset `<button/>` styles.
  padding: 0
  border: none
  background: none

.InstantSearch-ResultsCount--smallScreen
  margin-top: var(--unit)
  margin-bottom: 15px

.InstantSearch-ShowMoreResults
  margin-top: calc(var(--unit) * 2)

.InstantSearch--hideResults
  .InstantSearch-Body
    display: none

.InstantSearch-Line
  border: none
  height: 1px
  margin-top: var(--InstantSearch-Line-margin)
  margin-bottom: var(--InstantSearch-Line-margin)
  background-color: var(--InstantSearch-Line-color--header)

.InstantSearch-Line--header
  display: block
  +InstantSearch--smallScreen
    display: none

.InstantSearch-Line--refinements
  display: block
  +InstantSearch--smallScreen
    display: none

.InstantSearch-ExportData
  height: calc(var(--unit) * 2)
  color: var(--accent-color)
  font-size: 14px

  &__button
    @include onFocus--keyboard
      @include focus-outline-thin()

  +InstantSearch--smallScreen
    margin-left: 0
    margin-top: 6px
    display: flex
    justify-content: center

.InstantSearchTextInput
  border-radius: var(--InstantSearch-TextInput-borderRadius)
  border-color: var(--InstantSearch-TextInput-borderColor)
  outline: none

  &:focus
    box-shadow: 0 0 0 1px var(--InstantSearch-TextInput-borderColor--focus)
    border-color: var(--InstantSearch-TextInput-borderColor--focus)
