@import "../../styles/common";

.Container {
  min-height: 7rem;
  background-color: var(--color-white);
  margin-bottom: 1.5rem;
}

.Description {
  font-size: 1rem;
  padding: 0 1rem;
  text-align: center;
}

.Icon {
  margin-bottom: 1rem;
}

.Table {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  margin-bottom: .5rem;

  &__container {
    position: relative;
    padding: 1.5rem;
    border: 1px solid var(--color-grayish-100);
    border-radius: 4px;
    overflow: auto;
    border-bottom: 1px solid var(--color-grayish-100);
  }

  &__title {
    font-size: .75rem;
    line-height: .875rem;
    font-weight: 700;
    color: var(--color-gray-700);
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &__closeIcon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: var(--color-gray-400);
    cursor: pointer;
  }

  &__header {
    color: var(--color-black);
    padding: .5rem 1rem;
    font-size: .875rem;
    font-weight: normal;
    line-height: 1rem;
    background-color: var(--color-gray-25);
    border: 1px solid var(--color-grayish-100);
    border-bottom: none;

    &:first-child {
      border-top-left-radius: var(--radius-select-border);
    }

    &:last-child {
      border: none;
      background-color: transparent;
    }

    &:not(:last-child) {
      border-right: none;
    }

    &:nth-last-child(2) {
      border-top-right-radius: var(--radius-select-border);
      border-right: 1px solid var(--color-grayish-100);
    }
  }

  &__row {
    position: relative;
  }

  &__cell {
    color: var(--color-grayish-300);
    padding: .5rem 1rem;
    font-size: .875rem;
    font-weight: normal;
    line-height: 1rem;
    border: 1px solid var(--color-grayish-100);
    border-bottom: none;
    display: table-cell;
    min-width: 7rem;

    &:not(:last-child) {
      border-right: none;
    }

    &:last-child {
      border: none;
      box-shadow: none;
      padding: .5rem;
    }

    &:nth-last-child(2) {
      border-right: 1px solid var(--color-grayish-100);
    }

    &.isValue {
      color: var(--color-black);
    }

    &.hasError {
      color: var(--color-red-400);
      box-shadow: inset 0 0 0 1px var(--color-red-400);
    }

    &.isActive {
      box-shadow: inset 0 0 0 1px var(--color-purple-600);
    }

    &.isPlaceholder {
      color: var(--color-grayish-300);
    }

    &:active,
    &:focus {
      box-shadow: inset 0 0 0 1px var(--color-purple-600);
      outline: none;
    }
  }

  &__cellError {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 1.25rem;
    }
  }

  tr:first-child {
    .Table__cell {
      &:last-child {
        display: none;
      }
    }
  }

  tr:last-child {
    .Table__cell {
      border-bottom: 1px solid var(--color-grayish-100);

      &:first-child {
        border-bottom-left-radius: var(--radius-select-border);
      }

      &:last-child {
        border-bottom: none;
        border-bottom-right-radius: var(--radius-select-border);
      }

      &:nth-last-child(2) {
        border-bottom-right-radius: var(--radius-select-border);
      }
    }
  }

  &__cellInput {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &__removeIcon {
    width: 1rem;
    height: 1rem;
    color: var(--color-gray-400);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
}

.NewRowButton {
  margin-top: .5rem;
}
