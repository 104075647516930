@import ../../styles/common

.Filters-filter
  display: inline-flex
  align-items: center
  margin-right: calc(var(--unit) * 2)
  +xs
    margin-top: var(--unit)
    
  &-control
    margin-left: var(--unit)
  
  .rrui__select
    position: relative
