@import "../../styles/common";

@import "../Tooltip/Tooltip.module";

$tooltipOffset: .75rem;

.Tooltip {

  &--info[data-unstyledtooltip-show="true"] .trigger {
    color: var(--color-purple-500);
  }

  &--error .trigger {
    color: var(--color-red-400);
  }
}

.trigger {
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-gray-400);
  outline: none;
  border-radius: .25rem;
  position: relative;

  &:focus-visible {
    @include focus-ring();
  }

  .Tooltip--info & {
    @include onFocus--keyboard {
      @include focus-ring($color: var(--color-purple-600), $position-y: -.125rem, $position-x: -.125rem);
    }
  }

  .Tooltip--error & {
    @include onFocus--keyboard {
      @include focus-ring($color: var(--color-red-400), $position-y: -.125rem, $position-x: -.125rem);
    }
  }
}

.tooltip {
  @extend .tooltip;
}

.arrow {
 @extend .arrow;
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
}
