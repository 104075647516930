.UserBlock {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.row {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.name {
  color: inherit;
}

.email {
  color: var(--color-text-secondary);
}
