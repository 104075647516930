@import "../../../../styles/common";

.th {
  @include text-style-subtitle-2;

  background: var(--color-gray-50);
  color: var(--color-black);
  padding-left: 2rem;

  padding-top: 1rem;
  padding-bottom: 1rem;

  &.select {
    // Select column width must be minimum width
    width: 1px;
  }

  &:first-child,
  &.expand + th,
  &.select + th {
    padding-left: 1rem;
  }

  &.expand + th.select {
    padding-left: .5rem;
  }

  &:last-child {
    padding-right: 1rem;
    width: 4.125rem;
    z-index: 3;
  }

  &:not(:first-child) {
    vertical-align: text-bottom;
  }
}
