@import "../../../../styles/common";

.menu {
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
}

.item {
  text-transform: capitalize;
}
