@import ../../../../styles/common

.CourseOverview
  color: var(--color-gray-500)

  &__subsection
    padding: 0

  &__subsectionTitle
    color: var(--color-gray-700)
    font-size: .75rem
    font-style: normal
    font-weight: 700
    line-height: .875rem
    letter-spacing: 1.5px
    text-transform: uppercase
    margin-bottom: 1rem

  &__alert,
  &__subsectionDescription
    margin-bottom: 1.5rem

  &__columnValue
    color: var(--color-black)
    display: flex
    flex-wrap: nowrap
    gap: .25rem

  &__switch
    margin-bottom: 1rem
    font-size: .75rem
    font-style: normal
    font-weight: 700
    line-height: .875rem
    letter-spacing: 1.5px
    text-transform: uppercase

  &__sectionContainer
    margin-left: 1.5rem

  &__checkbox
    margin-bottom: 1rem
    font-size: .75rem
    font-style: normal
    font-weight: 700
    line-height: .875rem
    letter-spacing: 1.5px
    text-transform: uppercase
