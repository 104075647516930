@import "~@acadeum/ui/src/styles/common";

.DataUploadPage {
  &__dropZone {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  &__table {
    margin-bottom: 1.25rem;

    &--errorCell {
      color: var(--color-red-800);
      width: 100%;
    }
  }

  &__statusCell {
    justify-content: center;
  }

  &__statusTag {
    text-transform: capitalize;
  }

  &__successStatus {
    color: var(--color-green-700);
    background-color: var(--color-green-50);
  }

  &__updatedStatus {
    color: var(--color-blue-700);
    background-color: var(--color-blue-50);
  }

  &__duplicateStatus {
    color: var(--color-blue-700);
    background-color: var(--color-yellow-25);
  }

  &__errorStatus {
    color: var(--color-red-800);
    background-color: var(--color-red-100);
  }

  &__backButton {
    margin-right: auto;
  }

  &__alert {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    justify-content: space-between;
  }

  &__warning {
    margin-bottom: 1rem;
  }
}
