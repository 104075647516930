@import "../../../../styles/common";

.Pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
}
