@import ../../styles/common.sass

.InstantSearch-Refinements
  display: flex
  // min-height: calc(var(--unit) * 5)
  +InstantSearch--smallScreen
    // min-height: auto
    margin-top: 6px

  .InstantSearch-ClearRefinements
    margin-top: var(--InstantSearch-ClearRefinements-marginTop--refinements)
    margin-right: var(--InstantSearch-ClearRefinements-marginRight--refinements)
    +InstantSearch--smallScreen
      display: none

.InstantSearch-RefinementsList
  margin-top: calc(-1 * var(--InstantSearch-Refinement-marginRight))
  // margin-bottom: calc(-1 * var(--InstantSearch-Refinement-marginRight))

.InstantSearch-Refinement
  // display: flex
  // align-items: center
  height: var(--InstantSearch-Refinement-height)
  line-height: var(--InstantSearch-Refinement-lineHeight)
  border: var(--InstantSearch-Refinement-borderWidth) solid var(--InstantSearch-Refinement-borderColor)
  border-radius: var(--InstantSearch-Refinement-borderRadius)
  color: var(--InstantSearch-Refinement-color)
  background-color: var(--InstantSearch-Refinement-backgroundColor)
  padding-left: var(--InstantSearch-Refinement-paddingLeft)
  padding-right: var(--InstantSearch-Refinement-paddingLeft)
  margin-right: var(--InstantSearch-Refinement-marginRight)
  margin-top: var(--InstantSearch-Refinement-marginRight)
  // margin-bottom: var(--InstantSearch-Refinement-marginRight)
  float: left
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: var(--InstantSearch-Refinement-fontSize)
  cursor: default

  // ASP reskin styles.
  border-radius: 5px
  height: 2rem
  line-height: 2rem
  padding-left: 0.5rem
  padding-right: 0.5rem
  background-color: #F0F0F0
  color: #000000

  +InstantSearch--smallScreen
    height: var(--InstantSearch-Refinement-height--smallScreen)
    line-height: var(--InstantSearch-Refinement-lineHeight--smallScreen)

.InstantSearch-ClearRefinement
  padding: 3px
  margin-left: 4px
  margin-right: -3px
  line-height: 0
  cursor: pointer
  border-radius: 50%
  &:focus
    box-shadow: 0 0 0 var(--InstantSearch-ClearRefinementOutline-width--focus) currentColor

.InstantSearch-ClearRefinementIcon
  width: var(--InstantSearch-ClearRefinement-width)
  height: var(--InstantSearch-ClearRefinement-width)
