@import ../../styles/common

$ApproveCourseTableColumn-paddingRight: 12px

\:root
  --approve-course-form-margin-right: 2rem
  margin-right: calc(var(--approve-course-form-mapping-row-actions-spacing) + var(--icon-button-width))

.approve-course__description,
.approve-course__section-description
  max-width: 37.5rem
  color: var(--graphite-color)
  font-size: 1rem
  font-weight: 400
  line-height: 1.5rem
  margin-bottom: 1.5rem

.approve-course__form
  .form__field
    margin-bottom: var(--unit)
  .form__buttons
    margin-top: 2rem

.approve-course
  &__form-content
    border: 1px solid var(--gray-line-color)
    border-radius: 4px
    padding: 2rem
  &__course-remove-icon-container
    display: flex
    align-items: center
  &__course-remove-icon
    cursor: pointer
    width: 1.5rem
    height: 2rem
  &__alert
    margin-top: 1.5rem
  &__description
    margin-top: 1rem
    margin-bottom: 1rem
  &__sessions-description
    color: var(--graphite-color)
    margin-bottom: 1.5rem
  &__sessions
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: .5rem
    padding-bottom: 1.5rem
    &:not(:last-child)
      border-bottom: 1px solid #dee3de
      margin-bottom: 1.5rem
  &__term
    margin-bottom: 1rem
    font-size: .75rem
    line-height: .875rem
    font-style: normal
    font-weight: 700
    letter-spacing: 1.5px
    text-transform: uppercase

.approve-course__form-row
  display: flex
  align-items: center

.approve-course__form-inputs-row
  flex: auto

.approve-course__form-row-remove
  margin-top: calc((var(--form-input-label-height) - var(--icon-button-padding)) / 2)
  margin-left: calc(var(--approve-course-form-margin-right) - var(--icon-button-width) - var(--icon-button-padding))
  // margin-right: calc(-1 * var(--icon-button-padding))
  +xs
    display: none

.approve-course__mapping-actions
  display: flex
  justify-content: flex-end
  margin-right: var(--approve-course-form-margin-right)
  +xs
    margin-right: 0
  .action
    height: calc(var(--unit) * 2.5)

.approve-course__form-row-remove-small-screen
  display: none
  +xs
    display: inline-flex

.approve-course__form-row-remove--placeholder
  visibility: hidden

.approve-course__schedule
  margin-top: calc(var(--unit) * 2.5)

.approve-course__schedule-description
  margin: 0
  padding-left: 19px
  padding-right: 19px
  padding-top: 16px
  padding-bottom: 16px
  background-color: var(--input-background-color)

.approve-course__schedule-header
  display: flex
  justify-content: space-between
  margin-bottom: 11px
  color: #828282
  letter-spacing: 1.5px

.approve-course__schedule-table
  width: 100%
  margin-top: 42px
  margin-right: var(--approve-course-form-margin-right)
  color: #828282
  .rrui__checkbox__checkbox
    margin-left: 2px
    margin-right: 2px

.approve-course__schedule-table-row
  td
    height: 37px
    border-bottom: 1px solid #dfdfdf
    &:first-child
      border-bottom: none
  &:last-child
    td
      border-bottom: none
  .rrui__checkbox,
  .rrui__input
    height: 100%

.approve-course__schedule-table__term-checkbox
  // Both `width` and `padding-right` are used
  // so that this `<td/>` doesn't collapse
  // due to using `width: 100%` on session `<td/>`s.
  width: 32px
  // padding-right: 12px
  // The term checkbox label is still shown.
  // There're a couple of reasons:
  // * The label is required for accessibility.
  // * The right spacing is still clickable and toggles the checkbox.
  .rrui__checkbox__label
    max-width: 12px
    overflow: hidden
    opacity: 0

.approve-course__schedule-table-row
  td
    padding-right: $ApproveCourseTableColumn-paddingRight
    &:last-child
      width: 1px
      white-space: nowrap
      padding-right: 0

  // The CSS selector selector specificity is for overriding the `td` `padding-right` set above.
  .approve-course__schedule-table__term-checkbox
    padding-right: 0

  // The CSS selector selector specificity is for overriding the `td` `padding-right` set above.
  .approve-course__schedule-table__term-title
    // Adding `padding-right` here so that this `<td/>`'s proportion
    // is equal to those of the other `<td/>`s.
    // `30px` is the width of the checkbox plus the spacing from it.
    padding-right: $ApproveCourseTableColumn-paddingRight + 30px

.approve-course__schedule-table__term-title
  font-weight: bold
  // The term label is clickable as if it was a checkbox label.
  span
    cursor: pointer
    user-select: none

.approve-course__form-footer
  margin-top: 1rem
  align-items: flex-end
