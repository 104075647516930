@import "../../styles/common";

.ResultsPerPageSelect {
  position: relative;
  display: flex;
  align-items: center;

  &__label {
    text-transform: capitalize;
    color: var(--color-gray-500);
    margin-right: .5rem;
    white-space: nowrap;
  }
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-gray-50);
  padding: .25rem .75rem;
  gap: .5rem;
  border-radius: .24rem;
  position: relative;
  border: 1px solid transparent !important;

  &:hover {
    box-shadow: 0 2px 3px 0 #00000026;
    box-shadow: 0 6px 10px 4px #0000001A;
  }

  @include onFocus--keyboard {
    @include focus-ring();
  }

  &__icon {
    margin-left: auto;
    transition: all .4s;
  }

  &[data-state='open'] &__icon {
    transform: rotate(-180deg);
  }
}

.SelectContent {
  overflow: hidden;
  min-width: 172px;
  z-index: var(--layer-top);
  padding: .25rem 0;
  background-color: var(--color-white);
  border-radius: .25rem;
  box-shadow: 0 2px 6px 2px #0000001A;
  box-shadow: 0 1px 2px 0 #00000033;

}

.SelectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 1rem;
  outline: none;

  &[data-state="checked"] {
    background-color: var(--color-gray-15);
    color: var(--color-purple-600);
  }

  &:not([data-disabled]) {
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: var(--color-white);
      background-color: var(--color-purple-500);
    }
  }

  @include onFocus--keyboard {
    text-decoration: none;
    color: var(--color-white);
    background-color: var(--color-purple-500);
  }
}
