.Card {
  padding: 1.5rem;
  border-radius: .25rem;
}

.white {
  border: 1px solid var(--color-grayish-100);
  background: var(--color-white);
}

.gray {
  background: var(--color-gray-25);
}

.mlAuto {
  margin-left: auto;
}
