@import "../../styles/common";

.Fieldset {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;

  > * {
    flex: 1 1 0;

    @include bp-down(md) {
      flex-basis: auto;
      width: 100%;
    }
  }

  &--phone {

    > *:nth-child(2) {
      @include bp(md) {
        max-width: 5rem;
      }
    }
  }
}
