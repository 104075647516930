@import "../../styles/common";

.content {
  padding: 0;
  min-width: 18.75rem;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: .475rem;
  height: 1.125rem;
  min-width: 1.125rem;
  padding-right: .25rem;
  padding-left: .25rem;
  border-radius: 50%;
  background-color: var(--color-black);
  color: var(--color-white);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--color-grayish-100);
}

.list {
  max-height: calc(22rem + 1px); // 1px needed for ignore border-top height
  overflow-y: auto;
}

.item {
  padding: .5rem 1rem;
  min-height: 2.75rem;
  display: flex;
  align-items: center;

  &:where(:not(:last-of-type)) {
    border-bottom: 1px solid var(--color-grayish-100);
  }
}
