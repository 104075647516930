@use "sass:math";
@import "~react-datepicker/src/stylesheets/datepicker.scss";

@import "../common";

$DatePicker-arrowButton-width: 1.75rem;
$DatePicker-arrow-height: 0.5rem;
$DatePicker-arrow-width: math.div($DatePicker-arrow-height, 2);

.react-datepicker-popper {
  //This is a temporary solution
  @include bp-down(md) {
    position: inherit !important;
    top: unset !important;
    left: unset !important;
    transform: none !important;
  }
}

.react-datepicker {
  box-shadow: var(--shadow-calendar);
  border: none;

  @include bp-down(md) {
    display: block;
    box-shadow: none;
    max-width: 23.5rem;
    margin-right: auto;
    margin-left: auto;
  }

  &__month-container {
    float: none;
  }

  &,
  &__header {
    background: var(--color-white);
  }

  &__header {
    border-bottom: none;
    padding-top: 1.5rem;
  }

  &__month {
    margin-bottom: 1rem;

    @include bp-down(md) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &,
  &__navigation,
  &__month-text,
  &__month-text:hover,
  &__day,
  &__day:hover {
    border-radius: .25rem;
  }

  * {
    color: var(--color-black);
    font-family: var(--font-family);
    font-weight: 400;
    outline: none;
  }

  &-wrapper {
    width: 100%;
  }

  &-popper {
    z-index: var(--layer-1);
  }

  &__triangle {
    display: none;
  }

  &__current-month {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__navigation {
    top: 1rem;
    width: $DatePicker-arrowButton-width;
    height: $DatePicker-arrowButton-width;
    margin-top: .375rem;

    &-icon:before {
      display: none;
    }

    &:before {
      content: "";
      display: block;
      position: relative;
      width: $DatePicker-arrow-width;
      height: $DatePicker-arrow-width;
      border: $DatePicker-arrow-width solid transparent;
    }

    &:focus-visible {
      box-shadow: var(--shadow-border-purple-active);
    }

    &:active {
      box-shadow: var(--shadow-border-purple-active);
    }

    &--previous {
      margin-left: 1rem;

      &,
      &:before {
        border-left-width: 0;
      }

      &,
      &:hover,
      &:before {
        border-right-color: var(--color-gray-900);
      }

      @include bp-down(sm) {
        margin-left: 2rem;
      }
    }

    &--next {
      margin-right: 1rem;

      &,
      &:before {
        border-right-width: 0;
      }

      &,
      &:before,
      &:hover {
        border-left-color: var(--color-gray-900);
      }

      @include bp-down(sm) {
        margin-right: 2rem;
      }
    }
  }

  &__day-names {
    display: flex;
    margin-top: 1.5rem;
  }

  &__week {
    display: flex;
  }


  &__month-text,
  &__day,
  &__day-name,
  &__time-name {
    width: 2.6rem;
    line-height: 2.15rem;

    @include bp-down(md) {
      width: 1.8rem;
      line-height: 1.8rem;
    }
  }

  &__day-name {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }


  &__day-name,
  &__day {
    &:before {
      content: "";
      float: left;
      padding-top: 100%;
    }
  }


  &__month-text,
  &__day {
    font-size: .875rem;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
      box-shadow: var(--shadow-border-purple);
      background-color: var(--color-white);
    }

    &:focus-visible {
      box-shadow: var(--shadow-border-purple-active);
    }

    &:active {
      box-shadow: var(--shadow-border-purple-active);
    }

    &--disabled {
      color: var(--color-grayish-300);
      opacity: .4;
      pointer-events: none;
    }

    &--keyboard-selected {
      background: transparent;

      &:focus-visible {
        background: transparent;
      }
    }

    &--today {
      font-weight: 700;
      color: var(--color-purple-600);
      box-shadow: var(--color-purple-600);
    }

    &--in-range {
      background: var(--color-gray-100);
    }

    &--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
    ) {
      background: var(--color-gray-100);

      &.react-datepicker__day--selecting-range-start {
        background: var(--color-purple-600);
        color: var(--color-white);
      }
    }

    &--selected,
    &--range-start,
    &--range-end {
      &, &:hover {
        background: var(--color-purple-600);
        color: var(--color-white);
      }

      &:focus-visible{
        background: var(--color-purple-600);
        color: var(--color-white);
      }
    }

    &--outside-month {
      color: var(--color-grayish-300);
    }
  }
}
